const routes = {
    //Navigational
    Home: '/',
    Content: '/:category',
    //Topics: '/:category/:subcategory',
    //Cards: '/:category/:subcategory/:topics',
    BlankPage: "/404page",
    TopicList: '/:category/subcategory/:subcategory',
    Card: ['/:category/subcategory/:subcategory/topic/:topic', '/:category/topic/:topic'],

    //Admin
    Admin: {
        users: '/admin/users',
        categories:'/admin/categories',
        subcategories:'/admin/sub-categories',
        topics:'/admin/topics',
        flashcards: '/admin/flash-cards'
    },
    Create: '/admin/create',
    Edit: '/admin/edit',

    //User Access
    Logout: "/logout",
    Bookmark: "/bookmark",

    //Auth
    ResetPassword: "/reset/:id",
    VerifyPassword: "/verify-email/:id"
}

export default routes;
