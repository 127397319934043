import React from "react";
const SVG = ({ width, height, className }) => (
    <svg 
    width={width}
    height={height}
    className={`svg-icon ${className || ""}`}
    version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 24.888 24.888">
        
        <path d="M19.555,14.222a5.306,5.306,0,0,0-3.692,1.5L10.6,13.09a5.242,5.242,0,0,0,.065-.646A5.243,5.243,0,0,0,10.6,11.8l5.262-2.632a5.322,5.322,0,1,0-1.641-3.833,5.243,5.243,0,0,0,.065.646L9.025,8.611a5.333,5.333,0,1,0,0,7.667l5.262,2.632a5.242,5.242,0,0,0-.065.646,5.333,5.333,0,1,0,5.333-5.333Z"/>
    </svg>
);
export default SVG;
