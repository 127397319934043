import React from "react";
import { withApollo } from "react-apollo";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import Grid from "@material-ui/core/Grid";
import { Typography, Hidden, IconButton } from "@material-ui/core";
import ToolTip from "../Tooltip";
import { withRouter } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import ProfileAvatar from "../profileAvatar";
import { AuthContext } from "../../contexts";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "100%",
    height: 95,
    background: theme.palette.background.paper,
    color: theme.palette.common.black,

    paddingLeft: 66,
    paddingRight: 66,
    paddingTop: 42,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.0), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 2px 1px -1px rgba(0, 0, 0, 0)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 2,
    },
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      height: 150,
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: 50,
      paddingRight: 60,
      height: 150,
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.only("md")]: {
      height: 73,
      paddingTop: 22,
    },
    [theme.breakpoints.up("lg")]: {
      height: 96,
      paddingTop: 27,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 166,
      paddingRight: 166,
      paddingTop: 34,
    },
  },
  header: {
    display: "flex",
    paddingLeft: 12,
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
      display: "contents",
    },
  },
  headerChild: {
    whiteSpace: "nowrap",
    paddingTop: "10px",
    [theme.breakpoints.only("lg")]: {
      paddingTop: "10px",
    },
    [theme.breakpoints.only("md")]: {
      paddingTop: "10px",
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "8px",
      paddingTop: "42px",
    },
    [theme.breakpoints.only("xs")]: {
      paddingTop: "42px",
    },
  },
  arrowIcon: {
    paddingLeft: "18px",
    paddingTop: "51px",
    fill: "#FFD447FC",
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "45px",
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "0px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: "40px",
    },
  },
  TextName: {
    lineHeight: 1,
    fontWeight: "bold",
    display: "flex",
    textTransform: "capitalize",
    color: theme.palette.common.black,
    [theme.breakpoints.only("xs")]: {
      fontWeight: "bold",
      paddingBottom: 6,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.only("sm")]: {
      fontWeight: "bold",
      paddingBottom: 6,
      color: theme.palette.common.white,
    },
  },
  searchChild: {
    paddingRight: "22px",
    paddingTop: "2px",
    [theme.breakpoints.only("xs")]: {
      // paddingRight: "18px",
      paddingLeft: "18px",
      paddingTop: "5px",
    },
    [theme.breakpoints.only("md")]: {
      paddingRight: "0px",
    },
    [theme.breakpoints.only("lg")]: {
      paddingRight: "8px",
    },
  },
  search: {
    position: "relative",
    borderRadius: "25px",
    height: "38px",
    display: "flex",
    float: "right",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      //backgroundColor: theme.palette.common.white,
      //boxShadow: '0 5px 15px rgba(61, 70, 144, .4)',
      backgroundColor: "#00000008",
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.only("lg")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
      marginRight: 27,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
      marginRight: 27,
    },
    [theme.breakpoints.only("sm")]: {
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.only("xs")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.hint,
  },
  inputRoot: {
    color: theme.palette.text.hint,
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: theme.palette.common.black,
    paddingTop: 10,
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
  },
  subTitle: {
    whiteSpace: "nowrap",
    marginTop: "5px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  createButton: {
    background: theme.palette.primary.main,
    width: 40,
    height: 40,
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.white,
      fontSize: "1.8rem",
    },
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
}));

const CreateTopbar = (props) => {
  const classes = useStyles();
  const context = React.useContext(AuthContext);
  return (
    <AppBar position="static" className={classes.appBar}>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Hidden mdUp>
          <Grid item onClick={() => window.history.back()}>
            <ArrowBackIosIcon className={classes.arrowIcon} />
          </Grid>
        </Hidden>

        <Grid
          item
          direction="row"
          justify="space-between"
          container
          className={classes.header}
        >
          <Grid item xs={6} md={10} className={classes.headerChild}>
            <Typography variant="h5" className={classes.TextName}>
              {props.title}
            </Typography>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={6} md={2} container justify="flex-end">
            {get(context.user, [
              "user_profiles",
              "0",
              "workspace_user_profiles",
              "0",
              "role",
              "name",
            ]) === "admin" &&
              props?.hasCreate && (
                <ToolTip title="Add" style={{ marginRight: 15 }}>
                  <IconButton
                    color="primary"
                    className={classes.createButton}
                    onClick={() => props.history.push("/admin/create")}
                  >
                    <AddIcon />
                  </IconButton>
                </ToolTip>
              )}
            <ProfileAvatar />
          </Grid>
        </Hidden>
      </Grid>
    </AppBar>
  );
};

export default withRouter(withApollo(CreateTopbar));
