import React from "react";
import { Grid, Typography, Chip, withStyles } from "@material-ui/core";
import { parseISO, format, differenceInDays } from "date-fns";
import { withRouter } from "react-router-dom";
import config from "../../config";
import { ShareIcon, HeartIcon } from "../../icons";

const styles = (theme) => ({
  imageStyle: (props) => {
    return {
      width: props.preview ? "95px" : "100%",
      height: props.preview ? "95px" : "100%",
      borderRadius: props.preview ? "50%" : "18px",
      objectFit: "cover",
      "&:hover": {
        cursor: "pointer",
      },
      // display:"block",
      [theme.breakpoints.only("xs")]: {
        borderRadius: "50%",
        width: "95px",
        height: "95px",
      },
    };
  },
  cardContent: {
    borderRadius: "20px",
    backgroundColor: theme.palette.common.white,
    padding: 18,
    height: 140,
    position: "relative",
    boxShadow: "0px 3px 6px #00000029;",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0px 5px 10px #00000029",
    },
    [theme.breakpoints.only("xs")]: {
      backgroundColor: theme.palette.common.white,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  pad: {
    padding: "10px 10px 15px 10px",
  },
  fullWidth: {
    width: "100%",
    fontWeight: "lighter",
    fontSize: "0.7099rem",
    color: "#262626",
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px",
    },
  },
  fullWidth2: {
    width: "100%",
    fontWeight: "bold",
    fontSize: "0.9109rem",
    color: "#262626",
    /*[theme.breakpoints.only("sm")]: {
            fontSize: "14px"
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "15px"
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "18px"
        }*/
  },
  fullWidth1: {
    width: "100%",
    fontSize: "12px",
    color: "#848484",
    letterSpacing: "0px",
    fontWeight: "lighter",
    paddingTop: 3,
    [theme.breakpoints.only("sm")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "10px",
    },
  },
  imageContainer: (props) => {
    return {
      height: props.preview ? "initial" : 100,
      [theme.breakpoints.only("xs")]: {
        height: 97,
        textAlign: "center",
      },
      [theme.breakpoints.only("xl")]: {
        // height: 159,
      },
    };
  },
  textpad: (props) => {
    return {
      paddingLeft: props.preview ? 20 : 9,
      [theme.breakpoints.only("xs")]: {
        paddingLeft: 5,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 10,
      },
    };
  },
  trending: {
    fontWeight: "300",
    position: "absolute",
    right: -10,
    top: 10,
    "& .MuiChip-root": {
      height: 20,
      backgroundColor: "#FFD447FC",
      color: theme.palette.common.white,
      fontSize: 11,
    },
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cardAction: {
    position: "absolute",
    right: 15,
    bottom: 15,
    [theme.breakpoints.only("xs")]: {
      right: 10,
      bottom: 10,
    },
  },
  cardActionIcon: {
    width: 20,
    height: 20,
    fill: "#d8d8d8",
    marginRight: 5,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
});

class SubCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { classes, location } = this.props;
    const result = this.props.card;
    let redirecturl = location.pathname + `/topic/${result.urlParam}`;
    const imgURL = result.path
      ? result.path
      : config.url + "/" + result.imageUrl;
    return (
      <>
        <Grid
          item
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          onClick={() => {
            if (this.props.preview !== true) {
              this.props.history.push(redirecturl);
            } else if (this.props.editable === true) {
              this.setState({ open: true });
            }
          }}
          className={classes.cardContent}
          lg={12}
        >
          <Grid
            item
            lg={4}
            md={5}
            sm={5}
            xs={4}
            className={classes.imageContainer}
          >
            <img className={classes.imageStyle} src={`${imgURL}`} alt="" />
          </Grid>
          <Grid item className={classes.textpad} lg={8} md={7} sm={7} xs={8}>
            <Typography className={classes.fullWidth2} variant="h6">
              {result.title}
            </Typography>
            <Typography className={classes.fullWidth} variant="subtitle2">
              By {result.createdBy ? result.createdBy : "Crayond"}
            </Typography>
            <Typography className={classes.fullWidth1}>
              {format(parseISO(result.createdAt), "EE dd MMM,yyyy")}
            </Typography>
          </Grid>
          {differenceInDays(Date.now(), parseISO(result.createdAt)) < 3 && (
            <div className={classes.trending}>
              <Chip label="New" />
            </div>
          )}
          {/* Card Action */}
          <div className={classes.cardAction}>
            {/* Like Icon */}
            <HeartIcon className={classes.cardActionIcon} />
            {/* Share Icon */}
            <ShareIcon className={classes.cardActionIcon} />
          </div>
        </Grid>
        {/*<EditSubCard
                    open={this.state.open}
                    handleClose={() => this.setState({ open: false })}
                    card={{
                        id: result.id,
                        title: result.title,
                        path: result.path,
                        createdAt: new Date().toISOString()
                    }} />*/}
      </>
    );
  }
}
export default withRouter(withStyles(styles)(SubCards));
