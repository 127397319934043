import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="Group_118" transform="translate(-31.051 19.54)">
            <g id="Path_109">
                <g>
                    <path d="M53.3,1.2c0-0.3,0.1-0.6,0.2-0.8c-0.1,0.1,0.1-0.2,0.1-0.2c0,0,0.2-0.3,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3
				c0,0,0.3-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.1-0.1,0.3-0.1c0.1,0,0.3-0.1,0.4-0.1c0,0,0.2,0,0.1,0
				c-0.1,0,0.1,0,0.2,0c0.2,0,0.6,0,0.8,0c-0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1c0,0-0.1-0.1,0,0
				c0.1,0.1,0.2,0.1,0.4,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0,0c0,0,0.1,0,0.1,0.1c0.3,0.3,0.6,0.7,0.7,1.1
				C58.2,1,58.2,1.4,58,1.7c-0.2,0.4-0.5,0.7-0.9,0.8c-0.6,0.2-1.2,0.4-1.6,0.9c-0.8,0.9-0.8,2.2-0.7,3.3c0.1,0.6,0.5,1.1,1.1,1.1
				c0.6,0,1.2-0.5,1.1-1.1c0-0.5-0.1-0.9-0.1-1.4c0-0.2,0,0.2,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.2-0.1,0.2,0,0
				c0,0,0-0.1,0.1-0.1c-0.2,0.2,0,0,0,0c0,0,0.1-0.1,0,0c0,0,0.3-0.2,0.1-0.1c0.4-0.2,0.7-0.2,1.1-0.4C58.7,4.3,59,4,59.2,3.8
				c0.5-0.5,0.9-1.3,1.1-2c0.3-1.6-0.5-3.2-1.7-4.1c-1.2-0.9-2.8-1.1-4.2-0.6c-1.6,0.5-2.9,1.9-3.2,3.6C51.1,1.2,51.3,1.8,52,2
				C52.5,2.1,53.3,1.8,53.3,1.2L53.3,1.2z"/>
                </g>
            </g>
            <g id="Group_89" transform="translate(37.937 317.937)">
                <g id="Ellipse_30" transform="translate(0 0)">
                    <g>
                        <g>
                            <path d="M17.9-326.9c5.6,0.1,10.8,3.6,12.6,9c1.8,5.3,0.2,11.2-4.1,14.8c-4.3,3.6-10.6,4-15.3,1.2c-4.7-2.8-7.4-8.5-6.3-13.9
						C6-322.2,11.5-326.8,17.9-326.9c1.4,0,1.5-2.3,0-2.3C11.4-329,5.5-325,3.2-318.8C1-312.7,2.9-305.6,8-301.4
						c5,4.1,12.3,4.8,17.9,1.4c5.7-3.4,8.6-10,7.3-16.5c-1.4-7.3-8-12.5-15.3-12.6C16.5-329.1,16.5-326.9,17.9-326.9z"/>
                        </g>
                    </g>
                </g>
                <g id="question_1_" transform="translate(12.602 19.763)">
                    <g id="Group_88" transform="translate(0 0)">
                        <g id="Group_87" transform="translate(0)">
                            <g id="Ellipse_29">
                                <circle cx="5.3" cy="-326.1" r="1.9" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
export default SVG;
