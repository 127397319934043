import Routes from "../../router/routes";
import { ContentCount, UserList } from "../../services";
import {
  CATEGORY_LIST,
  SUBCATEGORY_LIST,
  TOPIC_LIST,
} from "../../graphql/query";

async function getInfoDetailsCount() {
  try {
    var response = await ContentCount();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
}

function getColumnNames(path) {
  if (path === Routes.Admin.users) {
    return [
      {
        label: "Name",
        align: "left",
        path: "email",
        type: "email",
        user: true,
      },
      { label: "Created At", align: "left", type: "date", path: "createdAt" },
      {
        label: "Bookmarked",
        align: "center",
        path: "bookmarked",
        type: "text",
      },
      /*{
        label: "Active/InActive",
        align: "center",
        path: "is_active",
        type: "switch",
      },*/
    ];
  }
  if (path === Routes.Admin.categories) {
    return [
      { label: "Name", align: "left", path: "name", type: "text" },
      { label: "Created At", align: "left", type: "date", path: "createdAt" },
      { label: "Edit", align: "center", type: "edit" },
      { label: "Delete", align: "center", type: "delete" },
      {
        label: "Active/InActive",
        align: "center",
        path: "isActive",
        type: "switch",
      },
    ];
  }
  if (path === Routes.Admin.subcategories) {
    return [
      { label: "Name", align: "left", path: "name", type: "text" },
      { label: "Created At", align: "left", type: "date", path: "createdAt" },
      { label: "Edit", align: "center", type: "edit" },
      { label: "Delete", align: "center", type: "delete" },
      {
        label: "Active/InActive",
        align: "center",
        path: "isActive",
        type: "switch",
      },
    ];
  }
  if (path === Routes.Admin.topics) {
    return [
      { label: "Title", align: "left", path: "title", type: "text" },
      { label: "Created At", align: "left", type: "date", path: "createdAt" },
      { label: "Edit", align: "center", type: "edit" },
      { label: "Delete", align: "center", type: "delete" },
      {
        label: "Active/InActive",
        align: "center",
        path: "isActive",
        type: "switch",
      },
    ];
  }
  return [];
}

async function getRows(client, path, data) {
  var response;
  if (path === Routes.Admin.users) {
    try {
      response = await UserList({ limit: 1000, offset: 0 });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  if (path === Routes.Admin.categories) {
    try {
      response = await client.query({
        query: CATEGORY_LIST,
        variables: data,
        fetchPolicy: "network-only",
      });
      if (response.data) {
        return response.data.categories.nodes;
      }
    } catch (error) {
      console.log(error);
    }
  }
  if (path === Routes.Admin.subcategories) {
    try {
      response = await client.query({
        query: SUBCATEGORY_LIST,
        variables: data,
        fetchPolicy: "network-only",
      });
      if (response.data) {
        return response.data.subCategories.nodes;
      }
    } catch (error) {
      console.log(error);
    }
  }
  if (path === Routes.Admin.topics) {
    try {
      response = await client.query({
        query: TOPIC_LIST,
        variables: data,
        fetchPolicy: "network-only",
      });
      if (response.data) {
        return response.data.topics.nodes;
      }
    } catch (error) {
      console.log(error);
    }
  }
  return [];
}
export default {
  getColumnNames,
  getRows,
  getInfoDetailsCount,
};
