import { gql } from "apollo-boost";
export const variables = {
  category: {
    create: {
      id: "",
      image_url: "",
      name: "",
      created_at: new Date(),
      updated_at: new Date(),
      url_param: "",
    },
    update: {
      id: "",
      image_url: "",
      name: "",
      updated_at: new Date(),
    },
  },
  sub_category: {
    create: {
      id: "",
      image_url: "",
      name: "",
      created_at: new Date(),
      updated_at: new Date(),
      category_id: null,
      url_param: "",
    },
    update: {
      id: "",
      image_url: "",
      name: "",
      updated_at: new Date(),
    },
  },
  topics: {
    create: {
      id: "",
      image_url: "",
      title: "",
      created_at: new Date(),
      updated_at: new Date(),
      category_id: null,
      sub_category_id: null,
      url_param: "",
    },
    update: {
      id: "",
      image_url: "",
      title: "",
      updated_at: new Date(),
    },
  },
  card: {
    create: {
      id: "",
      image_url: "",
      title: "",
      content: "",
      created_at: new Date(),
      updated_at: new Date(),
      topic_id: "",
    },
    update: {
      id: null,
      image_url: null,
      content: null,
      title: null,
      order_by: null,
      updated_at: new Date(),
    },
  },
  bookmark: {
    add: {
      $user_id: "",
      $card_id: null,
      $createdAt: new Date(),
      $updatedAt: new Date(),
    },
    remove: {
      id: "",
    },
  },
};
export const mutation = {
  category: {
    create: gql`
      mutation (
        $id: UUID!
        $image_url: String
        $name: String
        $created_at: Datetime!
        $updated_at: Datetime!
        $url_param: String
      ) {
        createCategory(
          input: {
            category: {
              id: $id
              createdAt: $created_at
              updatedAt: $updated_at
              imageUrl: $image_url
              isActive: true
              name: $name
              urlParam: $url_param
            }
          }
        ) {
          category {
            id
          }
        }
      }
    `,
    update: gql`
      mutation (
        $id: UUID!
        $imageUrl: String
        $name: String
        $updatedAt: Datetime!
        $isActive: Boolean
        $urlParam: String
      ) {
        updateCategoryById(
          input: {
            categoryPatch: {
              updatedAt: $updatedAt
              imageUrl: $imageUrl
              isActive: $isActive
              name: $name
              urlParam: $urlParam
            }
            id: $id
          }
        ) {
          category {
            id
          }
        }
      }
    `,
    delete: gql`
      mutation ($id: UUID!) {
        deleteCategoryById(input: { id: $id }) {
          deletedCategoryId
        }
      }
    `,
  },
  sub_category: {
    create: gql`
      mutation (
        $id: String!
        $image_url: String
        $name: String
        $created_at: Datetime!
        $updated_at: Datetime!
        $category_id: UUID
        $url_param: String
      ) {
        createSubCategory(
          input: {
            subCategory: {
              id: $id
              createdAt: $created_at
              updatedAt: $updated_at
              imageUrl: $image_url
              isActive: true
              name: $name
              categoryId: $category_id
              urlParam: $url_param
            }
          }
        ) {
          subCategory {
            id
          }
        }
      }
    `,
    update: gql`
      mutation (
        $id: String!
        $imageUrl: String
        $name: String
        $updatedAt: Datetime!
        $isActive: Boolean
        $urlParam: String
      ) {
        updateSubCategoryById(
          input: {
            subCategoryPatch: {
              updatedAt: $updatedAt
              imageUrl: $imageUrl
              isActive: $isActive
              name: $name
              urlParam: $urlParam
            }
            id: $id
          }
        ) {
          subCategory {
            id
          }
        }
      }
    `,
    delete: gql`
      mutation ($id: String!) {
        deleteSubCategoryById(input: { id: $id }) {
          deletedSubCategoryId
        }
      }
    `,
  },
  topics: {
    create: gql`
      mutation (
        $id: String!
        $image_url: String
        $title: String
        $created_at: Datetime!
        $updated_at: Datetime!
        $category_id: UUID
        $sub_category_id: String
        $url_param: String
      ) {
        createTopic(
          input: {
            topic: {
              id: $id
              createdAt: $created_at
              updatedAt: $updated_at
              imageUrl: $image_url
              isActive: true
              subCategoryId: $sub_category_id
              categoryId: $category_id
              title: $title
              urlParam: $url_param
            }
          }
        ) {
          topic {
            id
          }
        }
      }
    `,
    update: gql`
      mutation (
        $id: String!
        $imageUrl: String
        $title: String
        $updatedAt: Datetime!
        $isActive: Boolean
        $urlParam: String
      ) {
        updateTopicById(
          input: {
            topicPatch: {
              id: $id
              updatedAt: $updatedAt
              imageUrl: $imageUrl
              title: $title
              isActive: $isActive
              urlParam: $urlParam
            }
            id: $id
          }
        ) {
          topic {
            id
          }
        }
      }
    `,
    delete: gql`
      mutation ($id: String!) {
        deleteTopicById(input: { id: $id }) {
          deletedTopicId
        }
      }
    `,
  },
  card: {
    create: gql`
      mutation (
        $id: UUID!
        $image_url: String
        $title: String
        $content: String
        $created_at: Datetime!
        $updated_at: Datetime!
        $topic_id: String
        $order_by: Int
      ) {
        createCard(
          input: {
            card: {
              id: $id
              createdAt: $created_at
              updatedAt: $updated_at
              imageUrl: $image_url
              isActive: true
              title: $title
              content: $content
              topicId: $topic_id
              orderBy: $order_by
            }
          }
        ) {
          card {
            id
          }
        }
      }
    `,
    update: gql`
      mutation (
        $id: UUID!
        $image_url: String
        $title: String
        $content: String
        $order_by: Int
        $updatedAt: Datetime!
      ) {
        updateCardById(
          input: {
            cardPatch: {
              updatedAt: $updatedAt
              imageUrl: $image_url
              content: $content
              title: $title
              orderBy: $order_by
            }
            id: $id
          }
        ) {
          card {
            id
          }
        }
      }
    `,
    delete: gql`
      mutation ($id: UUID!) {
        deleteCardById(input: { id: $id }) {
          deletedCardId
        }
      }
    `,
  },
  bookmark: {
    add: gql`
      mutation (
        $user_id: UUID!
        $card_id: UUID
        $createdAt: Datetime!
        $updatedAt: Datetime!
      ) {
        createBookmark(
          input: {
            bookmark: {
              createdBy: $user_id
              createdAt: $createdAt
              updatedAt: $updatedAt
              cardsId: $card_id
            }
          }
        ) {
          bookmark {
            id
            cardsId
            createdBy
          }
        }
      }
    `,
    remove: gql`
      mutation ($id: Int!) {
        deleteBookmarkById(input: { id: $id }) {
          deletedBookmarkId
        }
      }
    `,
  },
};
