import React from "react";
import { withApollo } from "react-apollo";
import { TextField, Hidden, Typography, Button, Grid, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Sub_Categories_by_Category_ID } from "../../graphql/query";
// import { MainCards } from '../';
import config from '../../config';
import SubCategoryCard from './../SubCategoryCard/index';
import UploadIcon from "../../icons/upload";

let name;
let img;

const styles = (theme) => ({
    title: {
        fontSize: "1.2rem",
        fontWeight: "600",
        textAlign: "center"
    },
    divider: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "grey",
        margin: "10px 0",
        '&::before': {
            content: "''",
            display: "block",
            background: "rgba(0, 0, 0, 0.2)",
            width: "50%",
            height: 1,
            marginRight: 10
        },
        '&::after': {
            content: "''",
            display: "block",
            background: "rgba(0, 0, 0, 0.2)",
            width: "50%",
            height: 1,
            marginLeft: 10
        }
    },
    categoryPreview: {
        border: "2px dashed #B9BEC9",
        borderRadius: 8,
        padding: "10px 20px",
        background: "#F9FBFE",
        [theme.breakpoints.down("sm")]: {
            background: theme.palette.background.paper,
        }
    },
    uploadButton: {
        borderRadius: 8,
        textTransform: "capitalize"
    },
    uploadImage: {
        width: 100,
        height: 100,
        fill: "#DBDFEA",
    },
    previewCard: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        wordBreak: "break-word"
    },
    previewImage: {
        width: 160,
        padding: "10px 0",
        [theme.breakpoints.only("xs")]: {
            width: 160
        }
    },
    inputStyle: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 8
        }
    }
});

class SelectSubCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    async componentDidMount() {
        let category_id = this.props.category.listvalue?.id
        if (category_id) {
            await this.props.client.query({
                query: Sub_Categories_by_Category_ID,
                variables: {
                    category_id: category_id
                },
                fetchPolicy: "network-only",
            }).then((res) => {
                console.log(res)
                if (res.data.allSubCategories?.nodes)
                    this.props.handleSubCategory({
                        ...this.props.sub_category,
                        list: res.data.allSubCategories.nodes
                    })
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    previewRender = () => {
        var { listvalue, new_value, image_url } = this.props.sub_category
        if (image_url !== null && new_value !== '') {
            name = new_value
            img = URL.createObjectURL(image_url)
            return true;
        }
        if (listvalue !== null) {
            name = listvalue.name
            img = config.url + '/' + listvalue.imageUrl
            return true
        }
        return false;
    }

    render() {
        var { list, listvalue, new_value } = this.props.sub_category
        const loading = this.state.open && list.length < 0;
        const { classes } = this.props;
        return (
            <>
                <Grid container spacing={4}>
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.title}>Select Sub Categories</Typography>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={6} className={classes.inputStyle}>
                        <Autocomplete
                            id="category" style={{ marginBottom: '10px' }}
                            fullWidth
                            open={this.state.open}
                            onOpen={() => {
                                this.setState({ open: true });
                            }}
                            onClose={() => {
                                this.setState({ open: false });
                            }}
                            value={listvalue}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            options={list}
                            noOptionsText='No Options'
                            loading={loading}
                            onChange={(event, newValue) => {
                                this.props.handleSubCategory({ ...this.props.sub_category, listvalue: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Sub Category"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        {/* Divider */}
                        <div className={classes.divider}>Or</div>
                        <TextField
                            id="CategoryName"
                            label="Create Sub Category"
                            placeholder="Enter title..."
                            value={new_value}
                            onChange={(event) =>
                                this.props.handleSubCategory({
                                    ...this.props.sub_category,
                                    new_value: event.target.value
                                })
                            }
                            fullWidth
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.categoryPreview}>
                            <Typography variant="body2" color="primary">Preview Sub Category</Typography>
                            <div className={classes.previewCard}>
                                {
                                    this.previewRender() ?
                                        <div className={classes.previewImage}>
                                            <SubCategoryCard
                                                preview={true}
                                                card={{
                                                    title: name,
                                                    path: img,
                                                    createdAt: new Date().toISOString()
                                                }}
                                            />
                                        </div>
                                        :
                                        <UploadIcon className={classes.uploadImage} />
                                }
                            </div>
                            <label htmlFor="upload-photo">
                                <input
                                    style={{ display: 'none' }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    type="file"
                                    onChange={(event) => {
                                        const files = event.target.files;
                                        if (files) {
                                            this.props.handleSubCategory({ ...this.props.sub_category, image_url: files[0] ? files[0] : null })
                                        }
                                    }}
                                />
                                {new_value &&
                                    <Button color="primary" fullWidth variant="contained" component="span"
                                        className={classes.uploadButton} disableElevation>
                                        {this.previewRender() ? "Change Image" : "Upload Image"}
                                    </Button>}
                            </label>
                            {
                                this.previewRender() && new_value ?
                                    (<Button color="primary" fullWidth variant="text" component="span"
                                        className={classes.uploadButton} disableElevation
                                        onClick={() => this.props.handleSubCategory({ ...this.props.sub_category, image_url: null })}>
                                        Remove Image
                                    </Button>) : ""
                            }
                        </div>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withStyles((styles))(withApollo(SelectSubCategories));