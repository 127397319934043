import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TOPIC_LIST, FLASHCARDS_BY_TITLE } from '../../graphql/query'
import { mutation } from '../../graphql/mutations';
import { SnackBarContext } from '../../contexts';
import { v4 as uuidv4 } from 'uuid';
import { findIndex } from 'lodash'

class FlashCardScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      list: [],
      listvalue: null,
      flashCards: [],
      isReordered: false,
    }
  }
  componentDidMount() {
    var { client } = this.props
    client.query({
      query: TOPIC_LIST,
      fetchPolicy: "network-only",
    }).then(res => {
      this.setState({
        list: res.data.topics.nodes
      })
    }).catch(err => {
      console.log(err)
    })
  }
  // save flashcards
  saveFlashcards = (type, item) => {
    var { flashCards } = this.state
    var index = findIndex(flashCards, { 'id': item?.id })
    if (type !== "Close") {
      if (type === "Remove")
        flashCards.splice(index, 1)
      if (type === "Save")
        flashCards.splice(index, 1, item)
    }
    this.setState({
      flashCards: flashCards
    })
  }
  // Hanlde onChange
  onChangeHandler = (value) => {
    if (value) {
      var { client } = this.props
      client.query({
        query: FLASHCARDS_BY_TITLE,
        variables: { title_id: value?.id },
        fetchPolicy: "network-only",
      }).then(res => {
        this.setState({
          ...this.state, listvalue: value,
          flashCards: res.data.flashcards.nodes
        })
      }).catch(err => {
        console.log(err)
      })
    } else {
      this.setState({
        ...this.state, listvalue: value,
        flashCards: []
      })
    }

  };
  // List click handler
  listHanlder = (item) => {
    this.props.handleView(item)
  };
  // Reorder Array
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  // Draggable end
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const flashCards = this.reorder(
      this.state.flashCards,
      result.source.index,
      result.destination.index
    );
    this.setState({ ...this.state, flashCards: flashCards, isReordered: true });
  };
  // Update flash cards
  updateFlashCardsHandler = () => {
    var { flashCards } = this.state
    var { client } = this.props
    var variables = {}
    flashCards.forEach((item, index) => {
      variables["id"] = item.id
      variables["order_by"] = index + 1
      variables["updatedAt"] = new Date()
      client.mutate({
        mutation: mutation.card.update,
        variables: variables
      }).then(res => {
        if (res)
          this.context.setSnack({
            ...this.context.snack,
            open: true,
            type: "success",
            msg: "Updated Successfully!",
            vertical: "bottom",
          })
      }).catch(err => {
        console.log(err)
        this.context.setSnack({
          ...this.context.snack,
          open: true,
          type: "error",
          msg: "Something went wrong, please try again later!",
          vertical: "bottom",
        });
      })
    })
  };
  // Content Filter
  render() {
    const { classes } = this.props;
    const { flashCards, list, open, isReordered } = this.state;
    const loading = open && list.length < 0;
    return (
      <Grid
        container
        item
        justify="flex-start"
        xs={12}
        spacing={2}
        className={classes.flashCardsContainer}
      >
        {/* Auto Complete */}
        <Grid item xs={12} sm={12} md={4}>
          <Grid item xs={12}>
            <Autocomplete
              id="category"
              fullWidth
              style={{ marginBottom: "10px" }}
              open={open}
              onOpen={() => {
                this.setState({ ...this.state, open: true });
              }}
              onClose={() => {
                this.setState({ ...this.state, open: false });
              }}
              value={this.state.listvalue}
              getOptionSelected={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option.title}
              options={list}
              noOptionsText="No Options"
              loading={loading}
              onChange={(event, newValue) => this.onChangeHandler(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Topic"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {/* Search */}
          <Grid item xs={12}>
            {/* Add Button */}
            <Button
              color="primary"
              fullWidth
              variant="contained"
              component="span"
              className={classes.updateButton}
              disableElevation
              disabled={!this.state.listvalue}
              onClick={() => {
                this.props.handleEdit({
                  id: uuidv4(),
                  title: '',
                  content: '',
                  type: 'New',
                  order_by: flashCards.length,
                  topic_id: this.state.listvalue?.id
                })
              }}
            >
              Add Card
            </Button>
          </Grid>
          <Grid item xs={12}>
            {/* Update Button */}
            <Button
              color="primary"
              fullWidth
              variant="contained"
              component="span"
              className={classes.updateButton}
              disableElevation
              disabled={!isReordered}
              onClick={() => this.updateFlashCardsHandler()}
            >
              Update
            </Button>
          </Grid>
        </Grid>
        {/* List group */}
        <Grid item xs={12} sm={12} md={8}>
          <Paper
            style={{
              maxHeight: 440,
              overflowY: "auto",
              background: "#fff",
              // borderRadius: 16,
            }}
          >
            {flashCards.length > 0 ? (
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {/* List Group */}
                      <List component="nav" disablePadding dense>
                        {flashCards.map((item, index) => (
                          <Draggable
                            key={`${item.id}`}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  backgroundColor: snapshot.isDragging
                                    ? "#3D4690"
                                    : "#fff",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                {/* List Item */}
                                <ListItem
                                  divider
                                  className={classes.listItem}
                                  onClick={() => this.listHanlder(item)}
                                >
                                  {/* List Item content */}
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="h6"
                                        noWrap
                                        className={classes.listTitle}
                                        style={{
                                          color: snapshot.isDragging
                                            ? "#fff"
                                            : "#000",
                                        }}
                                      >
                                        {index + 1}. {item.title}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography
                                        variant="body1"
                                        noWrap
                                        className={classes.listContent}
                                        style={{
                                          color: snapshot.isDragging
                                            ? "#fff"
                                            : "#949494",
                                        }}
                                      >
                                        {item.content}
                                      </Typography>
                                    }
                                  />
                                  {/* List Item Actions */}
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      size="small"
                                      edge="end"
                                      aria-label="edit"
                                      onClick={() => {
                                        this.props.handleEdit(item)
                                      }}
                                    >
                                      <EditIcon
                                        style={{
                                          fill: snapshot.isDragging
                                            ? "#fff"
                                            : "currentColor",
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() =>
                                        this.props.handleDelete(item)
                                      }
                                    >
                                      <DeleteIcon
                                        style={{
                                          fill: snapshot.isDragging
                                            ? "#fff"
                                            : "currentColor",
                                        }}
                                      />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
                <Typography
                  style={{ color: "#949494", textAlign: "center", padding: 10 }}
                >
                  *Please select the topic to edit the flash cards.
                </Typography>
              )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
FlashCardScreen.contextType = SnackBarContext
export default FlashCardScreen;
