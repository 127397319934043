import {
    Grid,
    Typography, withStyles
} from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import routes from '../../router/routes';
import { VerifyEmail as verify } from '../../services'
import '../loading.css';

const styles = (theme) => ({
    root: {
        height: '100vh',
        width: '100%',
    },
})

class VerifyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirecting: false,
            message: 'Verifying Mail!'
        }
    }
    componentDidMount() {
        const token = this.props.match.params.id;
        verify({
            token
        }).then(e => {
            this.setState({
                redirecting: true,
                message: e.data.message
            }, () => {
                if (e.data.message.includes('successfully')) {
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: routes.Home,
                            state: { resetted: true }
                        });
                    }, 1000);
                }
            })
        }).catch(err => {
            this.setState({
                redirecting: false,
                message: "Something went wrong, Please try again!"
            })
        })
    }
    render() {
        var { classes } = this.props
        var { message, redirecting } = this.state
        return (
            <Grid
                className={classes.root}
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Typography variant="h3" className={classes.resetHeader}
                    color={"primary"}>{message}</Typography>
                {redirecting && <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>}
            </Grid>
        )
    }
}
export default withStyles(styles)(withRouter(VerifyEmail));