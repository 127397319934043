import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import config from "../../config";

const styles = (theme) => ({
  root: {
    padding: 0,
    [theme.breakpoints.only("xs")]: {
      padding: "15px 15px 77px 15px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "20px",
    },
  },

  imgContainer: {
    height: "100%",
    width: "100%",

    [theme.breakpoints.only("md")]: {
      height: "100%",
      width: "100%",
      paddingBottom: "20px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingRight: "0px",
      height: "100%",
      width: "100%",
    },
  },
  text: {
    fontWeight: "600",
    letterSpacing: "0px",
    color: theme.palette.common.white,
    padding: "15px",
    textTransform: "capitalize",
    // fontFamily:"Proxima Nova"
    position: "absolute",
    display: "flex",
    zIndex: 10000,
    cursor: "pointer",
    fontSize: "1.0999rem",
    [theme.breakpoints.only("md")]: {
      paddingLeft: "5px",
      paddingTop: "17px",
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "10px",
      paddingTop: "17px",
    },
    [theme.breakpoints.only("xs")]: {
      //fontSize: "21px",
      paddingLeft: "10px",
      paddingTop: "15px",
      fontSize: "1.0999rem",
    },
  },
  imgProp: {
    borderRadius: "30px",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    background: `transparent linear-gradient(0deg, #00000000 0%, #00000000 0%, #00000066 100%) 0% 0% no-repeat padding-box`,
    opacity: 1,
    filter: "brightness(0.7)",
    boxShadow: "0px 3px 6px #00000029",
  },
  heightCard: (props) => {
    return {
      height: 254,
      position: "relative",
      [theme.breakpoints.only("lg")]: {
        width: props.preview ? "100%" : "90%",
      },
      [theme.breakpoints.only("xs")]: {
        height: props.preview ? 200 : 254,
      },

      "&:hover": {
        cursor: "pointer",
        boxShadow: "0 5px 15px rgba(61, 70, 144, .4)",
        borderRadius: "30px",
      },
    };
  },
  pad: {
    padding: "0px 15px 15px 15px",

    [theme.breakpoints.only("xs")]: {
      padding: "8px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0px 12px 18px 12px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 10px 15px 10px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "10px",
    },
  },
  bottomContainer: {
    height: "53px",
    bottom: "0",
    position: "fixed",
    display: "flex",
    zIndex: 10000,
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
  starIcon: {
    fill: theme.palette.text.hint,
    height: "30px",
    width: 32,
  },
  searchIcon: {
    fill: theme.palette.primary.main,
    height: "40px",
    width: 41,
  },
  addIcon: {
    color: theme.palette.text.hint,
    height: 95,
    width: 80,
  },
  dot: {
    width: "52px",
    height: "52px",
    background: theme.palette.primary.main,
    position: "relative",
    /* top: 152px", */
    zIndex: "100000",
    bottom: "7px",
    borderRadius: "50%",
    left: "0",
    top: "12px",
    right: "0",
    margin: "auto",
    // paddingRight:"8px",
    padding: 0,
    fill: "#ef0404",
    transition: "200ms cubic-bezier(0.14, -0.08, 0.74, 1.4)",
    strokeLinecap: "round",
    // box-shadow: 0px 8px 0px 4px #e8a6a6, 0px 6px 14px 10px #e8a6a6;
    fontSize: "34px",
    textAlign: "center",
    color: "white",
  },
  addCont: {
    width: "72px",
    height: "74px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    position: "absolute",
    top: "-28px",
    marginRight: "auto",
    marginLeft: "-5px",
  },
  componentRender: {
    paddingLeft: 166,
    paddingRight: 168,
    paddingTop: 8,
    overflow: "scroll",
    height: "calc(100vh - 111px)",

    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 18,
    },
  },
});
class MainCards extends React.Component {
  render() {
    const { classes } = this.props;
    const { name, imageUrl, urlParam } = this.props.data;
    let base_url = "";
    const topic_url = `${base_url}/${urlParam}`;
    return (
      <>
        <Grid className={classes.heightCard}>
          {this.props.preview === true ? (
            <Typography variant="h6" className={classes.text}>
              {name}
            </Typography>
          ) : (
            <>
              <Typography
                onClick={() => {
                  this.props.history.push(topic_url);
                }}
                variant="h6"
                className={classes.text}
              >
                {name}
              </Typography>
            </>
          )}

          <Grid className={classes.imgContainer}>
            {this.props.preview ? (
              <img className={classes.imgProp} src={imageUrl} alt="" />
            ) : (
              <img
                onClick={() => {
                  this.props.history.push(topic_url);
                }}
                className={classes.imgProp}
                src={`${config.url}/${imageUrl}`}
                alt=""
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withRouter(withStyles(styles)(MainCards));
