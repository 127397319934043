import {
    Button, Grid, TextField,
    Typography, withStyles
} from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import routes from '../../router/routes';
import { ResetPassword as reset } from '../../services'

const styles = (theme) => ({
    root: {
        height: '100vh',
        width: '100%',
    },
    input: {
        width: "100%",
        marginBottom: 10,
        borderRadius: 8,
        height: "36px !important",
        "& .MuiFormHelperText-contained": {
            marginLeft: 0,
            marginRight: 0,
            textAlign: "center",
            fontWeight: '300',
        },
        "& .MuiFormHelperText-root": {
            marginTop: 1,
            fontSize: 12,
            color: "#f44336",
        }
    },
    resetHeader: {
        fontWeight: 'bold'
    },
    errortext: {
        fontWeight: 300,
    }
})

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirm_password: '',
            error: {},
            isResting: false,
            message: ''
        }
    }

    setValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    onResetClicked = () => {
        const { password, confirm_password } = this.state;

        if (!password || !confirm_password) {
            this.setState({
                message: "Enter the password and confirm password."
            })
        } else if (password !== confirm_password) {
            this.setState({
                message: "Password and confirm password is not matching."
            })
        } else {
            this.setState({
                isResting: true,
            }, () => {
                const token = this.props.match.params.id;
                reset({
                    password: this.state.confirm_password,
                    token,
                    isForgetPassword: true
                }).then((e) => {
                    this.setState({
                        isResting: false
                    }, () => {
                        this.props.history.push({
                            pathname: routes.Home,
                            state: { resetted: true }
                        });
                    })
                }).catch((e) => {
                    this.setState({
                        isResting: false,
                        message: "Something went wrong! Unable to reset"
                    })
                });
            })
        }
    }

    render() {

        const { classes } = this.props;
        const { password, confirm_password, error, isResting, message } = this.state;
        return <div>
            <Grid
                className={classes.root}
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <Typography variant="h3" className={classes.resetHeader}
                        color={"primary"}>{"Reset Password"}</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined" className={classes.input}
                        placeholder="New Password" type='password'
                        value={password}
                        onChange={(e) => this.setValue('password', e.target.value)}
                        helperText={error.password}
                        error={error.password}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined" className={classes.input}
                        placeholder="Confirm Password" type='password'
                        value={confirm_password}
                        onChange={(e) => this.setValue('confirm_password', e.target.value)}
                        helperText={error.confirm_password}
                        error={error.confirm_password}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="caption" className={classes.errortext} color="error">
                        {message}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isResting}
                        onClick={this.onResetClicked}
                    >{
                            `${isResting ? 'Restting...' : 'Reset Password'}`
                        }</Button>
                </Grid>
            </Grid>
        </div>
    }
}

export default withStyles(styles)(withRouter(ResetPassword));