import { gql } from "apollo-boost";

export const GET_ALL_CATEGORIES = (search) => gql`query {
  allCategories(
    condition: { isActive: true }
    orderBy: CREATED_BY_DESC
    ${
      search !== ""
        ? `filter: { name: { startsWithInsensitive: "${search}" } }`
        : ""
    }
  ) {
    nodes {
      id
      imageUrl
      name
      urlParam
    }
  }
}`;

export const Sub_Categories_by_Category_ID = gql`
  query ($search: String, $category_id: UUID) {
    allSubCategories(
      condition: { isActive: true }
      filter: {
        name: { startsWithInsensitive: $search }
        categoryId: { equalTo: $category_id }
      }
      orderBy: NAME_ASC
    ) {
      nodes {
        id
        imageUrl
        name
        createdAt
        urlParam
      }
    }
  }
`;

export const Sub_Categories_by_Category_Name = gql`
  query ($category: String, $search: String) {
    subcategories: allSubCategories(
      condition: { isActive: true }
      filter: {
        name: { includesInsensitive: $search }
        categoryByCategoryId: { urlParam: { equalToInsensitive: $category } }
      }
      orderBy: CREATED_AT_DESC
    ) {
      nodes {
        id
        imageUrl
        name
        createdAt
        urlParam
      }
    }
  }
`;

export const Topics_by_Category_Name = gql`
  query ($category: String, $search: String) {
    topics: allTopics(
      condition: { isActive: true }
      filter: {
        title: { includesInsensitive: $search }
        categoryByCategoryId: { urlParam: { equalToInsensitive: $category } }
      }
      orderBy: CREATED_AT_DESC
    ) {
      nodes {
        id
        imageUrl
        title
        createdAt
        urlParam
      }
    }
  }
`;

export const Topics_by_SubCategory_Name = gql`
  query ($sub_category: String, $search: String) {
    topics: allTopics(
      filter: {
        subCategoryBySubCategoryId: {
          urlParam: { equalToInsensitive: $sub_category }
        }
        title: { includesInsensitive: $search }
      }
      orderBy: CREATED_AT_DESC
      condition: { isActive: true }
    ) {
      nodes {
        id
        imageUrl
        title
        urlParam
        createdAt
      }
    }
  }
`;

export const GET_ALL_FLASH_CARDS = gql`
  query ($topic_title: String, $user_id: UUID) {
    allCards(
      filter: {
        topicByTopicId: { urlParam: { equalToInsensitive: $topic_title } }
      }
      orderBy: ORDER_BY_ASC
    ) {
      nodes {
        id
        imageUrl
        title
        content
        isLiked: bookmarksByCardsId(condition: { createdBy: $user_id }) {
          nodes {
            id
            cardsId
            createdBy
          }
        }
      }
    }
  }
`;

export const FIND_BOOKMARK = gql`
  query ($user_id: UUID!, $card_id: UUID) {
    allBookmarks(condition: { cardsId: $card_id, createdBy: $user_id }) {
      nodes {
        id
      }
    }
  }
`;

export const FIND_ALL_BOOKMARKS = (user_id, search) => gql`query findbookmarks {
  allBookmarks(
    filter: { cardByCardsIdExists: true }
    condition: { createdBy: "${user_id}" }
    orderBy: CREATED_AT_DESC
    ${
      search !== ""
        ? `filter: { cardByCardsId: { content: { includesInsensitive: "${search}" } } }`
        : ""
    }
  ) {
    nodes {
      id
      card: cardByCardsId {
        id
        title
        content
        topic: topicByTopicId {
          id
          title
          imageUrl
          urlParam
          category: categoryByCategoryId {
            name
            urlParam
          }
          subcategory: subCategoryBySubCategoryId {
            id
            name
            urlParam
            category: categoryByCategoryId {
              name
              urlParam
            }
          }
        }
      }
    }
  }
}`;

export const CATEGORY_LIST = gql`
  query ($first: Int, $offset: Int) {
    categories: allCategories(
      first: $first
      offset: $offset
      orderBy: NAME_ASC
    ) {
      nodes {
        id
        imageUrl
        isActive
        name
        createdAt
        urlParam
      }
    }
  }
`;

export const SUBCATEGORY_LIST = gql`
  query ($first: Int, $offset: Int) {
    subCategories: allSubCategories(
      first: $first
      offset: $offset
      orderBy: NAME_ASC
    ) {
      nodes {
        id
        imageUrl
        isActive
        name
        createdAt
        urlParam
      }
    }
  }
`;

export const TOPIC_LIST = gql`
  query ($first: Int, $offset: Int) {
    topics: allTopics(first: $first, offset: $offset, orderBy: TITLE_ASC) {
      nodes {
        id
        imageUrl
        isActive
        createdAt
        title
        urlParam
      }
    }
  }
`;

export const FLASHCARDS_BY_TITLE = gql`
  query ($title_id: String!) {
    flashcards: allCards(
      orderBy: ORDER_BY_ASC
      condition: { topicId: $title_id }
    ) {
      nodes {
        id
        title
        content
      }
    }
  }
`;

export const SUBTITLEBARQUERY = gql`
  query ($category: String, $subcategory: String, $topic: String) {
    category: allCategories(condition: { urlParam: $category }) {
      nodes {
        id
        name
        urlParam
      }
    }
    subcategory: allSubCategories(condition: { urlParam: $subcategory }) {
      nodes {
        id
        name
        urlParam
      }
    }
    topic: allTopics(condition: { urlParam: $topic }) {
      nodes {
        id
        title
        urlParam
      }
    }
  }
`;
