import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { withApollo, Query } from "react-apollo";
import { GET_ALL_CATEGORIES } from "../../graphql/query";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { MainCards } from "../../components";
import BottomNavbar from "../../components/bottomNavbar";

const styles = (theme) => ({
  componentRender: {
    paddingLeft: 166,
    paddingRight: 166,
    paddingTop: 8,
    overflowY: "scroll",
    overflowX: "hidden",
    height: "calc(100vh - 111px)",
    /* width  */
    "&::-webkit-scrollbar": {
      width: 0,
      display: "none",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#3D4690",
      borderRadius: 15,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#303f9f",
    },
    [theme.breakpoints.down("sm")]: {
      /* width  */
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: "calc(100vh - 167px)",
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 18,
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: 66,
      paddingRight: 66,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 166,
      paddingRight: 166,
    },
  },
  root: {
    padding: 0,
    [theme.breakpoints.only("xs")]: {
      padding: "15px 10px 77px 10px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "20px 30px",
    },
  },
  pad: {
    padding: "0px 15px 15px 15px",

    [theme.breakpoints.only("xs")]: {
      padding: "8px",
    },
    [theme.breakpoints.only("xl")]: {
      padding: "0px 20px 20px 20px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0px 0px 18px 0px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 10px 15px 10px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "10px",
    },
  },
});

class Categories extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.componentRender}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.root}
        >
          <Query
            query={GET_ALL_CATEGORIES(this.props.search)}
            fetchPolicy={"network-only"}
          >
            {({ data, loading, error }) => {
              if (loading)
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </Grid>
                );
              if (error)
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Typography variant="h5">
                      Some error occurred, please try refresh!
                    </Typography>
                  </Grid>
                );
              if (data.allCategories.nodes.length > 0) {
                const categories = data.allCategories.nodes;
                return categories.map((card) => (
                  <Grid
                    key={card.id}
                    className={classes.pad}
                    item
                    xl={2}
                    lg={2}
                    md={3}
                    xs={6}
                    sm={3}
                  >
                    {this.props.editable !== true ? (
                      <MainCards key={card.id} data={card} />
                    ) : (
                      <MainCards
                        key={card.id}
                        data={card}
                        preview={this.props.preview}
                        editable={this.props.editable}
                      />
                    )}
                  </Grid>
                ));
              } else {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Typography variant="h5">
                      No such categories found!
                    </Typography>
                  </Grid>
                );
              }
            }}
          </Query>
        </Grid>
        {/* Bottom Navigation */}
        <Hidden mdUp>
          <BottomNavbar />
        </Hidden>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(withApollo(Categories)));
