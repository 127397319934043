import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="backend" transform="translate(635.974 328.909)">
            <g id="Group_116">
                <path id="Path_148" d="M-597.7-298.6h-0.9v-0.9c0-1.5-1.2-2.8-2.8-2.8h-8.3v-1.8h2.8c0.5,0,0.9-0.4,1-0.9c0-0.5-0.4-0.9-0.9-1
           c0,0-0.1,0-0.1,0h-0.9v-1.8h2.8c1.5,0,2.8-1.2,2.8-2.8v-5.6c0-1.5-1.2-2.8-2.8-2.8h-11c-1.5,0-2.8,1.2-2.8,2.8v5.6
           c0,1.5,1.2,2.8,2.8,2.8h2.8v1.8h-0.9c-0.5,0-0.9,0.5-0.9,1c0,0.5,0.4,0.8,0.9,0.9h2.8v1.8h-8.3c-1.5,0-2.8,1.2-2.8,2.8v0.9h-0.9
           c-1.5,0-2.8,1.2-2.8,2.8v1.8c0,1.5,1.2,2.8,2.8,2.8h0.9v1.9h-0.9c-0.5,0-0.9,0.5-0.9,1c0,0.5,0.4,0.8,0.9,0.9h3.7
           c0.5,0,0.9-0.5,0.9-1c0-0.5-0.4-0.8-0.9-0.9h-0.9v-1.9h0.9c1.5,0,2.8-1.2,2.8-2.8v-1.8c0-1.5-1.2-2.8-2.8-2.8h-0.9v-0.9
           c0-0.5,0.4-0.9,0.9-0.9l0,0h8.3v1.8h-0.9c-1.5,0-2.8,1.2-2.8,2.8v1.8c0,1.5,1.2,2.8,2.8,2.8h0.9v1.9h-0.9c-0.5,0-0.9,0.5-0.9,1
           c0,0.5,0.4,0.8,0.9,0.9h3.7c0.5,0,0.9-0.5,0.9-1c0-0.5-0.4-0.8-0.9-0.9h-0.9v-1.9h0.9c1.5,0,2.8-1.2,2.8-2.8v-1.8
           c0-1.5-1.2-2.8-2.8-2.8h-0.9v-1.8h8.3c0.5,0,0.9,0.4,0.9,0.9l0,0v0.9h-0.9c-1.5,0-2.8,1.2-2.8,2.8v1.8c0,1.5,1.2,2.8,2.8,2.8h0.9
           v1.9h-0.9c-0.5,0-0.9,0.4-1,0.9c0,0.5,0.4,0.9,0.9,1c0,0,0.1,0,0.1,0h3.7c0.5,0,0.9-0.5,0.9-1c0-0.5-0.4-0.8-0.9-0.9h-0.9v-1.9
           h0.9c1.5,0,2.8-1.2,2.8-2.8v-1.8C-595-297.3-596.2-298.6-597.7-298.6z M-620-296.7c0.5,0,0.9,0.4,0.9,0.9l0,0v1.8
           c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.7c-0.5,0-0.9-0.4-0.9-0.9l0,0v-1.8c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0L-620-296.7z M-616.2-309.6
           c-0.5,0-0.9-0.4-0.9-0.9v-5.6c0-0.5,0.4-0.9,0.9-0.9h11.1c0.5,0,0.9,0.4,0.9,0.9c0,0,0,0,0,0v5.6c0,0.5-0.4,0.9-0.9,0.9l0,0
           H-616.2z M-608.8-296.7c0.5,0,0.9,0.4,0.9,0.9l0,0v1.8c0,0.5-0.4,0.9-0.9,0.9l0,0h-3.7c-0.5,0-0.9-0.4-0.9-0.9l0,0v-1.8
           c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0L-608.8-296.7z M-611.6-305.9v-1.8h1.8v1.8L-611.6-305.9z M-596.8-294c0,0.5-0.4,0.9-0.9,0.9
           l0,0h-3.7c-0.5,0-0.9-0.4-0.9-0.9l0,0v-1.8c0-0.5,0.4-0.9,0.9-0.9l0,0h3.7c0.5,0,0.9,0.4,0.9,0.9l0,0V-294z"/>
            </g>
        </g>
    </svg>

);
export default SVG;
