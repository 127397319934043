import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from '../contexts';
import { get } from 'lodash'

function PrivateRoute({ component: Component, ...rest }) {
    const Auth = React.useContext(AuthContext)
    const isAuthenticated = localStorage.getItem("auth_token") ? true : false;
    const isRoleAdmin = get(Auth.user, ['user_profiles', '0', 'workspace_user_profiles', '0', 'role', 'name']) === 'admin'
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated && isRoleAdmin ? (
                    <Component {...props} />
                ) : (
                        <Redirect to="/" />
                    )
            }
        />
    );
}

export default PrivateRoute;