import React from "react";
import { TablePagination, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    "&::-webkit-scrollbar": {
      height: 0,
    },
  },
}));

function DashboardTablePagination({
  page,
  rowsPerPage,
  count,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const classes = useStyles();
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      className={classes.tablePagination}
    />
  );
}

export default DashboardTablePagination;
