import React from 'react';

const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="logout" transform="translate(7.883 406.474)">
            <g id="Group_82">
                <g id="Group_81">
                    <path id="Path_105" d="M28.4-392H10.5c-2.2,0-4.1,1.8-4.1,4.1v1c0,0.6,0.5,1,1,1s1-0.5,1-1l0,0v-1c0-1.1,0.9-2,2-2h17.9
				c1.1,0,2,0.9,2,2v17.9c0,1.1-0.9,2-2,2H10.5c-1.1,0-2-0.9-2-2v-1c0-0.6-0.5-1-1-1s-1,0.5-1,1v1c0,2.2,1.8,4.1,4.1,4.1h17.9
				c2.2,0,4.1-1.8,4.1-4.1v-17.9C32.4-390.2,30.6-392,28.4-392z"/>
                </g>
            </g>
            <g id="Group_84" transform="translate(0.001 6.755)">
                <g id="Group_83">
                    <path id="Path_106" d="M8.8-385L8.8-385c-0.4-0.4-0.4-1,0-1.4l3.8-3.8c0.4-0.4,0.5-1,0.1-1.4c-0.4-0.4-1-0.5-1.4-0.1
				c0,0-0.1,0.1-0.1,0.1l-3.8,3.8c-1.2,1.2-1.2,3.1,0,4.3l3.8,3.8c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0L8.8-385z"/>
                </g>
            </g>
            <g id="Group_86" transform="translate(5.079 11.987)">
                <g id="Group_85">
                    <path id="Path_107" d="M18.3-392H7.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1c0,0,0,0,0,0h10.8c0.6,0,1-0.5,1-1
				C19.3-391.5,18.8-392,18.3-392C18.3-392,18.3-392,18.3-392z"/>
                </g>
            </g>
        </g>
    </svg>
)

export default SVG;