import React from 'react';
import { Grid, Typography } from '@material-ui/core';

class BlankPage extends React.Component {

    render() {
        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                        <Typography variant={"h5"}> 404 Page not found..!!!</Typography>
            </Grid>
        )
    }
}
export default (BlankPage);