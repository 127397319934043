import React from "react";
import { Grid, Typography, Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { parseISO, differenceInDays } from "date-fns";
import { withRouter } from "react-router-dom";
import config from "../../config";
// import EditSubCard from './editSubCard';

const styles = (theme) => ({
  cardContainer: (props) => {
    return {
      borderRadius: 18,
      backgroundColor: theme.palette.common.white,
      height: props.isSubOnly ? 170 : 160,
      width: props.isSubOnly ? 170 : 160,
      padding: "10px",
      textAlign: "center",
      position: "relative",
      boxShadow: "3px 3px 6px #00000029;",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "5px 5px 10px #00000029;",
      },
      [theme.breakpoints.down("sm")]: {
        width: props.isSubOnly ? "100%" : 160,
      },
    };
  },
  imageContainer: {
    textAlign: "center",
  },
  imageStyle: (props) => {
    return {
      width: props.isSubOnly ? 100 : 80,
      height: props.isSubOnly ? 100 : 80,
      borderRadius: "50%",
      objectFit: "cover",
      "&:hover": {
        cursor: "pointer",
      },
    };
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    padding: "5px",
  },
  trending: {
    fontWeight: "300",
    position: "absolute",
    right: -10,
    top: 10,
    "& .MuiChip-root": {
      height: 20,
      backgroundColor: "#FFD447FC",
      color: theme.palette.common.white,
      fontSize: 11,
    },
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class SubCategoryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { classes } = this.props;
    const preview = this.props.preview ? this.props.preview : false;
    const result = this.props.card;
    const path = result.path ? result.path : config.url + "/" + result.imageUrl;
    //const id = result?.id?.split("-")?.pop();
    return (
      <>
        {/* Card Container */}
        <Grid
          item
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          onClick={() => {
            if (preview !== true) {
              this.props.history.push(
                `${this.props.location.pathname}/subcategory/${result.urlParam}`
              );
            } else if (this.props.editable === true) {
              this.setState({ open: true });
            }
          }}
          className={classes.cardContainer}
          lg={12}
        >
          <Grid item xs={12}>
            {/* Card Image */}
            <div className={classes.imageContainer}>
              <img className={classes.imageStyle} src={`${path}`} alt="" />
            </div>
          </Grid>
          <Grid item xs={12}>
            {/* Card Name */}
            <Typography className={classes.cardTitle} variant="h6">
              {result.name ? result.name : result.title}
            </Typography>
          </Grid>
          {/* Trending */}
          {differenceInDays(Date.now(), parseISO(result.createdAt)) < 3 && (
            <div className={classes.trending}>
              <Chip label="New" />
            </div>
          )}
        </Grid>
        {/* <EditSubCard
                    open={this.state.open}
                    handleClose={() => this.setState({ open: false })}
                    card={{
                        id: result.id,
                        title: result.title,
                        path: result.path,
                        createdAt: new Date().toISOString()
                    }} /> */}
      </>
    );
  }
}
export default withRouter(withStyles(styles)(SubCategoryCard));
