import React from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { withApollo, Query } from "react-apollo";
import { Topics_by_SubCategory_Name } from "../../graphql/query";
import { withRouter } from "react-router-dom";
import { SubCards } from "../../components";
import BottomNavbar from "../../components/bottomNavbar";
import "../loading.css";

const styles = (theme) => ({
  componentRender: {
    paddingLeft: 60,
    paddingRight: 50,
    overflowY: "scroll",
    overflowX: "hidden",
    height: "calc(100vh - 111px)",
    /* width  */
    "&::-webkit-scrollbar": {
      width: 0,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#3D4690",
      borderRadius: 15,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#303f9f",
    },
    [theme.breakpoints.down("sm")]: {
      /* width  */
      "&::-webkit-scrollbar": {
        width: 0,
      },
      borderTopLeftRadius: "38px",
      borderBottomLeftRadius: "38px",
    },
    [theme.breakpoints.down("sm")]: {
      borderTopLeftRadius: "38px",
      borderTopRightRadius: "38px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      background: theme.palette.background.paper,
      height: "calc(100vh - 167px)",
      marginTop: -40,
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: 8,
      paddingLeft: 166,
      paddingRight: 166,
    },
  },
  root: {
    padding: '10px',
    [theme.breakpoints.only("xs")]: {
      padding: "15px 15px 77px 15px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "20px",
    },
  },
});

function TopicsList(props) {
  const { classes } = props;
  const { subcategory } = props.match.params;
  return (
    <div className={classes.componentRender}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        spacing={4}
        alignItems="center"
        className={classes.root}
      >
        <Query
          query={Topics_by_SubCategory_Name}
          variables={{
            sub_category: subcategory,
            search: props.search,
          }}
        >
          {({ data, loading, error }) => {
            if (loading)
              return (
                <Grid
                  item
                  container
                  xs={12}
                  justify="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </Grid>
              );
            if (error)
              return (
                <Grid
                  item
                  container
                  xs={12}
                  justify="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Typography>
                    Some error occurred, please try refresh!
                  </Typography>
                </Grid>
              );
            if (data && data.topics.nodes.length) {
              const topics = data.topics.nodes;
              return topics.map((card) => (
                <Grid item key={card.id} lg={4} md={6} sm={6} xs={12}>
                  <SubCards
                    key={card.id}
                    card={card}
                    preview={props.preview}
                    editable={props.editable}
                  />
                </Grid>
              ));
            } else {
              return (
                <Grid
                  item
                  container
                  xs={12}
                  justify="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Typography>No such data found!</Typography>
                </Grid>
              );
            }
          }}
        </Query>
      </Grid>
      {/* Bottom Navigation */}
      <Hidden mdUp>
        <BottomNavbar />
      </Hidden>
    </div>
  );
}
export default withRouter(withStyles(styles)(withApollo(TopicsList)));
