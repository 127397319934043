import React, { Component } from 'react';
import { withApollo } from "react-apollo";
import { findIndex } from "lodash";
import IconButton from '@material-ui/core/IconButton';
// import ShareIcon from '@material-ui/icons/Share';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SwipeListener from 'swipe-listener';
import { AuthContext, ModalContext, SnackBarContext } from './../../contexts';
import { Share } from '../';
import { FIND_BOOKMARK } from "../../graphql/query";
import { withStyles, Hidden, Typography, Grid } from '@material-ui/core';
import { mutation, variables } from '../../graphql/mutations';
import { ShareIcon, HeartIcon } from "../../icons";
import './style.css';

const styles = (theme) => ({
    cardText: {
        fontSize: "1.2rem",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            height: "100%"
        },
    },
    arrowContainer: {
        display: "flex",
        alignItems: "center"
    },
    arrowStyle: {
        backgroundColor: theme.palette.primary.light,
        padding: 20,
        opacity: 0.2,
        "& .MuiIconButton-label": {
            color: "#fff"
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            opacity: 0.4,
        }
    },
    mainCard: {
        margin: "auto",
        height: 600,
        position: "relative",
        maxWidth: 700,
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            height: "100%"
        }
    },
    mainCardContainer: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    cardContainer: {
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowWrap: "break-word",
        padding: 20,
        [theme.breakpoints.only("xs")]: {
            padding: "15px 10px"
        }
    },
    cardTitle: {
        color: theme.palette.primary.main,
        fontSize: 28,
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: 10,
        fontFamily: "Neutra Text",
        [theme.breakpoints.only("xs")]: {
            padding: "5px 0",
            fontSize: 24,
        }
    },
    cardContent: {
        fontSize: 24,
        fontWeight: "bold",
        fontFamily: "Neutra Text",
        padding: 20,
        [theme.breakpoints.only("xs")]: {
            padding: "0px 20px",
            marginTop: 5,
            fontSize: 20
        }
    },
    cardFooter: {
        padding: 10,
    },
    cardCounterText: {
        fontWeight: 'lighter',
        fontSize: 16,
        color: "#B7B7B7",
    },
    cardActions: {
        float: "right",
        "& .MuiIconButton-root:hover": {
            background: "initial",
            "& svg": {
                fill: theme.palette.primary.main
            }
        },
    },
    shareIcon: {
        width: 32,
        height: 32,
        fill: "#d8d8d8",
        [theme.breakpoints.only("xs")]: {
            width: 28,
            height: 28,
        }
    },
    heartIcon: {
        width: 32,
        height: 32,
        fill: "#d8d8d8",
        "&.isLiked": {
            fill: "#C20404"
        },
        [theme.breakpoints.only("xs")]: {
            width: 28,
            height: 28,
        }
    },
    showingCardStyle: {
        position: "absolute",
        height: 500,
        width: "100%",
        top: 0,
        marginTop: 50,
        borderRadius: 20,
        boxShadow: "0px 0px 28px #00000029",
        backgroundColor: "#FFFFFF",
        zIndex: 1,
        opacity: 1,
        [theme.breakpoints.down("sm")]: {
            height: "85%",
            borderRadius: 36,
            marginTop: 25
        }
    },
    firstLeftCard: {
        position: "absolute",
        height: "80%",
        width: "100%",
        top: 60,
        left: -35,
        border: 0,
        borderRadius: 20,
        boxShadow: "0px 0px 28px #00000029",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        opacity: 0.45,
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    secondLeftCard: {
        position: "absolute",
        height: "75%",
        width: "100%",
        top: 75,
        left: -70,
        border: 0,
        borderRadius: 20,
        boxShadow: "0px 0px 28px #00000029",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        opacity: 0.35,
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    firstRightCard: {
        position: "absolute",
        height: "80%",
        width: "100%",
        top: 60,
        right: -35,
        border: 0,
        borderRadius: 20,
        boxShadow: "0px 0px 28px #00000029",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        opacity: 0.45,
        [theme.breakpoints.down("sm")]: {
            top: 35,
            height: "85%",
            right: 0,
            borderRadius: 36,
            boxShadow: "0px 3px 6px #00000029",
        }
    },
    secondRightCard: {
        position: "absolute",
        height: "75%",
        width: "100%",
        top: 75,
        right: -70,
        border: 0,
        borderRadius: 20,
        boxShadow: "0px 0px 28px #00000029",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        opacity: 0.35,
        [theme.breakpoints.down("sm")]: {
            top: 45,
            height: "85%",
            right: 0,
            borderRadius: 36,
            boxShadow: "0px 3px 6px #00000029",
        }
    },
})

class ListCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsData: this.props.data,
            currentCardDetails: { ...this.props.data[this.props.card_index] },
            // secondCardDetais:{...this.props.data[1]},
            count: 0,
            open: false,
            url: '',
            title: '',
            Bookmarks: [],
        }
    }

    handleCardDetails = (event) => {

        let count//, secondCardDetais;
        if (event.currentTarget.id === "leftArrow") {
            if (this.state.count === 0) {
                count = 0
                // secondCardDetais={...this.state.detailsData[count]}
            }
            else {
                count = this.state.count - 1;
            }
            //this.setState({ secondCardDetais: secondCardDetais })

            document.getElementById('currentCardText').style = ' ';
            void document.getElementById('currentCardText').offsetWidth;
            document.getElementById('currentCardText').style.animation = 'textBlur 500ms ease-in-out';

            document.getElementById('currentCardTitle').style = ' ';
            void document.getElementById('currentCardTitle').offsetWidth;
            document.getElementById('currentCardTitle').style.animation = 'textBlur 500ms ease-in-out';

            let currentCardDetails = { ...this.state.detailsData[count] }


            setTimeout(function () {
                this.setState(() => {
                    return {
                        currentCardDetails: currentCardDetails,
                        count: count,
                        //  secondCardDetais:secondCardDetais
                    }
                }, () => console.log(this.state))
            }.bind(this), 500)
        }

        else {
            if (this.state.count === this.state.detailsData.length - 1) {
                count = this.state.detailsData.length - 1
            }
            else {
                count = this.state.count + 1;
            }

            //this.setState({ secondCardDetais: secondCardDetais })

            document.getElementById('currentCardText').style = ' ';
            void document.getElementById('currentCardText').offsetWidth;
            document.getElementById('currentCardText').style.animation = 'textBlur 500ms ease-in-out';

            document.getElementById('currentCardTitle').style = ' ';
            void document.getElementById('currentCardTitle').offsetWidth;
            document.getElementById('currentCardTitle').style.animation = 'textBlur 500ms ease-in-out';

            let currentCardDetails = { ...this.state.detailsData[count] }


            setTimeout(function () {
                this.setState(() => {
                    return {
                        currentCardDetails: currentCardDetails,
                        count: count,
                        // secondCardDetais:secondCardDetais
                    }
                }, () => console.log(this.state))
            }.bind(this), 500)

        }

    }

    setBookmarks = (data) => {
        const bookmarks = []
        data.map(e => {
            if (e.isLiked.nodes.length >= 1) { bookmarks.push(e.isLiked.nodes[0]) }
            return null;
        })
        this.setState({ Bookmarks: bookmarks }, () => {
            //console.log(bookmarks)
        })
    }

    /*setData_CurrentCard = (data, index) => {
        this.setState({
            detailsData: data,
            currentCardDetails: { ...data[index] },
        }, () => {
            console.log(this.state)
        })
    }*/

    handleBookmark = (info_id, snack) => {
        let { client } = this.props;
        let user_profiles = this.context.user?.user_profiles
        let user_id = user_profiles[0].id
        client.query({
            query: FIND_BOOKMARK,
            variables: {
                user_id: user_id,
                card_id: info_id
            },
            fetchPolicy: "network-only",
        }).then((res) => {
            if (res.data.allBookmarks.nodes.length > 0) {
                let values = variables.bookmark.remove
                values['id'] = res.data.allBookmarks.nodes[0].id
                client.mutate({
                    mutation: mutation.bookmark.remove,
                    variables: { ...values }
                }).then((res) => {
                    if (res) {
                        const { Bookmarks } = this.state
                        const index = findIndex(Bookmarks, ["cardsId", this.state.currentCardDetails.id])
                        if (index >= 0) {
                            Bookmarks.splice(index, 1)
                        }
                        this.setState({
                            Bookmarks: Bookmarks
                        }, () => { console.log("REMOVED_BOOKMARK") })
                        snack.setSnack({
                            ...snack,
                            open: true,
                            type: "success",
                            msg: "This card is removed from bookmark.",
                            vertical: "bottom",
                        });
                        //console.log("REMOVE_BOOKMARK")
                    }
                })
            } else {
                let values = variables.bookmark.add
                values['user_id'] = user_id
                values['card_id'] = info_id
                values['createdAt'] = new Date()
                values['updatedAt'] = new Date()
                client.mutate({
                    mutation: mutation.bookmark.add,
                    variables: {
                        ...values
                    }
                }).then((res) => {
                    if (res) {
                        const { Bookmarks } = this.state
                        Bookmarks.push(res.data.createBookmark.bookmark)
                        this.setState({
                            Bookmarks: Bookmarks
                        }, () => { console.log("ADDED_BOOKMARK") })
                        snack.setSnack({
                            ...snack,
                            open: true,
                            type: "success",
                            msg: "This card has been bookmarked!",
                            vertical: "bottom",
                        });
                        //console.log("ADD_BOOKMARK")
                    }
                })
            }
        }).catch((err) => {
            console.log(err);
            snack.setSnack({
                ...snack,
                open: true,
                type: "error",
                msg: "Something went wrong, please try again later!",
                vertical: "bottom",
            });
        });
    }

    callback = (info_id, snack) => {
        let user_profiles = this.context.user?.user_profiles
        user_profiles && this.props.refetch({
            variables: {
                user_id: user_profiles[0].id
            }
        }).then((res) => {
            const data = res.data.allCards.nodes
            //const index = (findIndex(data, { id: info_id }))
            const bookmarks = []
            data.map(e => {
                if (e.isLiked.nodes.length >= 1) { bookmarks.push(e.isLiked.nodes[0]) }
                return null;
            })
            //this.setBookmarks(data);
            //this.setData_CurrentCard(data, index)
            if (findIndex(bookmarks, { cardsId: info_id }) >= 0) {
                snack.setSnack({
                    ...snack,
                    open: true,
                    type: "info",
                    msg: "It's already bookmarked!",
                    vertical: "bottom",
                });
            } else {
                this.handleBookmark(info_id, snack)
                setTimeout(() => {
                    window.location.reload(true)
                }, 300);
            }
        })
    }

    dynamicChangeColor = (snack, Login) => {
        const info_id = this.state.currentCardDetails.id
        let user_profiles = this.context.user?.user_profiles
        if (!user_profiles) {
            Login.setModal({
                open: true,
                cb: () => {
                    this.callback(info_id, snack)
                }
            })
            snack.setSnack({
                ...snack,
                open: true,
                type: "warning",
                msg: "Please log in / sign up to bookmark.",
                vertical: "bottom",
            });
        } else {
            this.handleBookmark(info_id, snack)
        }
    }


    handleCardDetailsForMobile = (swipe) => {
        let count;
        if (swipe === "bottom") {
            if (this.state.count === 0) {
                count = 0
            }
            else {
                count = this.state.count - 1;
            }

            document.getElementById('currentShowingCard').style = ' ';
            void document.getElementById('currentShowingCard').offsetWidth;
            document.getElementById('currentShowingCard').style.animation = 'mymoveBottom 500ms ease-in-out '; //mymoveBottomScale 500ms ease-in-out

            document.getElementById('firstRightCard').style = ' ';
            void document.getElementById('firstRightCard').offsetWidth;
            document.getElementById('firstRightCard').style.animation = 'mymoveBottomTopFirstCard 500ms ease-in-out';

            document.getElementById('secondRightCard').style = ' ';
            void document.getElementById('secondRightCard').offsetWidth;
            document.getElementById('secondRightCard').style.animation = 'mymoveBottomTopSecondCard 500ms ease-in-out';

            let currentCardDetails = { ...this.state.detailsData[count] }
            this.setState(() => {
                return {
                    currentCardDetails: currentCardDetails,
                    count: count
                }
            }, () => { })
        }
        else if (swipe === "top") {
            if (this.state.count === this.state.detailsData.length - 1) {
                count = this.state.detailsData.length - 1
            }
            else {
                count = this.state.count + 1;
            }

            document.getElementById('currentShowingCard').style = ' ';
            void document.getElementById('currentShowingCard').offsetWidth;
            document.getElementById('currentShowingCard').style.animation = 'mymoveTop 500ms ease-in-out ';  //mymoveTopScale 500ms ease-in-out

            document.getElementById('secondRightCard').style = ' ';
            void document.getElementById('secondRightCard').offsetWidth;
            document.getElementById('secondRightCard').style.animation = 'mymoveTopBottomSecondCard 500ms ease-in-out';

            document.getElementById('firstRightCard').style = ' ';
            void document.getElementById('firstRightCard').offsetWidth;
            document.getElementById('firstRightCard').style.animation = 'mymoveTopBottomFirstCard 500ms ease-in-out';

            let currentCardDetails = { ...this.state.detailsData[count] }

            this.setState(() => {
                return {
                    currentCardDetails: currentCardDetails,
                    count: count,
                    // animateClass:"currentSHowingCardAnimation"
                }
            }, () => { })

        }
    }



    componentDidMount() {
        const self = this;
        var currentShowingCard = document.querySelector('#currentShowingCard');
        window.innerWidth < 1000 && SwipeListener(currentShowingCard);

        currentShowingCard.addEventListener('swipe', function (e) {
            var directions = e.detail.directions;
            // var x = e.detail.x;
            // var y = e.detail.y;

            if (directions.top) {
                self.handleCardDetailsForMobile("top");
            }

            if (directions.bottom) {
                self.handleCardDetailsForMobile("bottom");
            }
        });

        this.setBookmarks(this.props.data);
    }

    handleOnClick = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: this.state.currentCardDetails.title,
                    //text: `Check out ${postTitle} on ${siteTitle}`,
                    url: document.location.href,
                })
                .then(() => {
                    console.log('Successfully shared');
                })
                .catch(error => {
                    console.error('Something went wrong sharing the blog', error);
                });
        } else {
            this.setState({ open: true, url: document.location.href, title: this.state.currentCardDetails.title })
        }
    };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { classes } = this.props;
        return (
            <SnackBarContext.Consumer>
                {snack => <>
                    {/* Left arrow */}
                    <Hidden smDown>
                        <div className={classes.arrowContainer} style={{ justifyContent: "flex-start" }}>
                            <IconButton id="leftArrow" className={`${classes.arrowStyle}`} onClick={(event) => this.handleCardDetails(event)}>
                                < ArrowBackIosIcon />
                            </IconButton>
                        </div>
                    </Hidden>

                    {/* Main Card */}
                    <div className={`${classes.mainCard} col-md-5`}>
                        <div className={classes.mainCardContainer}>
                            {/* First left side card placeholder */}
                            <div className={classes.firstLeftCard}></div >
                            {/* Second left side card placeholder */}
                            <div className={classes.secondLeftCard}></div>
                            {/* Currently Showing Card */}
                            <div id="currentShowingCard" className={classes.showingCardStyle}>
                                {/* Card Container */}
                                <div className={classes.cardContainer}>

                                    {/* Card Title */}
                                    <Typography id="currentCardTitle" variant="h3" className={classes.cardTitle}>{this.state.currentCardDetails.title}</Typography>
                                    {/* Card Content */}
                                    <Typography id="currentCardText" className={classes.cardContent}>{this.state.currentCardDetails.content}</Typography>

                                    {/* Card Footer cardFooter */}
                                    <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.cardFooter}>
                                        {/* Left spacer */}
                                        <Grid item sm={4} xs={4}></Grid>
                                        <Grid item sm={4} xs={3} style={{ textAlign: "center" }}>
                                            {/* Cards counter number */}
                                            <span className={classes.cardCounterText}>{findIndex(this.state.detailsData, { id: this.state.currentCardDetails.id }) + 1}/{this.state.detailsData.length}</span>
                                        </Grid>
                                        {/* Card Actions */}
                                        <Grid item sm={4} xs={5}>
                                            <div className={classes.cardActions}>
                                                {/* Bookmark icon */}
                                                <ModalContext.Consumer>
                                                    {Login => <IconButton aria-label="add to favorites" onClick={() => this.dynamicChangeColor(snack, Login)}>
                                                        <HeartIcon
                                                            className={`${classes.heartIcon} ${findIndex(this.state.Bookmarks, { cardsId: this.state.currentCardDetails.id }) >= 0 && "isLiked"}`}
                                                        />
                                                    </IconButton>}
                                                </ModalContext.Consumer>
                                                {/* Share icon */}
                                                <IconButton aria-label="add to share" onClick={() => this.handleOnClick()}>
                                                    <ShareIcon className={classes.shareIcon} />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            {/* First right side card placeholder */}
                            <div id="firstRightCard" className={classes.firstRightCard}></div>
                            {/* Second right side card placeholder */}
                            <div id="secondRightCard" className={classes.secondRightCard}></div>
                        </div >
                    </div >
                    {/* Right arrow */}
                    <Hidden smDown>
                        <div className={classes.arrowContainer} style={{ justifyContent: "flex-end" }}>
                            <IconButton id="rightArrow" className={`${classes.arrowStyle}`} aria-label="add to favorites" onClick={(event) => this.handleCardDetails(event)}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </div>
                    </Hidden>
                    <Share
                        url={this.state.url}
                        open={this.state.open}
                        title={this.state.title}
                        handleClose={() => this.setState({ open: false })}
                    />
                </>}
            </SnackBarContext.Consumer >
        )
    }
}
ListCard.contextType = AuthContext
export default withApollo(withStyles((styles))(ListCard));