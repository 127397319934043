import React from 'react';
import {
    Grid, Avatar, Menu, MenuItem,
    Grow, IconButton, ListItemIcon, Typography
} from '@material-ui/core';
import Tooltip from '../Tooltip'
import { withStyles } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { AuthContext, ModalContext } from './../../contexts';
import { deepPurple } from '@material-ui/core/colors';
import { ProfileIcon, SignoutIcon } from '../../icons';


const styles = (theme) => ({
    paper: {
        color: '#fff'
    },
    purple: {
        fontWeight: 'bolder',
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    profileButton: {
        background: "#E0E3ED",
        padding: 12,
        "&:hover": {
            background: "#E0E3ED"
        }
    },
    profileIcon: {
        fill: theme.palette.common.white,
        width: 32,
        height: 32
    },
    menuListContainer: {
        "& .MuiListItemIcon-root": {
            minWidth: 35,
        }
    },
    signOutIcon: {
        fill: theme.palette.primary.main,
        width: 35,
        height: 35,
        [theme.breakpoints.only("sm")]: {
            width: 40,
            height: 40,
        }
    },
    signOutText: {
        color: theme.palette.primary.main,
        [theme.breakpoints.only("sm")]: {
            fontSize: 16
        }
    }
})


class ProfileAvatar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    }

    handleClick = (event, props) => {
        if (!this.context.user?.email) {
            props.setModal({
                open: true
            })
        } else {
            this.setState({ anchorEl: event.currentTarget });
        }
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { classes } = this.props;
        var { email, user_profiles } = this.context.user
        var name, profile_pic
        if (user_profiles && user_profiles.length > 0) {
            name = [user_profiles[0]?.first_name, user_profiles[0]?.last_name].join(' ')
            profile_pic = user_profiles[0]?.profile_pic
        }
        return (
            <ModalContext.Consumer>
                {Login =>
                    <>
                        <Grid
                            aria-controls="simple-menu"
                            aria-haspopup="true" onClick={(event) => this.handleClick(event, Login)}
                            style={{ cursor: 'pointer' }}>
                            <Tooltip
                                title={(user_profiles && user_profiles[0]?.first_name) ? name : email ? email : 'Anonyomous'}>
                                {profile_pic ? <Avatar alt={name} src={profile_pic} /> :
                                    <Avatar
                                        className={email ? classes.purple : null}>
                                        {email ? email[0].toUpperCase() :
                                            <IconButton className={classes.profileButton}>
                                                <ProfileIcon className={classes.profileIcon} />
                                            </IconButton>
                                        }
                                    </Avatar>}
                            </Tooltip>
                        </Grid>
                        <Menu
                            className={classes.paper}
                            id="simple-menu"
                            elevation={2}
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            PaperProps={{
                                style: {
                                    marginTop: "5px",
                                    background: "#fff",
                                }
                            }}
                            TransitionComponent={Grow}
                        >
                            <MenuItem onClick={() => { this.props.history.push('/logout') }}
                                className={classes.menuListContainer}>
                                <ListItemIcon>
                                    <SignoutIcon className={classes.signOutIcon} />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap className={classes.signOutText}>
                                    Sign Out
                        </Typography>
                            </MenuItem>
                        </Menu>
                    </>
                }
            </ModalContext.Consumer>
        )
    }
}
ProfileAvatar.contextType = AuthContext;
export default withRouter(withStyles((styles))(ProfileAvatar));