import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="star_2_" transform="translate(0.001 57.879)">
            <g id="Group_62" transform="translate(-0.001 9.527)">
                <path id="Path_96" d="M41.7-46.6c-0.5-1.5-1.9-2.6-3.5-2.6h-6.9c-0.4,0-0.8-0.3-1-0.7l-2.1-6.6c-0.6-2-2.7-3-4.7-2.4
           c-1.1,0.4-2,1.3-2.4,2.4L19-49.8c-0.1,0.4-0.5,0.7-1,0.7h-6.9c-2.1,0-3.7,1.7-3.7,3.8c0,1.2,0.6,2.3,1.5,3l5.6,4.1
           c0.4,0.3,0.5,0.7,0.4,1.1l-2.1,6.6c-0.5,1.5,0,3.2,1.3,4.2c1.3,1,3.1,1,4.4,0l5.6-4.1c0.4-0.3,0.8-0.3,1.2,0l5.6,4.1
           c1.7,1.2,4,0.8,5.2-0.8c0.7-1,0.9-2.2,0.5-3.3l-1.5-4.5c-0.2-0.7-1-1.1-1.7-0.9c-0.7,0.2-1.1,1-0.9,1.7c0,0,0,0,0,0l1.5,4.5
           c0.2,0.5-0.1,1.1-0.7,1.3c-0.3,0.1-0.7,0-0.9-0.1l-5.6-4c-1.3-0.9-3.1-0.9-4.4,0l-5.6,4c-0.5,0.3-1.1,0.2-1.4-0.2
           c-0.2-0.3-0.2-0.6-0.1-0.9l2.1-6.6c0.5-1.5,0-3.2-1.4-4.2l-5.6-4.1c-0.4-0.3-0.5-0.7-0.4-1.2c0.1-0.4,0.5-0.7,1-0.7H18
           c1.6,0,3-1,3.5-2.5l2.1-6.6c0.2-0.5,0.7-0.8,1.3-0.7c0.3,0.1,0.6,0.3,0.7,0.7l2.1,6.6c0.5,1.5,1.9,2.5,3.5,2.5h6.9
           c0.4,0,0.8,0.3,1,0.7c0.2,0.4,0,0.9-0.4,1.2L35-41.8c-0.6,0.4-0.7,1.3-0.3,1.9c0.4,0.6,1.3,0.7,1.8,0.3l3.8-2.8
           C41.7-43.3,42.3-45,41.7-46.6z"/>
            </g>
        </g>
    </svg>
);
export default SVG;
