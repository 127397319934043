import React from "react";
import { withApollo } from "react-apollo";
import { TextField, Hidden, Typography, Button, Grid, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_ALL_CATEGORIES } from "../../graphql/query";
import { MainCards } from '..';
import config from '../../config';
import UploadIcon from "../../icons/upload";

let name;
let img;

const styles = (theme) => ({
    title: {
        fontSize: "1.2rem",
        fontWeight: "600",
        textAlign: "center"
    },
    divider: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "grey",
        margin: "10px 0",
        '&::before': {
            content: "''",
            display: "block",
            background: "rgba(0, 0, 0, 0.2)",
            width: "50%",
            height: 1,
            marginRight: 10
        },
        '&::after': {
            content: "''",
            display: "block",
            background: "rgba(0, 0, 0, 0.2)",
            width: "50%",
            height: 1,
            marginLeft: 10
        }
    },
    categoryPreview: {
        border: "2px dashed #B9BEC9",
        borderRadius: 8,
        padding: "10px 20px",
        background: "#F9FBFE",
        [theme.breakpoints.down("sm")]: {
            background: theme.palette.background.paper,
        }
    },
    uploadButton: {
        borderRadius: 8,
        textTransform: "capitalize"
    },
    uploadImage: {
        width: 100,
        height: 100,
        fill: "#DBDFEA",
    },
    previewCard: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        wordBreak: "break-word"
    },
    previewImage: {
        width: 200,
        padding: "10px 0",
        [theme.breakpoints.only("xs")]: {
            width: 160
        }
    },
    inputStyle: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 8
        }
    }
});



class MasterCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    async componentDidMount() {
        await this.props.client.query({
            query: GET_ALL_CATEGORIES(''),
            fetchPolicy: "network-only",
        }).then((res) => {
            if (res.data.allCategories?.nodes)
                this.props.handleCategory({ ...this.props.category, list: res.data.allCategories.nodes })
        }).catch((err) => {
            console.log(err);
        });
    }

    previewRender = () => {
        var { listvalue, new_value, image_url } = this.props.category
        if (image_url !== null && new_value !== '') {
            name = new_value
            img = URL.createObjectURL(image_url)
            return true;
        }
        if (listvalue !== null) {
            name = listvalue.name
            img = config.url + '/' + listvalue.imageUrl
            return true
        }
        return false;
    }

    render() {
        var { list, listvalue, new_value, image_url } = this.props.category
        const loading = this.state.open && list.length < 0
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={4}>
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.title}>Select Categories</Typography>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={7} className={classes.inputStyle}>
                        <Autocomplete
                            id="category" style={{ marginBottom: '10px' }}
                            fullWidth
                            open={this.state.open}
                            onOpen={() => {
                                this.setState({ open: true });
                            }}
                            onClose={() => {
                                this.setState({ open: false });
                            }}
                            value={listvalue}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            options={list}
                            noOptionsText='No Options'
                            loading={loading}
                            onChange={(event, newValue) => {
                                this.props.handleCategory({ ...this.props.category, listvalue: newValue, new_value: '' })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Category"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        {/* Divider */}
                        <div className={classes.divider}>Or</div>
                        <TextField
                            id="CategoryName"
                            label="Create Category"
                            placeholder="Enter title..."
                            value={new_value}
                            onChange={(event) =>
                                this.props.handleCategory({ ...this.props.category, new_value: event.target.value, listvalue: null })
                            }
                            fullWidth
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <div className={classes.categoryPreview}>
                            <Typography variant="body2" color="primary">Preview Category</Typography>
                            <div className={classes.previewCard}>
                                {
                                    this.previewRender() ?
                                        <div className={classes.previewImage}>
                                            <MainCards
                                                preview={true}                                             
                                                data={{                                                  
                                                    name: name,
                                                    imageUrl: img
                                                }} />
                                        </div>
                                        :
                                        <UploadIcon className={classes.uploadImage} />
                                }
                            </div>
                            <label htmlFor="upload-photo">
                                <input
                                    style={{ display: 'none' }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    type="file"
                                    onChange={(event) => {
                                        const files = event.target.files;
                                        if (files) {
                                            this.props.handleCategory({ ...this.props.category, image_url: files[0] ? files[0] : null })
                                        }
                                    }}
                                />
                                {new_value &&
                                    <Button color="primary" fullWidth variant="contained" component="span"
                                        className={classes.uploadButton} disableElevation>
                                        {this.previewRender() && new_value && image_url ? "Change Image" : "Upload Image"}
                                    </Button>}
                            </label>
                            {
                                this.previewRender() && new_value && image_url ?
                                    (<Button color="primary" fullWidth variant="text" component="span"
                                        className={classes.uploadButton} disableElevation
                                        onClick={() => this.props.handleCategory({ ...this.props.category, image_url: null })
                                        }>
                                        Remove Image
                                    </Button>) : ""
                            }
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles((styles))(withApollo(MasterCategory));