import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { ListCard } from "../../components";
import { withApollo, Query } from "react-apollo";
import { GET_ALL_FLASH_CARDS } from "../../graphql/query";
import { AuthContext } from "../../contexts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { findIndex } from "lodash";

const styles = (theme) => ({
  componentRender: {
    paddingLeft: 166,
    paddingRight: 166,
    paddingTop: 8,
    overflowY: "scroll",
    overflowX: "hidden",
    height: "calc(100vh - 111px)",
    /* width  */
    "&::-webkit-scrollbar": {
      width: 0,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#3D4690",
      borderRadius: 15,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#303f9f",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "0 10px",
      borderRadius: 0,
      background: theme.palette.primary.main,
      height: "calc(100vh - 126px)",
      marginTop: -80,
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0 10px",
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 18,
    },
  },
});

function GET_CARD_DETAILS_ID(props) {
  let Auth = React.useContext(AuthContext);
  var user_id = null;
  if (Auth.user?.user_profiles) {
    user_id = Auth.user?.user_profiles[0].id;
  }
  const { topic } = props.match.params;
  return (
    <Query
      query={GET_ALL_FLASH_CARDS}
      variables={{
        topic_title: topic,
        user_id: user_id,
      }}
      fetchPolicy={"network-only"}
      notifyOnNetworkStatusChange={true}
    >
      {({ data, loading, error, refetch, networkStatus }) => {
        if (loading || networkStatus === 4)
          return (
            <Grid
              item
              container
              xs={12}
              justify="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Grid>
          );
        if (error)
          return (
            <Grid
              item
              container
              xs={12}
              justify="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Typography variant="h5">
                Some error occurred, please try refresh!
              </Typography>
            </Grid>
          );
        if (data) {
          let cards = data.allCards.nodes;
          let index = findIndex(cards, { id: props.location.state?.card_id });
          return (
            <ListCard
              data={data.allCards.nodes}
              card_index={index < 0 ? 0 : index}
              refetch={refetch}
            />
          );
        }
      }}
    </Query>
  );
}

class FlashCard extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        direction="row"
        justify="center"
        alignItems="center"
        container
        className={classes.componentRender}
      >
        <GET_CARD_DETAILS_ID
          location={{ ...this.props.location }}
          match={{ ...this.props.match }}
        />
      </Grid>
    );
  }
}
export default withRouter(withStyles(styles)(withApollo(FlashCard)));
