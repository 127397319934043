/*global google*/
import React from 'react';
//import axios from 'axios';
import { AuthContext } from './contexts';
//import config from './config';
import { Refresh, Google_Verify } from './services';
import http from "./services/http-common";
import { withApollo } from '@apollo/react-hoc';


const AppAuth = (props) => {

    const [auth, setAuth] = React.useState({ user: {}, lastupdate: '' });
    const [
        state,
        setState
    ] = React.useState({ isRefreshed: true });

    // Todo: Your Referesh API here

    React.useEffect(() => {
        const fetchData = async () => {
            await Refresh({
                authorization: localStorage.getItem("auth_token"),
            }).then(async (response) => {
                let token = response.data.auth_token;
                localStorage.setItem("auth_token", token);
                setAuth({ user: response.data.user });
                setState({ isRefreshed: true });
            }).catch((err) => {
                localStorage.clear();
                delete http.auth.defaults.headers.common['authorization'];
                setState({ isRefreshed: true });
            })
        }
        var pathname = window.location.pathname
        if (localStorage.getItem("auth_token") === null && !pathname.includes('admin') && !pathname.includes('reset') && !pathname.includes('verify-mail')) {
            window.onload = function () {
                window.onGoogleLibraryLoad = () => {
                    google.accounts.id.initialize({
                        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                        callback: async (response) => {
                            var token = response.credential
                            await Google_Verify({
                                token: token
                            }).then(async (res) => {
                                let token = res.data.auth_token;
                                localStorage.setItem("auth_token", token);
                                setAuth({ user: res.data.user });
                                setState({ isRefreshed: true });
                            }).catch((err) => {
                                localStorage.clear();
                                delete http.auth.defaults.headers.common['authorization'];
                                setState({ isRefreshed: true });
                            })
                        }
                    });
                    google.accounts.id.prompt(notification => {
                        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                            //console.log(notification.getNotDisplayedReason())
                        }
                    });
                }
            };
        } else {
            fetchData();
        }
    }, [state.isRefreshed])

    return (
        <div>
            {
                state.isRefreshed ?
                    <AuthContext.Provider value={{ ...auth, setAuth }}>
                        {props.children}
                    </AuthContext.Provider>
                    : <div>Loading...</div>
            }
        </div>

    )
}

export default withApollo(AppAuth);
