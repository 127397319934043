import React from "react";
const SVG = ({ width, height, className }) => (
    <svg 
    width={width}
    height={height}
    className={`svg-icon ${className || ""}`}
    version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 24.446 22.329">
        
        <g transform="translate(0 -22.164)"><g transform="translate(0 22.164)"><path d="M17.927,22.164a7.674,7.674,0,0,0-5.7,2.6,7.674,7.674,0,0,0-5.7-2.6C2.683,22.164,0,25.708,0,29.879,0,32.929,1.538,38.87,11.838,44.4a.815.815,0,0,0,.771,0c10.3-5.526,11.838-11.468,11.838-14.518C24.446,25.8,21.84,22.164,17.927,22.164Z" transform="translate(0 -22.164)"/></g></g>
    </svg>
);
export default SVG;
