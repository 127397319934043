import React from "react";
import {
  Grid,
  AppBar,
  Typography,
  Hidden,
  Breadcrumbs,
  makeStyles,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withApollo } from "react-apollo";
import { withRouter, Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ProfileAvatar from "../profileAvatar";
import { SUBTITLEBARQUERY } from "../../graphql/query";

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "100%",
    height: 95,
    background: theme.palette.background.paper,
    color: theme.palette.common.black,

    paddingLeft: 66,
    paddingRight: 66,
    paddingTop: 42,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.0), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 2px 1px -1px rgba(0, 0, 0, 0)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 2,
    },
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      height: 150,
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: 50,
      paddingRight: 60,
      height: 150,
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.only("md")]: {
      height: 73,
      paddingTop: 22,
    },
    [theme.breakpoints.up("lg")]: {
      height: 96,
      paddingTop: 27,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 166,
      paddingRight: 166,
      paddingTop: 34,
    },
  },
  header: {
    display: "flex",
    paddingLeft: 12,
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
      display: "contents",
    },
  },
  headerChild: {
    whiteSpace: "nowrap",
    paddingLeft: "11px",
    paddingTop: "10px",
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "13px",
      paddingTop: "10px",
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: "13px",
      paddingTop: "10px",
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "8px",
      paddingTop: "42px",
    },
    [theme.breakpoints.only("xs")]: {
      paddingTop: "42px",
    },
  },
  arrowIcon: {
    paddingLeft: "18px",
    paddingTop: "51px",
    fill: "#FFD447FC",
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "45px",
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "0px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: "40px",
    },
  },
  TextName: {
    lineHeight: 1,
    fontWeight: "bold",
    display: "flex",
    textTransform: "capitalize",
    color: theme.palette.common.black,
    [theme.breakpoints.only("xs")]: {
      fontWeight: "bold",
      paddingBottom: 6,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.only("sm")]: {
      fontWeight: "bold",
      paddingBottom: 6,
      color: theme.palette.common.white,
    },
  },
  searchChild: {
    paddingRight: "22px",
    paddingTop: "2px",
    [theme.breakpoints.only("xs")]: {
      // paddingRight: "18px",
      paddingLeft: "18px",
      paddingTop: "5px",
    },
    [theme.breakpoints.only("md")]: {
      paddingRight: "0px",
    },
    [theme.breakpoints.only("lg")]: {
      paddingRight: "8px",
    },
  },
  search: {
    position: "relative",
    borderRadius: "25px",
    height: "38px",
    display: "flex",
    float: "right",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      //backgroundColor: theme.palette.common.white,
      //boxShadow: '0 5px 15px rgba(61, 70, 144, .4)',
      backgroundColor: "#00000008",
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.only("lg")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
      marginRight: 27,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
      marginRight: 27,
    },
    [theme.breakpoints.only("sm")]: {
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.only("xs")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.hint,
  },
  inputRoot: {
    color: theme.palette.text.hint,
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: theme.palette.common.black,
    paddingTop: 10,
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
  },
  subTitle: {
    font: "normal normal normal 14px Proxima Nova",
    color: "#000000",
    whiteSpace: "nowrap",
  },
  avatar: {
    marginTop: 41,
    position: "relative",
    [theme.breakpoints.between("md", "lg")]: {
      marginTop: 0,
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: 33,
    },
  },
  breadCrumbs: {
    marginTop: 5,
    "& a": {
      fontSize: 14,
      fontFamily: "Proxima Nova",
      fontWeight: "bold",
      color: "#000000",
      textDecoration: "none",
      opacity: 0.6,
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
    },
    "& .MuiBreadcrumbs-separator": {
      margin: 0,
    },
  },
  activeLink: {
    fontSize: 14,
    fontFamily: "Proxima Nova",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function SubTopbar(props) {
  const classes = useStyles();
  let { location, match } = props;
  const previousLocation = usePrevious(props.location);

  React.useEffect(() => {
    if (previousLocation !== location) {
      props.handleSearch("");
    } // eslint-disable-next-line
  }, [previousLocation, location]);

  const [state, setState] = React.useState({
    category: {},
    subcategory: {},
    topic: {},
  });

  let setTitle = () => {
    let { category, subcategory, topic } = match.params;
    console.log(match);
    props.client
      .query({
        query: SUBTITLEBARQUERY,
        variables: {
          category,
          subcategory: subcategory ? subcategory : null,
          topic: topic ? topic : null,
        },
      })
      .then(({ data }) => {
        let { category, subcategory, topic } = data;
        if (match.params.category) {
          setState({ ...state, category: category.nodes[0] });
        }
        if (match.params.subcategory) {
          setState({
            ...state,
            category: category.nodes[0],
            subcategory: subcategory.nodes[0],
          });
        }
        if (match.params.topic) {
          setState({
            ...state,
            category: category.nodes[0],
            subcategory: subcategory.nodes[0],
            topic: topic.nodes[0],
          });
        }
      });
  };

  React.useEffect(setTitle, [match]);

  const query = new URLSearchParams(props.location.search);
  const isSubOnly = query.get("subsonly");
  let { category, subcategory, topic } = state;
  console.log(state);
  return (
    <AppBar position="static" className={classes.appBar}>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Hidden mdUp>
          <Grid item onClick={() => window.history.back()}>
            <ArrowBackIosIcon className={classes.arrowIcon} />
          </Grid>
        </Hidden>

        <Grid
          item
          direction="row"
          justify="space-between"
          //alignItems="center"
          container
          className={classes.header}
        >
          <Grid
            item
            md={!topic?.title ? 6 : 11}
            className={classes.headerChild}
          >
            <Typography variant="h5" className={classes.TextName}>
              {!!isSubOnly
                ? "Sub Categories"
                : subcategory?.name
                ? subcategory?.name
                : category?.name}
            </Typography>
            <Hidden smDown>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                className={classes.breadCrumbs}
              >
                <Link to="/">Categories</Link>
                {subcategory?.name || topic?.title ? (
                  <Link to={`/${category?.urlParam}`}>{category?.name}</Link>
                ) : (
                  <Typography className={classes.activeLink}>
                    {category?.name}
                  </Typography>
                )}
                {subcategory?.name && topic?.title ? (
                  <Link
                    to={`/${category?.urlParam}/subcategory/${subcategory?.urlParam}`}
                  >
                    {subcategory?.name}
                  </Link>
                ) : (
                  subcategory && (
                    <Typography className={classes.activeLink}>
                      {subcategory?.name}
                    </Typography>
                  )
                )}
                {topic?.title && (
                  <Typography className={classes.activeLink}>
                    {topic?.title}
                  </Typography>
                )}
              </Breadcrumbs>
            </Hidden>
          </Grid>
          <Hidden smDown>
            {!topic?.title && (
              <Grid md={5} item className={classes.searchChild}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder={
                      !!isSubOnly
                        ? "Search sub-categories"
                        : !!subcategory?.name
                        ? "Search topics"
                        : "Search sub-categories / topics"
                    }
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    type="search"
                    inputProps={{ "aria-label": "search" }}
                    value={props.search}
                    onChange={(event) => props.handleSearch(event.target.value)}
                  />
                </div>
              </Grid>
            )}
          </Hidden>
          <Hidden smDown>
            <Grid md={1} item container justify="flex-end">
              <ProfileAvatar />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default withRouter(withApollo(SubTopbar));
