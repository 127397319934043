import React from "react";
import { Grid, Typography, Button, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { withApollo, Query } from "react-apollo";
import {
  Sub_Categories_by_Category_Name,
  Topics_by_Category_Name,
} from "../../graphql/query";
import { withRouter } from "react-router-dom";
import SubCategoryCard from "./../../components/SubCategoryCard";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SubCards from "./../../components/subcard/index";
import BottomNavbar from "../../components/bottomNavbar";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import "../loading.css";

const styles = (theme) => ({
  componentRender: {
    paddingLeft: 66,
    paddingRight: 66,
    paddingTop: 8,
    overflowY: "scroll",
    overflowX: "hidden",
    height: "calc(100vh - 111px)",
    /* width  */
    "&::-webkit-scrollbar": {
      width: 0,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#3D4690",
      borderRadius: 15,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#303f9f",
    },
    [theme.breakpoints.down("sm")]: {
      /* width  */
      "&::-webkit-scrollbar": {
        width: 0,
      },
      borderTopLeftRadius: "38px",
      borderBottomLeftRadius: 0,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      background: theme.palette.background.paper,
      borderTopLeftRadius: "38px",
      borderTopRightRadius: "38px",
      height: "calc(100vh - 167px)",
      marginTop: -40,
    },
    [theme.breakpoints.only("md")]: {
      paddingTop: 18,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 166,
      paddingRight: 166,
    },
  },
  root: {
    padding: 0,
    [theme.breakpoints.only("xs")]: {
      padding: "15px 15px 77px 15px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "20px",
    },
  },
  subtitle: {
    width: "100%",
    fontWeight: "bold",
    float: "left",
    fontSize: "18px",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
  },
  titleContainer: {
    padding: "10px",
  },
  button: {
    float: "right",
    fontSize: "13px",
    fontWeight: "bold",
    textTransform: "capitalize",
    "& .MuiButton-iconSizeSmall > *:first-child": {
      fontSize: "12px",
    },
  },
  subCategoriesContainer: {
    overflowX: "scroll",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  innerContainer: {
    padding: "5px 10px 15px 10px",
  },
  subCategoryOnly: {
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 10px",
  },
  search: {
    position: "relative",
    borderRadius: "25px",
    height: "38px",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.hint,
  },
  inputRoot: {
    color: theme.palette.text.hint,
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: theme.palette.common.black,
    paddingTop: 10,
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
  },
});

class SubCategories extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.handleSearch("");
    }
  }

  render() {
    let { category } = this.props.match.params;
    let query = new URLSearchParams(this.props.location.search);
    const isSubOnly = query.get("subsonly");

    const { classes } = this.props;
    return (
      <div className={classes.componentRender}>
        <>
          {/* Search */}
          <Hidden mdUp>
            <Grid
              container
              item
              xs={12}
              style={{ padding: "25px 20px 0px 20px" }}
            >
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  fullWidth
                  placeholder="Search(Design,Technology,etc)"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={this.props.search}
                  type="search"
                  onChange={(event) =>
                    this.props.handleSearch(event.target.value)
                  }
                />
              </div>
            </Grid>
          </Hidden>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.root}
          >
            <Query
              query={Sub_Categories_by_Category_Name}
              variables={{ category, search: this.props.search }}
            >
              {({ data, loading, error }) => {
                return (
                  <>
                    {/* Sub category Title */}
                    {(loading ||
                      data.subcategories.nodes.length > 0 ||
                      this.props.search !== "") && (
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        className={classes.titleContainer}
                      >
                        <Grid item xs={6}>
                          <Typography variant="h6" className={classes.subtitle}>
                            Sub Categories
                          </Typography>
                        </Grid>
                        {loading ? (
                          <Grid
                            item
                            container
                            xs={12}
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            <div className="lds-ellipsis">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </Grid>
                        ) : error ? (
                          <Grid
                            item
                            container
                            xs={12}
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            <Typography variant="h5">
                              Some error occurred, please try refresh!
                            </Typography>
                          </Grid>
                        ) : data &&
                          data.subcategories.nodes.length === 0 &&
                          this.props.search !== "" ? (
                          <Grid
                            item
                            container
                            xs={12}
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            <Typography variant="h5">
                              No such sub category found!
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item xs={6}>
                            {!isSubOnly && (
                              <Button
                                size="small"
                                variant="text"
                                color="primary"
                                className={classes.button}
                                endIcon={<ArrowForwardIosIcon />}
                                disableElevation
                                disableFocusRipple
                                onClick={() =>
                                  this.props.history.push(
                                    `${this.props.location.pathname}?subsonly=true`
                                  )
                                }
                              >
                                View All
                              </Button>
                            )}
                            {isSubOnly && (
                              <Button
                                size="small"
                                variant="text"
                                color="primary"
                                className={classes.button}
                                endIcon={<ArrowForwardIosIcon />}
                                disableElevation
                                disableFocusRipple
                                onClick={() => this.props.history.goBack()}
                              >
                                View less
                              </Button>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {/* Sub Categories Cards */}
                    {data && data.subcategories.nodes.length > 0 && (
                      <Grid
                        container
                        direction="row"
                        wrap={!isSubOnly ? "nowrap" : "wrap"}
                        className={
                          !isSubOnly
                            ? classes.subCategoriesContainer
                            : classes.subCategoryOnly
                        }
                        spacing={!isSubOnly ? 0 : 4}
                      >
                        {data.subcategories.nodes.map((card) =>
                          !isSubOnly ? (
                            <div
                              key={card.id}
                              className={classes.innerContainer}
                            >
                              <SubCategoryCard
                                key={card.id}
                                card={card}
                                preview={this.props.preview}
                                editable={this.props.editable}
                                isSubOnly={!!isSubOnly}
                              />
                            </div>
                          ) : (
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              md={3}
                              lg={3}
                              key={card.id}
                            >
                              <SubCategoryCard
                                key={card.id}
                                card={card}
                                preview={this.props.preview}
                                editable={this.props.editable}
                                isSubOnly={isSubOnly}
                              />
                            </Grid>
                          )
                        )}
                      </Grid>
                    )}
                  </>
                );
              }}
            </Query>
            {!isSubOnly && (
              <Query
                query={Topics_by_Category_Name}
                variables={{
                  category,
                  search: this.props.search,
                }}
              >
                {({ data, loading, error }) => {
                  return (
                    <>
                      {/* Topics */}
                      {(loading ||
                        data.topics.nodes.length > 0 ||
                        this.props.search !== "") && (
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            className={`${classes.subtitle} ${classes.titleContainer}`}
                          >
                            Topics
                          </Typography>
                        </Grid>
                      )}
                      {loading && (
                        <Grid
                          item
                          container
                          xs={12}
                          justify="center"
                          alignItems="center"
                          style={{ height: "100%" }}
                        >
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </Grid>
                      )}
                      {error && (
                        <Grid
                          item
                          container
                          xs={12}
                          justify="center"
                          alignItems="center"
                          style={{ height: "100%" }}
                        >
                          <Typography variant="h5">
                            Some error occurred, please try refresh!
                          </Typography>
                        </Grid>
                      )}
                      {data &&
                        data.topics.nodes.length === 0 &&
                        this.props.search !== "" && (
                          <Grid
                            item
                            container
                            xs={12}
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            <Typography variant="h5">
                              No such topics found!
                            </Typography>
                          </Grid>
                        )}
                      {/* Topics List card */}
                      {data && data.topics.nodes.length > 0 && (
                        <Grid
                          item
                          container
                          spacing={4}
                          style={{ padding: "0 10px" }}
                        >
                          {data.topics.nodes.map((card) => (
                            <Grid
                              item
                              key={card.id}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                            >
                              <SubCards
                                key={card.id}
                                card={card}
                                preview={this.props.preview}
                                editable={this.props.editable}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </>
                  );
                }}
              </Query>
            )}
          </Grid>
        </>
        <Hidden mdUp>
          <BottomNavbar />
        </Hidden>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(withApollo(SubCategories)));
