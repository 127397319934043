import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
        <g id="menu-2" transform="translate(-2 -5)">
            <line id="Line_102" data-name="Line 102" x2="18" transform="translate(3 12)" fill="none" stroke="#474f95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <line id="Line_103" data-name="Line 103" x2="11" transform="translate(3 6)" fill="none" stroke="#474f95" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path id="Path_66" data-name="Path 66" d="M0,0H9.385" transform="translate(3 18)" fill="none" stroke="#474f95" strokeLinecap="round" strokeWidth="2" />
        </g>
    </svg>
);
export default SVG;
