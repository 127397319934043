import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import { IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "100%",
        flexGrow: 1,
        "& .MuiIconButton-root.Mui-disabled": {
            background: "rgba(0, 0, 0, 0.16)",
            color: "#fff"
        }
    },
    iconButtonFrwd: {
        background: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            background: theme.palette.primary.main,
            color: "#fff",
        }
    },
    iconButtonBck: {
        background: "rgba(0, 0, 0, 0.26)",
        color: "#fff",
        "&:hover": {
            background: "rgba(0, 0, 0, 0.26)",
            color: "#fff",
        }
    }
}));

export default function DotsMobileStepper(props) {
    const {steps, activeStep, handleBack, handleNext} = props;
    const classes = useStyles();
    const maxSteps = steps.length;

    return (
        <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={
                <IconButton size="medium" className={classes.iconButtonFrwd} onClick={handleNext}>
                    {activeStep === maxSteps - 1 ? <DoneIcon /> : <ArrowForwardIcon />}
                </IconButton>
            }
            backButton={
                <IconButton size="medium" className={classes.iconButtonBck} onClick={handleBack} disabled={activeStep === 0}>
                    <ArrowBackIcon />
                </IconButton>
            }
        />
    );
}