import React from 'react';
import { ModalContext } from './contexts'
import { withApollo } from '@apollo/react-hoc';

const AppLogin = (props) => {
    const [Modal, setModal] = React.useState({
        open: false,
        cb: null
    });

    const handleClose = () => {
        setModal({ ...Modal, open: false });
        if (Modal.cb) { Modal.cb() }
    };

    return (
        <>
            <ModalContext.Provider value={{ ...Modal, setModal, handleClose }}>
                {props.children}
            </ModalContext.Provider>
        </>
    )
}

export default withApollo(AppLogin);