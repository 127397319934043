import http from "./http-common";

export const uploadPhoto = (data) => {
    return http.base.post('/photo/add', data)
}

export const removePhoto = (data) => {
    return http.base.get('/photo/remove?imageUrl=' + data)
}

export const Login = (data) => {
    return http.auth.post('/login', data)
}

export const Refresh = (data) => {
    http.auth.defaults.headers.common['authorization'] = data.authorization
    return http.auth.get('/refresh')
}

export const CheckEmail = (data) => {
    return http.auth.post('/checkemail', data)
}

export const SignUp = (data) => {
    return http.auth.post('/signup', data)
}

export const ForgotPassword = (data) => {
    return http.auth.post('/forgot_password', data)
}

export const ResetPassword = data => {
    return http.auth.post('/reset_password', data)
}

export const Google_Verify = data => {
    return http.auth.post('/verify', data)
}

export const VerifyEmail = data => {
    return http.auth.post('/verify_email', data)
}

export const UserList = data => {
    return http.admin.post('/userlist', data)
}

export const ContentCount = () => {
    return http.admin.get('/contentcount')
}
