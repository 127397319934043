import React, { Component } from 'react';
import { withStyles, Grid, Hidden, Typography, IconButton } from '@material-ui/core';
import SubCategoryIcon from "../../icons/subcategory";
import TopicsIcon from "../../icons/topics";

const style = (theme) => ({
    title: {
        fontSize: "1.2rem",
        fontWeight: "600",
        textAlign: "center"
    },
    cardContent: {
        borderRadius: "20px",
        backgroundColor: theme.palette.common.white,
        padding: 18,
        height: 170,
        margin: "20px 0",
        border: "2px solid #DCDCDC",
        "&.active": {
            borderColor: theme.palette.primary.main,
            boxShadow: '0 5px 15px rgba(61, 70, 144, .4)',
        },
        "&:hover": {
            cursor: "pointer",
            boxShadow: '0 5px 15px rgba(61, 70, 144, .4)',
            // backgroundColor: "#00000008"
        },
        [theme.breakpoints.down("sm")]: {
            height: 180,
        },
    },
    textpad: {
        paddingTop: 5,
        textAlign: "center",
    },
    fullWidth2: {
        width: "100%",
        fontWeight: "bold",
        fontSize: "14px",
        [theme.breakpoints.only("xs")]: {
            textAlign: "center",
            fontSize: "14px"
        }
    },
    iconButton: {
        "&.MuiIconButton-root": {
            backgroundColor: "#EEF3F9",
        },
        "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
            fontSize: "3.5rem"
        }
    },
    uploadImage: {
        fill: theme.palette.primary.main,
        width: 80,
        height: 80,
        [theme.breakpoints.only("xs")]: {
            width: 60,
            height: 60
        }
    },
})

class ChooseOption extends Component {
    render() {
        const { selectedOption, handleSelectedOption } = this.props;
        const { classes } = this.props;
        return (
            <Grid container spacing={4}
                direction="row"
                justify="center"
                alignItems="center">
                <Hidden mdUp>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.title}>Choose One</Typography>
                    </Grid>
                </Hidden>
                <Grid item lg={4} sm={6} md={4} xs={6}>
                    <Grid
                        container
                        alignItems="center"
                        className={`${classes.cardContent} ${selectedOption === 'subcategory' && 'active'}`}
                        onClick={() => handleSelectedOption("subcategory")}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <IconButton size="medium" className={classes.iconButton}>
                                <SubCategoryIcon className={classes.uploadImage} />
                            </IconButton>
                        </Grid>
                        <Grid item className={classes.textpad} xs={12} >
                            <Typography className={classes.fullWidth2} variant="h6" >
                                Create Sub Category
                        </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={4} sm={6} md={4} xs={6}>
                    <Grid
                        container
                        alignItems="center"
                        className={`${classes.cardContent} ${selectedOption === 'topic' && 'active'}`}
                        onClick={() => handleSelectedOption("topic")}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <IconButton size="medium" className={classes.iconButton}>
                                <TopicsIcon className={classes.uploadImage} />
                            </IconButton>
                        </Grid>
                        <Grid item className={classes.textpad} xs={12} >
                            <Typography className={classes.fullWidth2} variant="h6" >
                                Create Topic
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(ChooseOption);