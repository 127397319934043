import React from "react";
import { withApollo } from "react-apollo";
import {
  TextField,
  Hidden,
  Typography,
  Button,
  Grid,
  withStyles,
} from "@material-ui/core";
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { SubCards } from "..";
import UploadIcon from "../../icons/upload";

let name;
let img;

const styles = (theme) => ({
  title: {
    fontSize: "1.2rem",
    fontWeight: "600",
    textAlign: "center",
  },
  titlePreview: {
    border: "2px dashed #B9BEC9",
    borderRadius: 8,
    padding: "10px 20px",
    background: "#F9FBFE",
    [theme.breakpoints.down("sm")]: {
      background: theme.palette.background.paper,
    },
  },
  previewCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    wordWrap: "break-word",
  },
  previewImage: {
    width: "100%",
    padding: "15px 0",
  },
  uploadButton: {
    borderRadius: 8,
    textTransform: "capitalize",
  },
  uploadImage: {
    width: 100,
    height: 100,
    fill: "#DBDFEA",
  },
  inputStyle: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
});

class CreateTopic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  /*async componentDidMount() {
        if (this.props.categoryId !== '') {
            await this.props.client.query({
                query: GET_ALL_CARD_DETAILS,
                variables: { Category_Name: this.props.categoryId, search: '' },
                fetchPolicy: "network-only",
            }).then((res) => {
                this.props.handleTitleList(res.data.allCardDetails.nodes)
            }).catch((err) => {
                console.log(err);
            });
        }
    }*/

  previewRender = () => {
    var { new_value, image_url } = this.props.topics;
    if (image_url !== null && new_value !== "") {
      name = new_value;
      img = URL.createObjectURL(image_url);
      return true;
    }
    return false;
  };

  render() {
    const { classes } = this.props;
    var { new_value, image_url } = this.props.topics;
    return (
      <>
        <Grid container spacing={4}>
          <Hidden mdUp>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.title}>
                Create Title
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={6} className={classes.inputStyle}>
            {/* {this.props.categoryId !== '' ? (
                            <>
                                <Autocomplete
                                    id="category" style={{ marginBottom: '10px' }}
                                    fullWidth
                                    open={this.state.open}
                                    onOpen={() => {
                                        this.setState({ open: true });
                                    }}
                                    onClose={() => {
                                        this.setState({ open: false });
                                    }}
                                    value={this.props.selectedTitle}
                                    getOptionSelected={(option, value) => option.title === value.title}
                                    getOptionLabel={(option) => option.title}
                                    options={this.props.TitleList}
                                    loading={loading}
                                    onChange={(event, newValue) => {
                                        this.props.handleSelectedTitle(newValue)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Title"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                <Divider />
                            </>) : (<></>)} */}

            {/* <Typography variant='body1' style={{ marginTop: '10px' }}>Create Title</Typography> */}
            <TextField
              id="topic-title"
              label="Create Title"
              value={new_value}
              fullWidth
              style={{ marginBottom: "10px" }}
              onChange={(event) =>
                this.props.handleTopics({
                  ...this.props.topics,
                  new_value: event.target.value,
                })
              }
              variant="outlined"
              placeholder="Title"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.titlePreview}>
              <Typography variant="body2" color="primary">
                Preview Title
              </Typography>
              <div className={classes.previewCard}>
                {this.previewRender() ? (
                  <div className={classes.previewImage}>
                    <SubCards
                      preview={true}
                      card={{
                        title: name,
                        path: img,
                        createdAt: new Date().toISOString(),
                      }}
                    />
                  </div>
                ) : (
                  <UploadIcon className={classes.uploadImage} />
                )}
              </div>
              <label htmlFor="upload-photo">
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={(event) => {
                    const files = event.target.files;
                    if (files) {
                      this.props.handleTopics({
                        ...this.props.topics,
                        image_url: files[0] ? files[0] : null,
                      });
                    }
                  }}
                />
                {new_value && (
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    component="span"
                    className={classes.uploadButton}
                    disableElevation
                  >
                    {this.previewRender() ? "Change Image" : "Upload Image"}
                  </Button>
                )}
              </label>
              {image_url && new_value && (
                <Button
                  color="primary"
                  fullWidth
                  variant="text"
                  component="span"
                  className={classes.uploadButton}
                  disableElevation
                  onClick={() =>
                    this.props.handleTopics({
                      ...this.props.topics,
                      image_url: null,
                    })
                  }
                >
                  Remove Image
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withApollo(CreateTopic));
