import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableContainer, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
// Components
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TablePagination from "./TablePagination";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
  },
  tableContainer: {
    maxHeight: 440,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    "&::-webkit-scrollbar": {
      height: 4,
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: 500,
    },
    [theme.breakpoints.only("xs")]: {
      maxHeight: 600,
    },
  },
  table: {
    backgroundColor: theme.palette.common.white,
  },
}));

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function DashboardTable(props) {
  const classes = useStyles();
  // Columns and rows
  const { count, columns } = props;
  // Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const previousPath = usePrevious(props.path);

  React.useEffect(() => {
    if (previousPath !== props.path) {
      setPage(0);
    }
  }, [previousPath, props.path]);

  const handleChangePage = (event, newPage) => {
    props.fetchRow(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          {/* Table Head */}
          <TableHead columns={columns} />
          {/* Table body */}
          <TableBody {...props} page={page} rowsPerPage={rowsPerPage} />
        </Table>
      </TableContainer>
      {/* Pagination */}
      {props.loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: '10px'
          }}
        >
          <Skeleton style={{ borderRadius: 10, width: "100%" }} />
        </div>
      ) : (
        <TablePagination
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
