import React from "react";
import { Grid } from "@material-ui/core";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { SnackBarContext } from "../../contexts";
import { Typography, Hidden } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { v4 as uuidv4 } from "uuid";
import {
  SelectCategories,
  MobileStepper,
  CreateTopic,
  CreateFlashCard,
  SelectSubCategories,
} from "../../components";
import { mutation, variables } from "../../graphql/mutations";
import { uploadPhoto } from "../../services";
import ChooseOption from "./../../components/ChooseOption/index";
const styles = (theme) => ({
  componentRender: {
    paddingLeft: 66,
    paddingRight: 66,
    paddingTop: 8,
    overflowY: "scroll",
    overflowX: "hidden",
    height: "calc(100vh - 111px)",
    /* width  */
    "&::-webkit-scrollbar": {
      width: 0,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#3D4690",
      borderRadius: 15,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#303f9f",
    },
    [theme.breakpoints.down("sm")]: {
      /* width  */
      "&::-webkit-scrollbar": {
        width: 0,
      },
      borderTopLeftRadius: "38px",
      borderBottomLeftRadius: 0,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      background: theme.palette.background.paper,
      borderTopLeftRadius: "38px",
      borderTopRightRadius: "38px",
      height: "calc(100vh - 167px)",
      marginTop: -40,
    },
    [theme.breakpoints.only("md")]: {
      paddingTop: 18,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 166,
      paddingRight: 166,
    },
  },
  root: {
    padding: 0,
    [theme.breakpoints.only("xs")]: {
      padding: "15px 15px 77px 15px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "20px",
    },
  },
  Stepper: {
    width: "100%",
    "& .MuiStepper-root": {
      padding: "0px 0px 10px 0px",
    },
  },
  button: {
    fontWeight: 300,
    marginRight: theme.spacing(1),
    fontSize: "14px",
    borderRadius: 8,
    textTransform: "Capitalize",
    padding: "8px 30px",
  },
  stepContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: "0 8%",
    "& .MuiInputBase-root": {
      background: "#fff",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0 8%",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0 2%",
    },
  },
  stepperActions: {
    paddingTop: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: "0 4%",
    [theme.breakpoints.only("sm")]: {
      padding: "0 6%",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0 0%",
    },
    [theme.breakpoints.up("md")]: {
      float: "right",
      paddingRight: "8%",
    },
  },
  stepperLabel: {
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      fontSize: 14,
      color: "#B0AFAF",
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 600,
    },
    "& .MuiStepIcon-root": {
      color: "#E0E3ED",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiStepConnector-alternativeLabel": {
      top: "15px",
    },
  },
});

function getSteps() {
  return [
    "Select Categories",
    "Choose One",
    "Select Sub Categories",
    "Create Topic",
    "Add Content",
  ];
}

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: { list: [], listvalue: null, new_value: "", image_url: null },
      sub_category: {
        list: [],
        listvalue: null,
        new_value: "",
        image_url: null,
      },
      topics: { new_value: "", image_url: null },
      //FlashCards props
      cardDetails: [{ title: "", content: "" }],
      //Stepper props
      activeStep: 0,
      selectedOption: "",
    };
  }

  handleNext = () => {
    let step;
    let { activeStep, selectedOption } = this.state;
    if (activeStep === 0) {
      step = 1;
    } else if (selectedOption.includes("sub") && activeStep < 2) {
      step = 2;
    } else if (selectedOption.includes("topic") && activeStep < 3) {
      step = 3;
    } else {
      step = activeStep + 1;
    }
    this.setState({ activeStep: step });
  };

  handleBack = () => {
    let step;
    let { activeStep, selectedOption } = this.state;
    if (activeStep === 4) {
      step = 3;
    } else if (selectedOption.includes("sub") && activeStep >= 2) {
      step = 1;
    } else if (selectedOption.includes("topic") && activeStep >= 3) {
      step = 1;
    } else {
      step = activeStep - 1;
    }
    this.setState({ activeStep: step });
  };

  handleReset = () => {
    this.setState({
      category: { list: [], listvalue: null, new_value: "", image_url: null },
      sub_category: {
        list: [],
        listvalue: null,
        new_value: "",
        image_url: null,
      },
      topics: { new_value: "", image_url: null },
      //FlashCards props
      cardDetails: [{ cardInfo: "" }],
      //Stepper props
      activeStep: 0,
      selectedOption: "",
    });
  };

  handleSelectedOption = (value) => {
    this.setState({ selectedOption: value });
  };

  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  state_validation = (state) => {
    let step = {
      category: 0,
      sub_category: 2,
      topics: 3,
    };
    let { listvalue, new_value, image_url } = this.state[state];
    let msg,
      activeStep,
      response = true;
    if (state !== "topics") {
      if (listvalue == null && new_value === "") {
        msg = `Select ${this.capitalize(state)} / Create new!`;
        activeStep = step[state];
        response = false;
      } else if (new_value !== "" && image_url == null) {
        msg = `Add ${this.capitalize(state)} image!`;
        activeStep = step[state];
        response = false;
      }
    } else {
      if (new_value === "") {
        msg = `${this.capitalize(state)} could not be empty!`;
        activeStep = step[state];
        response = false;
      } else if (new_value !== "" && image_url == null) {
        msg = `Add ${this.capitalize(state)} image!`;
        activeStep = step[state];
        response = false;
      }
    }
    return { msg: msg, activeStep: activeStep, response: response };
  };

  Validate = () => {
    let msg,
      activeStep,
      response = true;
    var category_validation = this.state_validation("category");
    if (!category_validation.response) {
      return category_validation;
    }

    if (this.state.selectedOption === "") {
      msg = "Please choose one!";
      activeStep = 1;
      response = false;
      return { msg: msg, activeStep: activeStep, response: response };
    }
    if (this.state.selectedOption.includes("sub")) {
      var sub_category_validation = this.state_validation("sub_category");
      if (!sub_category_validation.response) {
        return sub_category_validation;
      }
    }
    var topics_validation = this.state_validation("topics");
    if (!topics_validation.response) {
      return topics_validation;
    }

    this.state.cardDetails.forEach((element) => {
      if (element.title === "" || element.content === "") {
        msg = "Cards could not be empty!";
        activeStep = 4;
        response = false;
        return { msg: msg, activeStep: activeStep, response: response };
      }
    });
    return { msg: msg, activeStep: activeStep, response: response };
  };

  getStepContent = (step) => {
    step = getSteps()[step];
    switch (step) {
      case "Select Categories":
        return (
          <SelectCategories
            category={this.state.category}
            handleCategory={(value) => this.setState({ category: value })}
          />
        );
      case "Choose One":
        return (
          <ChooseOption
            selectedOption={this.state.selectedOption}
            handleSelectedOption={this.handleSelectedOption}
          />
        );
      case "Select Sub Categories":
        return (
          <SelectSubCategories
            category={this.state.category}
            sub_category={this.state.sub_category}
            handleSubCategory={(value) =>
              this.setState({ sub_category: value })
            }
          />
        );
      case "Create Topic":
        return (
          <CreateTopic
            topics={this.state.topics}
            handleTopics={(value) => this.setState({ topics: value })}
          />
        );
      case "Add Content":
        return (
          <CreateFlashCard
            cardDetails={this.state.cardDetails}
            setCardDetails={(value) => this.setState(value)}
          />
        );
      default:
        return "Unknown step";
    }
  };

  completeStep = () => {
    if (this.state.categoryValue !== null && this.state.title !== "") {
      return false;
    } else {
      return true;
    }
  };

  Create(params) {
    return new Promise(async (resolve, reject) => {
      let { state, category_id, sub_category_id, snack } = params;
      let id;
      const { client } = this.props;
      let { listvalue, new_value, image_url } = this.state[state];
      if (state === "topics") {
        listvalue = null;
      }
      if (listvalue !== null) {
        id = listvalue.id;
        resolve(id);
        return;
      }
      if (new_value !== "" && image_url !== null && listvalue === null) {
        let values = variables[state].create;

        if (state === "category") {
          id = uuidv4();
        } else {
          id = uuidv4().split("-").pop();
        }

        let filename =
          id +
          "_" +
          image_url.name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "-");

        values["id"] = id;
        values["image_url"] = state + "/" + filename;
        if (values.hasOwnProperty("name")) {
          values["name"] = new_value;
        }
        if (values.hasOwnProperty("title")) {
          values["title"] = new_value;
        }
        if (values.hasOwnProperty("url_param")) {
          values["url_param"] = `${
            new_value.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "-") +
            "-" +
            id.split("-")[0]
          }`.toLowerCase();
        }
        if (category_id !== null && values.hasOwnProperty("category_id")) {
          values["category_id"] = category_id;
        }
        if (
          sub_category_id !== null &&
          values.hasOwnProperty("sub_category_id")
        ) {
          values["sub_category_id"] = sub_category_id;
        }
        client
          .mutate({
            mutation: mutation[state].create,
            variables: {
              ...values,
            },
          })
          .then(async (res) => {
            let bodyFormData = new FormData();
            bodyFormData.set("name", filename);
            bodyFormData.set("folder", state);
            bodyFormData.set("file", image_url);
            uploadPhoto(bodyFormData).then(async (res) => {
              if (res) {
                console.log("image uploaded!");
                resolve(id);
              }
            });
          })
          .catch((error) => {
            snack.setSnack({
              ...snack,
              open: true,
              type: "error",
              msg: "Something went wrong, please try again later!",
              vertical: "bottom",
            });
            console.log(error);
            reject(error);
          });
      }
    });
  }

  Insert_Data = async (snack) => {
    const { msg, activeStep, response } = this.Validate();
    if (!response) {
      snack.setSnack({
        ...snack,
        open: true,
        type: "error",
        msg: msg,
        vertical: "bottom",
      });
      this.setState({ activeStep: activeStep });
      return;
    }

    var category_id, sub_category_id, topic_id;
    //Create Category
    this.Create({
      state: "category",
      category_id: null,
      sub_category_id: null,
      snack: snack,
    }).then((id) => {
      category_id = id;
      console.log(category_id);
      if (this.state.selectedOption.includes("sub")) {
        //Create Sub Category if exists
        this.Create({
          state: "sub_category",
          category_id: category_id,
          sub_category_id: null,
          snack: snack,
        }).then(async (id) => {
          sub_category_id = id;
          //Create topics
          this.Create({
            state: "topics",
            category_id: null,
            sub_category_id: sub_category_id,
            snack: snack,
          }).then((id) => {
            topic_id = id;
            processCards();
          });
        });
      } else {
        //Create topics
        this.Create({
          state: "topics",
          category_id: category_id,
          sub_category_id: null,
          snack: snack,
        }).then((id) => {
          topic_id = id;
          processCards();
        });
      }
    });

    const processCards = () => {
      const { client } = this.props;
      if (topic_id !== null) {
        this.state.cardDetails.map(async (value, index) => {
          let values = variables.card.create;
          let id = uuidv4();
          values["id"] = id;
          values["title"] = value.title;
          values["content"] = value.content;
          values["topic_id"] = topic_id;
          values["created_at"] = new Date();
          values["updated_at"] = new Date();
          values["order_by"] = index + 1;
          await client.mutate({
            mutation: mutation.card.create,
            variables: {
              ...values,
            },
          });
        });
        snack.setSnack({
          ...snack,
          open: true,
          type: "success",
          msg: "Successfully Created Content!",
          vertical: "bottom",
        });
        this.handleReset();
      } else {
        snack.setSnack({
          ...snack,
          open: true,
          type: "error",
          msg: "Something went wrong, please check again!",
          vertical: "bottom",
        });
        this.setState({ activeStep: 0 });
      }
    };
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    return (
      <SnackBarContext.Consumer>
        {(snack) => (
          <div className={classes.componentRender}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={classes.root}
            >
              <div className={classes.Stepper}>
                <Hidden smDown>
                  <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={this.state.activeStep}
                    className={classes.stepperLabel}
                  >
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Hidden>
                <>
                  {this.state.activeStep === steps.length ? (
                    <>
                      {this.completeStep ? (
                        <div className={classes.stepContent}>
                          <Typography
                            variant="h4"
                            style={{ textAlign: "center" }}
                          >
                            Verify and Finish!
                          </Typography>
                          <Typography variant="h6">
                            Category :{" "}
                            {this.state.category.listvalue !== null
                              ? this.state.category.listvalue.name
                              : this.state.category.new_value}
                          </Typography>
                          <Typography variant="h6">
                            Sub Category :{" "}
                            {this.state.sub_category.listvalue !== null
                              ? this.state.sub_category.listvalue.name
                              : this.state.sub_category.new_value}{" "}
                          </Typography>
                          <Typography variant="h6">
                            Title : {this.state.topics.new_value}
                          </Typography>
                          {/* <MobileStepper cardDetails={this.state.cardDetails} /> */}
                        </div>
                      ) : (
                        <div>
                          <Typography variant="h4">Please Check!</Typography>
                        </div>
                      )}
                      <div className={classes.stepperActions}>
                        <Button
                          onClick={this.handleReset}
                          className={classes.button}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => this.Insert_Data(snack)}
                        >
                          Finish
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Grid xs={12} item className={classes.stepContent}>
                        {this.getStepContent(this.state.activeStep)}
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.stepperActions}>
                          <Hidden smDown>
                            <Button
                              variant="text"
                              disabled={this.state.activeStep === 0}
                              onClick={this.handleBack}
                              size="large"
                              className={classes.button}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={this.handleNext}
                              className={classes.button}
                            >
                              {getSteps().length === this.state.activeStep + 1
                                ? "Add"
                                : "Next"}
                            </Button>
                          </Hidden>
                          <Hidden mdUp>
                            <MobileStepper
                              steps={steps}
                              activeStep={this.state.activeStep}
                              handleBack={this.handleBack}
                              handleNext={this.handleNext}
                            />
                          </Hidden>
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              </div>
            </Grid>
          </div>
        )}
      </SnackBarContext.Consumer>
    );
  }
}

export default withRouter(withStyles(styles)(withApollo(Create)));
