import React,{useState} from 'react';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

import {ThemeContext} from './contexts';

let lightTheme = createMuiTheme(require('./theme/light_theme.json'));
let darkTheme = createMuiTheme(require('./theme/dark_theme.json'));

lightTheme = responsiveFontSizes(lightTheme);
darkTheme = responsiveFontSizes(darkTheme);

const AppTheme = (props) => {
  let [theme,setTheme] = useState({
    Darktheme:false
  })

  const themes = theme.Darktheme ? darkTheme : lightTheme;
  return (
    <ThemeContext.Provider value={{...theme,setTheme}}>
    <ThemeProvider theme={themes}>
        {props.children}
    </ThemeProvider>
    </ThemeContext.Provider>
  );
}
export default AppTheme;
