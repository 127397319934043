import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Typography,
  Switch,
  IconButton,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { parseISO, format } from "date-fns";
import { get } from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  name: {
    paddingLeft: 10,
  },
}));

function DashboardTableBody(props) {
  const classes = useStyles();
  const {
    page,
    rowsPerPage,
    rows,
    columns,
    onChangeSwitch,
    loading,
    onEdit,
    onDelete,
  } = props;
  //   Avatar letter
  const getAvatarLetter = (value) => {
    return value[0].toUpperCase();
  };
  // Render table cell
  const renderCell = (item, column) => {
    // For date
    if (column?.type === "date") {
      return format(parseISO(get(item, column.path)), "EEEE dd MMM,yyyy");
    }
    // For user name and email
    if (column?.type === "email") {
      return (
        <div className={classes.flexContainer}>
          <Avatar src={item?.profile_pic} className={classes.primary}>
            {getAvatarLetter(item?.[column.path])}
          </Avatar>
          <Typography variant="subtitle1" className={classes.name}>
            {item?.[column.path]}
          </Typography>
        </div>
      );
    }
    // For switch
    if (column?.type === "switch") {
      return (
        <Switch
          checked={item?.[column.path]}
          onChange={() => onChangeSwitch(item)}
          color="primary"
          name="checkedB"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      );
    }
    // For Edit
    if (column?.type === "edit") {
      return (
        <IconButton
          size="small"
          aria-label="edit"
          color="primary"
          onClick={() => onEdit(item)}
        >
          <EditIcon />
        </IconButton>
      );
    }
    // For delete
    if (column?.type === "delete") {
      return (
        <IconButton
          size="small"
          aria-label="delete"
          color="secondary"
          onClick={() => onDelete(item)}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
    return !!column?.path ? get(item, column.path) : "";
  };
  // CreateKey
  const createKey = (item, column) => {
    return item._id + column.label;
  };
  return loading ? (
    <TableBody>
      {[1, 2].map((rows, index) => (
        <TableRow key={`${index}${rows}`}>
          <TableCell>
            <Skeleton />
          </TableCell>
          {columns.map((column, index) => (
            <TableCell key={index}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  ) : (
    <TableBody>
      {rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => (
          <TableRow hover key={index + page * rowsPerPage}>
            {/* Index */}
            <TableCell align="center">
              {index + page * rowsPerPage + 1}
            </TableCell>
            {columns.map((column) => (
              <TableCell key={createKey(row, column)} align={column.align}>
                {renderCell(row, column)}
              </TableCell>
            ))}
          </TableRow>
        ))}
    </TableBody>
  );
}

export default DashboardTableBody;
