import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import routes from "./routes"; //all routes

import {
    Home,
    BlankPage,
    NotFound,
    ResetPassword,
    VerifyEmail
} from "../screens";

import PrivateRoute from './privateRoute'

import { Logout } from "../components";

export const RouterApp = (props) => {
    return (
        <Router basename={`${process.env.PUBLIC_URL}`}>
            <Switch>
                <Redirect exact path="/admin" to={routes.Admin.users} />

                <Route exact component={BlankPage} path={routes.BlankPage} {...props} />
                {/*Home Path*/}
                <Route exact component={Home} path={routes.Home}  {...props} />
                <Route exact component={Home} path={routes.Bookmark}  {...props} />

                {/*User-Auth Path
                <Route exact component={SignIn} path={routes.Login}  {...props} />
                <Route exact component={SignUp} path={routes.Register}  {...props} />*/}
                <Route exact component={Logout} path={routes.Logout}  {...props} />

                {/*Content Path*/}
                <Route exact component={Home} path={routes.Content}  {...props} />
                <Route exact component={Home} path={routes.TopicList}  {...props} />
                {
                    routes.Card.map((path, index) =>
                        <Route key={index} exact component={Home} path={path}  {...props} />
                    )
                }
                {/*Admin Path*/}
                <PrivateRoute exact component={Home} path={routes.Admin.users}  {...props} />
                <PrivateRoute exact component={Home} path={routes.Admin.categories}  {...props} />
                <PrivateRoute exact component={Home} path={routes.Admin.subcategories}  {...props} />
                <PrivateRoute exact component={Home} path={routes.Admin.topics}  {...props} />
                <PrivateRoute exact component={Home} path={routes.Admin.flashcards}  {...props} />

                {/*Create Path*/}
                <PrivateRoute exact component={Home} path={routes.Create}  {...props} />

                {/*Edit Path*/}
                <PrivateRoute exact component={Home} path={routes.Edit}  {...props} />

                {/*Reset Password*/}
                <Route exact component={ResetPassword} path={routes.ResetPassword}  {...props} />
                {/*Verify Email*/}
                <Route exact component={VerifyEmail} path={routes.VerifyEmail}  {...props} />

                <Route path="*" component={NotFound} />
            </Switch>
        </Router>
    )
}

export default RouterApp;
