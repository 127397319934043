import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="user_3_" transform="translate(4.781 201.477)">
            <g id="Group_66" transform="translate(0)">
                <path id="Path_97" d="M35.4-165.3c-0.1-0.7-0.8-1.1-1.4-0.9c-0.7,0.1-1.1,0.8-0.9,1.4c0.1,0.4,0,0.8-0.3,1.1
           c-0.2,0.3-0.6,0.5-1,0.5H8.1c-0.4,0-0.7-0.2-1-0.5c-0.2-0.3-0.3-0.7-0.3-1.1c1.3-6.1,6.6-10.5,12.8-10.6c0.1,0,0.2,0,0.3,0
           s0.2,0,0.3,0c4.3,0.1,8.4,2.3,10.8,5.9c0.4,0.6,1.1,0.7,1.7,0.4s0.7-1.1,0.4-1.7c0,0,0,0,0,0c-2-2.9-4.8-5.1-8.2-6.2
           c3.7-2.7,4.4-7.9,1.7-11.6c-2.7-3.7-7.9-4.4-11.6-1.7s-4.4,7.9-1.7,11.6c0.5,0.6,1,1.2,1.7,1.7c-5.4,1.8-9.4,6.3-10.5,11.8
           c-0.4,2,0.9,4,2.9,4.4c0.2,0.1,0.5,0.1,0.8,0.1h23.7c2,0,3.7-1.7,3.7-3.7C35.5-164.8,35.5-165.1,35.4-165.3z M14.1-183.7
           c0-3.2,2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8c0,3.1-2.4,5.7-5.5,5.8H20h-0.3C16.6-178.1,14.1-180.6,14.1-183.7z"/>
            </g>
        </g>
    </svg>

);
export default SVG;
