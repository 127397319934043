import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="ebook" transform="translate(925.91 333.351)">
            <g id="Group_118_1_" transform="translate(0)">
                <g id="Group_117_1_" transform="translate(0)">
                    <path id="Path_149" d="M-897.4-303.6c0-0.6-0.5-1.1-1.1-1.1l0,0h-6.1c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h6.1
               C-897.9-302.4-897.4-302.9-897.4-303.6C-897.4-303.6-897.4-303.6-897.4-303.6z"/>
                    <circle id="Ellipse_32" cx="-899.9" cy="-299.3" r="1.1" />
                    <path id="Path_150" d="M-891-302.6c0.6,0,1.1-0.5,1.1-1.1V-317c0-2.3-1.9-4.2-4.2-4.2h-11.4c-2.3,0-4.2,1.9-4.2,4.2v18.6
               c0,2.3,1.9,4.2,4.2,4.2h11.4c2.3,0,4.2-1.9,4.2-4.2c0-0.6-0.5-1.1-1-1.1c-0.6,0-1.1,0.5-1.1,1c0,0,0,0,0,0c0,1.2-0.9,2.1-2.1,2.1
               h-11.4c-1.2,0-2.1-0.9-2.1-2.1V-317c0-1.2,0.9-2.1,2.1-2.1h11.4c1.2,0,2.1,0.9,2.1,2.1v13.3C-892-303.1-891.6-302.6-891-302.6z"
                    />
                    <path id="Path_151" d="M-894.4-307.7c0-0.6-0.4-1.1-1-1.1h-8.8c-0.6,0.1-1.1,0.6-1,1.3c0.1,0.5,0.5,0.9,1,1h8.8
               C-894.9-306.6-894.4-307.1-894.4-307.7z"/>
                    <path id="Path_152" d="M-895.4-317h-8.8c-0.6,0.1-1.1,0.6-1,1.3c0.1,0.5,0.5,0.9,1,1h8.8c0.6-0.1,1.1-0.6,1-1.3
               C-894.5-316.5-894.9-316.9-895.4-317z"/>
                    <path id="Path_153" d="M-895.4-312.9h-8.8c-0.6,0.1-1.1,0.6-1,1.3c0.1,0.5,0.5,0.9,1,1h8.8c0.6-0.1,1.1-0.6,1-1.3
               C-894.5-312.4-894.9-312.8-895.4-312.9z"/>
                </g>
            </g>
        </g>
    </svg>
);
export default SVG;
