import axios from "axios";
import config from '../config'

export default {
    base: axios.create({
        baseURL: config.url,
    }),
    auth: axios.create({
        baseURL: config.api_url + '/v1/auth',
    }),
    admin: axios.create({
        baseURL: config.api_url + '/v1/admin',
    })
}