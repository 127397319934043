/*global google*/
import React from 'react';
import { Redirect } from "react-router-dom";
import { AuthContext } from '../../contexts';
export default function Logout(props) {
    const Auth = React.useContext(AuthContext)
    React.useEffect(() => {
        Auth.setAuth({ user: {} })
        localStorage.removeItem("auth_token")
        google.accounts.id.disableAutoSelect();
    })
    return (
        <>
            <Redirect to="/" />
        </>
    )
}