import React from "react";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
  Hidden,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import {
  Login as userLogin,
  CheckEmail,SignUp,Google_Verify,ForgotPassword
} from "../../services";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { AuthContext, SnackBarContext } from "../../contexts";
import GoogleLogin from "react-google-login";
import { DoseLogo } from "../../icons";
import { get } from 'lodash'
import Routes from "../../router/routes";
const styles = (theme) => ({
  container: {
    backgroundColor: "transparent",
    overflow: "hidden",
    //height: "100vh",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      padding: 5,
    },
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 8,
    //width: "82%",
    height: 500,
    margin: "auto",
    padding: "31px 40px",
    boxShadow: "0px 0px 21px #00000014",
    [theme.breakpoints.up("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.only("xs")]: {
      // height: "calc(100vh - 100px)",
      height: "100%",
      width: "88%",
      padding: "10px 20px",
    },
  },
  title: {
    color: "#444444d4",
    fontFamily: "Proxima Nova",
    fontWeight: 500,
  },
  heading: {
    fontFamily: "Neutra Text",
    fontWeight: 600,
    color: "#444444",
    fontSize: "23px",
  },
  imgCont: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      display: "flex",
    },
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    [theme.breakpoints.only("md")]: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
  pic: {
    width: "100%",
    height: 360,
    objectFit: "contain",
    [theme.breakpoints.only("sm")]: {
      height: 300,
    },
    [theme.breakpoints.only("xs")]: {
      height: 150,
    },
  },
  line: {
    float: "left",
    [theme.breakpoints.only("sm")]: {
      height: 360,
    },
    [theme.breakpoints.only("md")]: {
      height: 376,
    },
    [theme.breakpoints.up("lg")]: {
      height: 369,
    },
  },
  form: {
    padding: "0px 0px 45px 45px",
    [theme.breakpoints.up("lg")]: {
      padding: "0px 0px 45px 42px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0px 0px 45px 39px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0px 0px",
    },
  },
  subtitle: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1F2427",
    width: "100%",
  },
  field: {
    width: "100%",
    marginBottom: 10,
    borderRadius: 8,
    height: "36px !important",
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
      marginRight: 0,
      textAlign: "right",
      fontWeight: "300",
    },
    "& .MuiFormHelperText-root": {
      marginTop: 1,
      fontSize: 12,
      color: "#f44336",
    },
  },
  formtitle: {
    fontWeight: "bold",
    marginBottom: 25,
    [theme.breakpoints.only("xs")]: {
      marginBottom: 5,
      textAlign: "center",
      color: theme.palette.primary.main,
    },
  },
  noaccount: {
    marginTop: 10,
    fontSize: 14,
    color: "#1F2427",
    fontStyle: "normal",
    fontWeight: "normal",
    [theme.breakpoints.only("xs")]:{
        marginTop: 15
    }
  },
  signup: {
    fontSize: 16,
    color: "#3D4690",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
  },
  forget: {
    fontSize: 14,
    fontWeight: "bold",
    float: "right",
    margin: "5px 0",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
  },
  buttoncont: {
    fontWeight: "bold",
    marginTop: 5,
    width: "100%",
    textTransform: "capitalize",
    [theme.breakpoints.only("xs")]: {
      marginTop: 5,
    },
  },
  formCont: {
    alignItems: "center",
    justifyContent: "center",
  },
  bottomDivider: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  botdiv: {
    width: "100%",
    color: "#4444443B",
    [theme.breakpoints.only("xs")]: {
      width: "75%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "82%",
    },
  },
  noaccount1: {
    fontSize: 15,
    color: "#1F2427",
    marginTop: 15,
    [theme.breakpoints.only("xs")]: {
      marginTop: 10,
      fontSize: 12,
    },
  },
  noaccount2: {
    float: "right",
    justifyContent: "center",
    alignItems: "center",
    display: "contents",
    fontSize: 10,
    color: "#1F2427",
    marginTop: 5,
  },
  checkbox: {
    padding: "9px 9px 9px 0px",
  },
  closeButton: {
    "&:hover": {
      cursor: "pointer",
    }
  },
  googleButton: {
    width: "100%",
    justifyContent: "center",
    textTransform: "capitalize",
    fontSize: "0.875rem!important",
    color: "rgba(0, 0, 0, 0.54);!important",
    fontFamily: `${theme.typography.fontFamily}!important`,
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)!important",
    "& span": {
      fontWeight: "bold!important",
    },
  },
  divider: {
    fontWeight: "300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "grey",
    margin: "10px 0",
    "&::before": {
      content: "''",
      display: "block",
      background: "rgba(0, 0, 0, 0.2)",
      width: "50%",
      height: 1,
      marginRight: 10,
    },
    "&::after": {
      content: "''",
      display: "block",
      background: "rgba(0, 0, 0, 0.2)",
      width: "50%",
      height: 1,
      marginLeft: 10,
    },
  },
  doselogo: {
    width: 80,
    fill: "#000",
    [theme.breakpoints.only('xs')]:{
        width: 60
    }
  },
});

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "Sign in",
      email: "",
      password: "",
      checked: false,
      error: false,
      helperText: "",
      loading: false,
      forgotPassword: false,
    };
  }

  toggle_component = (data) => {
    //toggle between sign in /
    this.setState({
      name: data,
      forgotPassword: false,
      error: false,
      helperText: "",
    });
  };

  handleChange = (e) => {
    var name = e.target.name;
    if (name === "checked") {
      this.setState({
        [name]: e.target.checked,
      });
    } else {
      this.setState({
        [name]: e.target.value,
        error: false,
        helperText: "",
      });
    }
  };

  validate(email, password) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    if (email === "" && password === "") {
      this.setState({
        error: "both",
        helperText: "Please enter your email id and password.",
      });
      return false;
    }
    if (
      email === "" ||
      (email !== "" && !re.test(String(email).toLowerCase()))
    ) {
      this.setState({
        error: "email",
        helperText: "Please enter valid email id.",
      });
      return false;
    }
    if (password === "") {
      this.setState({
        error: "password",
        helperText: "Password cannot be empty!",
      });
      return false;
    }
    return true;
  }

  login = (email, password) => {
    if (!this.validate(email, password)) {
      this.setState({ loading: false });
      return;
    }
    userLogin({
        emailId: email,
        password: password,
      })
      .then((res) => {
        if (res.status === 200) {
          let user = res.data.user;
          this.context.setAuth({ user: user });
          localStorage.setItem("auth_token", res.data.auth_token);
          this.setState({ loading: false }, () => {
            const isRoleAdmin = get(user, ['user_profiles', '0', 'workspace_user_profiles', '0', 'role', 'name']) === 'admin'
            this.props.history.replace({
              pathname: isRoleAdmin? Routes.Admin.users : this.props.location.pathname,
              state: { resetted: false },
            });
            this.props.onClose();
          });
        }
        if (res.status === 500) {
          this.setState({
            loading: false,
            error: "both",
            helperText: res.data.message,
          });
        }
      })
      .catch((err) => {
        const { status, message } = err.response?.data;
        if (status === 400 || status === 401) {
          this.setState({
            loading: false,
            error: "both",
            helperText: message,
          });
        } else {
          this.setState({ loading: false });
          console.log(err.response);
        }
      });
  };

  signup = (email, password, snack) => {
    if (!this.validate(email, password)) {
      this.setState({ loading: false });
      return;
    }
    CheckEmail({
        emailId: email,
      })
      .then((res) => {
        if (res.data.isExist) {
          this.setState({
            error: "email",
            helperText: "This email id exists!",
          });
          return;
        } else {
          SignUp({
              emailId: email,
              password: password,
            })
            .then((res) => {
              if (res.status === 200) {
                this.context.setAuth({ user: res.data.user });
                snack.setSnack({
                  ...snack,
                  open: true,
                  type: "success",
                  msg: res.data.message,
                  vertical: "bottom",
                });
                //localStorage.setItem("auth_token", res.data.auth_token);
                this.setState({ loading: false }, () => {
                  this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: { resetted: false },
                  });
                  this.props.onClose();
                });
              }
              if (res.status === 500) {
                this.setState({
                  loading: false,
                  error: "both",
                  helperText: res.data.message,
                });
                this.setState({ loading: false });
              }
            });
        }
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({ loading: false });
      });
  };

  forgotPassword = (email, snack) => {
    if (!this.validate(email, "forgot")) {
      this.setState({ loading: false });
      return;
    } else {
      ForgotPassword({
          email: email,
        })
        .then((res) => {
          if (res.data.status) {
            snack.setSnack({
              ...snack,
              open: true,
              type: res.data.message.includes("error") ? "error" : "success",
              msg: res.data.message,
              vertical: "bottom",
            });
            this.setState({ loading: false }, () => {
              this.props.onClose();
            });
          } else {
            snack.setSnack({
              ...snack,
              open: true,
              type: "error",
              msg: !res.data.message.includes("Bad")
                ? res.data.message
                : "Something went wrong, try again later!",
              vertical: "bottom",
            });
            this.setState({ loading: false });
          }
        });
    }
  };

  handleSignIn_Up(e, snack) {
    e.preventDefault();
    const { email, password } = this.state;
    const { name } = this.state;
    this.setState({ loading: true });
    if (this.state.forgotPassword) {
      this.forgotPassword(email, snack);
    }
    if (name === "Sign in" && !this.state.forgotPassword) {
      this.login(email, password);
    }
    if (name === "Sign up" && !this.state.forgotPassword) {
      this.signup(email, password, snack);
    }
  }

  Google_Signin = async (response) => {
    var token = response?.tokenId;
    if (token) {
      await Google_Verify({
          token: token,
        })
        .then(async (res) => {
          let token = res.data.auth_token;
          localStorage.setItem("auth_token", token);
          this.context.setAuth({ user: res.data.user });
          this.props.onClose();
        })
        .catch((err) => {
          localStorage.clear();
          this.props.onClose();
        });
    }
  };

  Google_Sign_inError = async (error) => {
    localStorage.clear();
    this.context.setAuth({ user: {} });
    console.log(error)
  };

  render() {
    const { classes, location } = this.props;
    return (
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.card}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignContent="center"
          >
            <Grid item>
                <DoseLogo className={classes.doselogo} />
            </Grid>
            <Grid
              onClick={() => {
                this.props.history.replace({
                  pathname: this.props.location.pathname,
                  search:this.props.location.search,
                  state: { resetted: false },
                });
                this.props.onClose();
              }}
              item
              className={classes.closeButton}
            >
              <CloseRoundedIcon />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            md={12}
            lg={12}
            sm={12}
            xs={12}
            xl={12}
            className={classes.formCont}
          >
            <Hidden xsDown>
              <Grid
                item
                md={6}
                lg={6}
                sm={6}
                xs={12}
                xl={6}
                className={classes.imgCont}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/signin-min.png`}
                  alt=""
                  className={classes.pic}
                />
              </Grid>
            </Hidden>
            {/* <Divider className={classes.divider} orientation='vertical' /> */}
            <Grid item md={6} lg={6} sm={6} xs={12} xl={6}>
              <Grid className={classes.line}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid className={classes.form}>
                <form noValidate autoComplete="off">
                  <Typography variant="h5" className={classes.formtitle}>
                    {location.pathname.includes("admin")
                      ? "Admin Login"
                      : `${
                          this.state.forgotPassword
                            ? "Reset Password"
                            : this.state.name
                        }`.toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    E-mail
                  </Typography>
                  <TextField
                    className={classes.field}
                    error={this.state.error === "email"}
                    helperText={
                      this.state.error === "email" && this.state.helperText
                    }
                    size="small"
                    name="email"
                    value={this.state.email}
                    required
                    type="email"
                    onChange={this.handleChange}
                    variant="outlined"
                  />
                  {!this.state.forgotPassword && (
                    <>
                      <Typography
                        variant="subtitle2"
                        className={classes.subtitle}
                      >
                        {this.state.name === "Sign up"
                          ? "Set Password"
                          : "Password"}
                      </Typography>
                      <TextField
                        className={classes.field}
                        error={this.state.error === "password"}
                        helperText={
                          this.state.error === "password" &&
                          this.state.helperText
                        }
                        size="small"
                        name="password"
                        value={this.state.password}
                        required
                        type="password"
                        onChange={this.handleChange}
                        variant="outlined"
                      />
                    </>
                  )}
                  <Grid>
                    <Typography
                      align="center"
                      className={classes.noaccount}
                      style={{ color: "red", fontSize: 12, fontWeight: "300" }}
                    >
                      {this.state.error === "both" && this.state.helperText}
                    </Typography>
                  </Grid>

                  {/*this.state.name === "Sign up" &&
                                        <Grid>
                                            <Checkbox
                                                name="checked"
                                                className={classes.checkbox}
                                                checked={this.state.checked}
                                                color="primary"
                                                size="small"
                                                onChange={this.handleChange}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                            <Typography align="center" className={classes.noaccount2}>I accept <span className={classes.signup}>Terms of service</span> and private policy</Typography>
                                        </Grid>
                                */}
                  {this.state.name === "Sign in" && (
                    <Grid item>
                      <Typography
                        color="primary"
                        align="center"
                        onClick={() =>
                          this.setState({
                            forgotPassword: !this.state.forgotPassword,
                          })
                        }
                        className={classes.forget}
                      >
                        {!this.state.forgotPassword
                          ? "Forgot password?"
                          : "Remember Password?"}
                      </Typography>
                    </Grid>
                  )}
                  <SnackBarContext.Consumer>
                    {(snack) => (
                      <Button
                        type="submit"
                        className={classes.buttoncont}
                        variant="contained"
                        onClick={(event) => this.handleSignIn_Up(event, snack)}
                        endIcon={
                          this.state.loading ? (
                            <div className="lds-hourglass"></div>
                          ) : (
                            <></>
                          )
                        }
                        color="primary"
                      >
                        {this.state.forgotPassword
                          ? "Reset".toUpperCase()
                          : this.state.name.toUpperCase()}
                      </Button>
                    )}
                  </SnackBarContext.Consumer>
                  <Grid>
                    <Grid item>
                      {/* Divider */}
                      <div className={classes.divider}>Or</div>
                    </Grid>
                    {/* Google Login */}
                    <SnackBarContext.Consumer>
                      {(snack) => (
                        <GoogleLogin
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          buttonText={`${this.state.name} with Google`}
                          onSuccess={this.Google_Signin}
                          onFailure={(error) =>
                            this.Google_Sign_inError(error)
                          }
                          className={classes.googleButton}
                        />
                      )}
                    </SnackBarContext.Consumer>
                    {this.state.name === "Sign in" &&
                      !location.pathname.includes("admin") && (
                        <Typography
                          align="center"
                          className={classes.noaccount}
                        >
                          Don't have an account?{" "}
                          <span
                            onClick={() => this.toggle_component("Sign up")}
                            className={classes.signup}
                          >
                            Sign up
                          </span>
                        </Typography>
                      )}
                  </Grid>
                  {this.state.name === "Sign up" && (
                    <Typography align="center" className={classes.noaccount}>
                      Have an account Already?{" "}
                      <span
                        onClick={() => this.toggle_component("Sign in")}
                        className={classes.signup}
                      >
                        Sign In
                      </span>
                    </Typography>
                  )}
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
Login.contextType = AuthContext;
export default withRouter(withStyles(styles)(Login));
