import React from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import config from "../../config";
import ShareModal from "../share";
//import { dummydata } from "./data";
import Share from "../../icons/share";
import Heart from "../../icons/heart";

const styles = (theme) => ({
  cardContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "20px 10px",
    borderRadius: 20,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 3px 6px #00000029",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0px 6px 8px #00000029",
    },
    [theme.breakpoints.only("xs")]: {
      height: 240,
      padding: 10,
    },
  },
  imageContainer: {
    textAlign: "center",
  },
  image: {
    borderRadius: 18,
    height: 92,
    width: 92,
    [theme.breakpoints.only("xs")]: {
      borderRadius: "50%",
    },
  },
  cardContent: {
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.only("sm")]: {
      paddingLeft: 25,
    },
    [theme.breakpoints.only("xs")]: {
      padding: 5,
    },
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
      textAlign: "center",
    },
  },
  cardSubTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: "#848484",
    [theme.breakpoints.only("xs")]: {
      fontSize: 10,
      textAlign: "center",
    },
  },
  cardButton: {
    padding: "4px 20px",
    fontSize: 16,
    fontWeight: 600,
    textTransform: "capitalize",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 10,
    },
  },
  cardAction: {
    float: "right",
    zIndex: 100,
    "& .MuiIconButton-root:hover": {
      background: "none",
    },
  },
  actionIcon: {
    width: 28,
    height: 28,
    fill: "#EEEEEF",
    [theme.breakpoints.only("sm")]: {
      width: 24,
      height: 24,
    },
    [theme.breakpoints.only("xs")]: {
      width: 22,
      height: 22,
    },
  },
  isRed: {
    fill: "#C20404",
  },
  mobileIconButton: {
    padding: 0,
  },
  mobileLike: {
    position: "absolute",
    top: 15,
    right: 15,
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
});

class Bookmark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      url: "",
      title: "",
    };
  }

  handleOnClick = (title, url) => {
    //console.log(document.location.href.replace('bookmark', url))
    if (navigator.share) {
      navigator
        .share({
          title: title,
          //text: `Check out ${postTitle} on ${siteTitle}`,
          url: url,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong sharing the blog", error);
        });
    } else {
      this.setState({ open: true, url: url, title: title });
    }
  };

  getTitle = (title) => {
    return title.substr(0, 55) + "...";
  };

  getCategoryName = (data) => {
    const { category, subcategory } = data.card.topic;
    return category ? category.name : subcategory.category.name;
  };

  getParams = (data) => {
    const { subcategory } = data.card.topic;
    const topic = data.card.topic;
    let search = "";
    if (subcategory) {
      search =
        search + `/subcategory/${subcategory.urlParam}/topic/${topic.urlParam}`;
    } else {
      search = search + `/topic/${topic.urlParam}`;
    }
    return search;
  };

  render() {
    const { classes } = this.props;
    const { data } = this.props;
    return (
      <>
        {/* Card Container */}
        <Grid
          item
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          xs={12}
          className={classes.cardContainer}
        >
          {/* Card Image */}
          <Grid item xl={1} lg={1} md={2} sm={2} xs={12}>
            <div className={classes.imageContainer}>
              <img
                className={classes.image}
                src={`${config.url}/${data.card.topic.imageUrl}`}
                alt=""
              />
            </div>
          </Grid>
          {/* Card Content */}
          <Grid
            item
            xl={8}
            lg={8}
            md={6}
            sm={8}
            xs={12}
            onClick={() =>
              this.props.history.push({
                pathname:
                  this.getCategoryName(data).replace(/ /g, "-") +
                  this.getParams(data),
                state: { card_id: data.card.id },
              })
            }
          >
            <div className={classes.cardContent}>
              {/* Card Title */}
              <Typography variant="h3" className={classes.cardTitle}>
                {this.getTitle(data.card.content)}
              </Typography>
              {/* Card body */}
              <Grid
                container
                item
                justify="flex-start"
                alignItems="center"
                xs={12}
              >
                {/* Card subtitle */}
                <Hidden xsDown>
                  <Grid item>
                    <Typography
                      variant="h6"
                      className={classes.cardSubTitle}
                      style={{ marginRight: 15 }}
                    >
                      {data.card.topic.title}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden smUp>
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.cardSubTitle}>
                      {data.card.topic.title}
                    </Typography>
                  </Grid>
                </Hidden>
                {/* Card Action button */}
                <Hidden xsDown>
                  <Grid item>
                    <Button size="medium" className={classes.cardButton}>
                      {this.getCategoryName(data)}
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
            </div>
          </Grid>
          {/* Card Actions */}
          <Hidden xsDown>
            <Grid item xl={2} lg={2} md={3} sm={2} xs={12}>
              <div className={classes.cardAction}>
                <IconButton
                  size="medium"
                  onClick={() => {
                    this.handleOnClick(
                      data.card.topic.title,
                      `${window.location.href.replace(
                        "bookmark",
                        ""
                      )}${this.getCategoryName(data)}${this.getParams(data)}`
                    );
                  }}
                >
                  <Share className={classes.actionIcon} />
                </IconButton>
                <IconButton size="medium">
                  <Heart className={`${classes.actionIcon} ${classes.isRed}`} />
                </IconButton>
              </div>
            </Grid>
          </Hidden>
          {/* Card Action On mobile */}
          <Hidden smUp>
            <Grid
              container
              item
              justify="space-between"
              alignItems="center"
              xs={12}
            >
              <Grid item>
                <Button size="medium" className={classes.cardButton}>
                  {this.getCategoryName(data)}
                </Button>
              </Grid>
              <Grid item>
                <IconButton
                  size="medium"
                  onClick={() => {
                    this.handleOnClick(
                      data.card.topic.title,
                      `${window.location.href.replace(
                        "bookmark",
                        ""
                      )}${this.getUrl(data)}`
                    );
                  }}
                  className={classes.mobileIconButton}
                >
                  <Share className={classes.actionIcon} />
                </IconButton>
              </Grid>
            </Grid>
          </Hidden>
          {/* Like button on mobile */}
          <Hidden smUp>
            <div className={classes.mobileLike}>
              <IconButton size="medium">
                <Heart className={`${classes.actionIcon} ${classes.isRed}`} />
              </IconButton>
            </div>
          </Hidden>
        </Grid>

        <ShareModal
          title={this.state.title}
          url={this.state.url}
          open={this.state.open}
          handleClose={() => this.setState({ open: false })}
        />
      </>
    );
  }
}
export default withRouter(withStyles(styles)(Bookmark));
