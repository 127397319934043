import React from "react";
import { Dialog, DialogTitle, DialogContent, Slide, Grid, Typography } from '@material-ui/core';
import { withApollo } from "react-apollo";
import { withStyles } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import {
    EmailShareButton, //Share Buttons 
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,

    EmailIcon, //Share Icons
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    paper: {
        height: 'auto',
        width: 'auto',
        borderRadius: 25,
        position: 'absolute',
        bottom: 0,
        marginBottom: 10,
        [theme.breakpoints.down("sm")]: {
            backgroundColor: '#fff'
        },
    }
})


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Share extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.props.open}
                className={classes.root}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{ className: classes.paper }}
            >
                <DialogTitle id="alert-dialog-slide-title">
                    <Typography style={{ fontWeight: 'bold', fontSize: '19px', textAlign: 'center' }}>
                        Share - {this.props.title}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <EmailShareButton subject={this.props.title} url={this.props.url}>
                                <EmailIcon size={50} round={true} />
                            </EmailShareButton>
                        </Grid>
                        <Grid item >
                            <FacebookShareButton url={this.props.url}>
                                <FacebookIcon size={50} round={true} />
                            </FacebookShareButton>
                        </Grid>
                        <Grid item >
                            <LinkedinShareButton
                                summary={''}
                                url={this.props.url}
                                title={this.props.title}
                                source={'crayond-infopedia.ga'}>
                                <LinkedinIcon size={50} round={true} />
                            </LinkedinShareButton>
                        </Grid>
                        <Grid item >
                            <RedditShareButton url={this.props.url} title={this.props.title} >
                                <RedditIcon size={50} round={true} />
                            </RedditShareButton>
                        </Grid>
                        <Grid item >
                            <TelegramShareButton url={this.props.url} title={this.props.title} >
                                <TelegramIcon size={50} round={true} />
                            </TelegramShareButton>
                        </Grid>
                        <Grid item >
                            <TumblrShareButton url={this.props.url}>
                                <TumblrIcon size={50} round={true} />
                            </TumblrShareButton>
                        </Grid>
                        <Grid item >
                            <TwitterShareButton url={this.props.url} title={this.props.title}>
                                <TwitterIcon size={50} round={true} />
                            </TwitterShareButton>
                        </Grid>
                        <Grid item >
                            <WhatsappShareButton url={this.props.url} title={this.props.title}>
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog >
        )
    }
}

export default withRouter(withStyles((styles))(withApollo(Share)));