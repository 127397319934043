import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, withRouter } from "react-router-dom";
import { AuthContext } from './../../contexts';
import routes from "../../router/routes";
import { HomeIcon, AboutIcon, BookmarkIcon, ProfileIcon, SignoutIcon, TaggedIcon, DoseLogo,CategoryIcon } from "../../icons";
import { get } from 'lodash'

const useStyles = makeStyles(theme => ({
    menuContainer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        minHeight: "100vh",
        height: "100%",
        /* Scroll  */
        '&::-webkit-scrollbar': {
            width: 0,
            display: "none"
        },
        [theme.breakpoints.down("sm")]: {
            width: "240px",
            minHeight: "calc(100vh - 111px)",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "space-between"
        }
    },
    linkStyle: {
        textDecoration: "none",
        color: theme.palette.common.white
    },
    listContainer: {
        width: "100%",
        paddingTop: 15,
    },
    iconsContainer: {
        paddingLeft: 20
    },
    fontsize: {
        // fontFamily:"Proxima Nova",
        fontSize: 18,
        //paddingLeft: 10,
        color: theme.palette.text.hint,
        fontWeight: "bold !important",
        opacity: 1,
        letterSpacing: 0
    },
    imageIcon: {
        width: 50,
        height: 50,
        fill: "#A4A8CB",
        '&:active': {
            fill: theme.palette.common.white,
        }

    },
    listItem: {
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        }
    },
    listItemActiveClass: {
        '& li': {
            backgroundColor: theme.palette.primary.dark,
            opacity: 1,
            background: `transparent linear-gradient(90deg, #EEF3F938 0%, ${theme.palette.primary.dark} 76%) 0% 0% no-repeat padding-box`,
        },
        '& .navText': {
            color: theme.palette.common.white
        },
        '& .navIcon svg': {
            fill: theme.palette.common.white
        }
    },
    borderline: {
        height: "90%",
        width: "6px",
        borderTopRightRadius: "25px",
        borderBottomRightRadius: "25px",
        background: "#FFD449",
        display: "flex",
        position: "absolute",
    },
    heading: {
        fontFamily: "Neutra Text",
        fontWeight: 'bold',
        fontSize: "23px",
    },
    copyrightContainer: {
        padding: "15px 0",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            width: "65%",
            padding: "10px 0"
        }
    },
    textContainer: {
        padding: "5px 15px",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 0",
        }
    },
    copyrightText: {
        fontSize: 12,
        fontWeight: 400,
        color: "#DBDFEA",
    },
    copyrightLogo: {
        width: 110
    },
    logoContainer: {
        padding: 10,
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            width: "60%",
        }
    },
    doselogo: {
        width: 100,
        fill: "#ffffff"
    }
}));


const SideMenu = props => {
    const classes = useStyles();
    const Auth = React.useContext(AuthContext);
    const isAuth = Auth.user?.id
    const Menus = [
        {
            name: 'Home',
            icon: <HomeIcon className={classes.imageIcon} />,
            path: "/",
            needAuth: false
        },
        {
            name: 'Categories',
            icon: <CategoryIcon className={classes.imageIcon} />,
            path: "/",
            needAuth: true,
            needAdmin: true
        },
        {
            name: 'Bookmark',
            icon: <BookmarkIcon className={classes.imageIcon} />,
            path: "/bookmark",
            needAuth: true
        },
        {
            name: 'Tagged',
            icon: <TaggedIcon className={classes.imageIcon} />,
            path: "/tagged",
            needAuth: true
        },
        {
            name: 'Profile',
            icon: <ProfileIcon className={classes.imageIcon} />,
            path: "/profile",
            needAuth: true
        },
        {
            name: 'About Us',
            icon: <AboutIcon className={classes.imageIcon} />,
            path: "/about",
            needAuth: false
        },
        {
            name: 'Sign Out',
            icon: <SignoutIcon className={classes.imageIcon} />,
            path: "/logout",
            needAuth: true
        },
    ]

    const navbarRenderer = (menu, index) => {
        var isAdmin = get(Auth.user, ['user_profiles', '0', 'workspace_user_profiles', '0', 'role', 'name']) === 'admin'
        if (menu.name === 'Home' && isAdmin) {
            menu['path'] = "/admin/users"
        }
        if (menu.name === 'Profile' || menu.name === 'About Us' || menu.name === 'Tagged') {
            return;
        }
        if (menu?.needAdmin && !isAdmin) {
            return;
        }
        return (<NavLink
            key={index}
            to={menu.path === "undefined" ? routes.BlankPage : menu.path}
            exact
            className={classes.linkStyle}
            onClick={props.toogleDrawer}
            activeClassName={classes.listItemActiveClass}
        >
            <ListItem className={classes.listItem}>
                {/* {alert(JSON.stringify(window.location.pathname))} */}
                {(menu.path === props.location.pathname) &&
                    <div className={classes.borderline}></div>
                }
                <ListItemIcon className={`${classes.iconsContainer} navIcon`}>
                    {menu.icon}
                </ListItemIcon>
                <Typography
                    className={`${classes.fontsize} navText`} >{menu.name}</Typography>
            </ListItem>


        </NavLink>);
    }

    return (
        <Grid container className={classes.menuContainer} direction="column" justify="space-between">
            <Grid xs={12} sm={12} md={12} lg={12} item className={classes.listContainer}>
                {/* Brand Logo */}
                <div className={classes.logoContainer}>
                    <DoseLogo className={classes.doselogo} />
                </div>
                <List>
                    {/* Menu Options */}
                    {
                        Menus.map((menu, index) => {
                            return (
                                menu.needAuth ? isAuth ? navbarRenderer(menu, index) : "" : navbarRenderer(menu, index)
                            )
                        })
                    }
                </List>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} item>
                <div className={classes.copyrightContainer}>
                    <div className={classes.textContainer}>
                        <Typography className={classes.copyrightText}>Made with love by</Typography>
                    </div>
                    <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt='' className={classes.copyrightLogo} />
                </div>
            </Grid>
        </Grid>
    );
};

export default withRouter(SideMenu);
