import React from 'react';
import './App.css';
import AppAuth from './App.auth';
import AppAlert from './App.alert';
import AppLogin from './App.Login';
import AppGQLClient from './App.gql';
import AppTheme from './App.theme';
import RouterApp from './router';
import AppErrorBoundary from './App.error_boundry';

function App() {
  return (
    <AppGQLClient>
      <AppAuth>
        <AppErrorBoundary>
          <AppTheme>
            <AppLogin>
              <AppAlert>
                <RouterApp />
              </AppAlert>
            </AppLogin>
          </AppTheme>
        </AppErrorBoundary>
      </AppAuth>
    </AppGQLClient>
  );
}

export default App;
