import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { withApollo, Query } from "react-apollo";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { FIND_ALL_BOOKMARKS } from "../../graphql/query";
import { Bookmark } from "../../components";
import BottomNavbar from "../../components/bottomNavbar";
import { AuthContext } from "./../../contexts";
const styles = (theme) => ({
  componentRender: {
    paddingLeft: 166,
    paddingRight: 166,
    paddingTop: 8,
    overflowY: "scroll",
    overflowX: "hidden",
    height: "calc(100vh - 111px)",
    /* width  */
    "&::-webkit-scrollbar": {
      width: 0,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#3D4690",
      borderRadius: 15,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#303f9f",
    },
    [theme.breakpoints.down("sm")]: {
      /* width  */
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      background: theme.palette.background.paper,
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      height: "calc(100vh - 167px)",
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 18,
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: 66,
      paddingRight: 66,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 166,
      paddingRight: 166,
    },
  },
  root: {
    paddingLeft: 12,
    [theme.breakpoints.only("xs")]: {
      padding: "15px 15px 77px 15px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "20px 40px",
    },
  },
  fullHeight: {
    height: "100%",
  },
});

class Bookmarks extends React.Component {
  render() {
    const { classes } = this.props;
    var user_id = this.context.user?.id
      ? this.context.user.user_profiles[0].id
      : null;
    return (
      <div className={classes.componentRender}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.root}
          spacing={4}
        >
          <Query
            query={FIND_ALL_BOOKMARKS(user_id, this.props.search)}
            //variables={{ user_id: user_id }}
            fetchPolicy={"network-only"}
          >
            {({ data, loading, error }) => {
              if (loading)
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    className={classes.fullHeight}
                  >
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </Grid>
                );
              if (error)
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    className={classes.fullHeight}
                  >
                    <Typography variant="h5">
                      {user_id
                        ? `Some error occurred, please try refresh!`
                        : `Please sign in to see your bookmarks!`}
                    </Typography>
                  </Grid>
                );
              if (data && data.allBookmarks.nodes.length > 0) {
                return data.allBookmarks.nodes.map((data, key) => (
                  <Grid key={key} item xs={6} sm={12} md={12}>
                    <Bookmark data={data} />
                  </Grid>
                ));
              } else {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    className={classes.fullHeight}
                  >
                    <Typography variant="h5">
                      {this.props.search === ""
                        ? "You haven't bookmarked any card yet!"
                        : "No such bookmarked cards found!"}
                    </Typography>
                  </Grid>
                );
              }
            }}
          </Query>
        </Grid>

        <Hidden mdUp>
          <BottomNavbar />
        </Hidden>
      </div>
    );
  }
}
Bookmarks.contextType = AuthContext;
export default withRouter(withStyles(styles)(withApollo(Bookmarks)));
