import React, { Component } from "react";
import {
  withStyles,
  Grid,
  IconButton,
  Typography,
  Hidden,
  //TextField,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import TopicsIcon from "../../icons/topics";
import UserIcon from "../../icons/profile";
import CategoryIcon from "../../icons/subcategory";
import { DashboardTable } from "../../components";
import handler from "./handler";
import { set } from "lodash";
import Routes from "../../router/routes";
import BottomNavbar from "../../components/bottomNavbar";
import FlashCardScreen from "./flashCards";
import { EditContent } from "../../components";
import { findIndex } from "lodash";

const styles = (theme) => ({
  componentRender: {
    paddingLeft: 66,
    paddingRight: 66,
    paddingTop: 0,
    overflowY: "scroll",
    overflowX: "hidden",
    height: "calc(100vh - 111px)",
    /* width  */
    "&::-webkit-scrollbar": {
      width: 0,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#3D4690",
      borderRadius: 15,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#303f9f",
    },
    [theme.breakpoints.down("sm")]: {
      /* width  */
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      background: theme.palette.background.paper,
      borderTopLeftRadius: "38px",
      borderTopRightRadius: "38px",
      height: "calc(100vh - 167px)",
      marginTop: -40,
    },
    [theme.breakpoints.only("md")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 166,
      paddingRight: 166,
    },
  },
  root: {
    padding: "10px 0",
    [theme.breakpoints.only("xs")]: {
      padding: "40px 15px 77px 15px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "20px",
      paddingTop: 40,
    },
  },
  headerContainer: {
    padding: "10px 0px",
    overflowX: "scroll",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
  },
  innerContainer: {
    padding: "20px 20px 10px 0px",
  },
  cardContainer: {
    width: 150,
    height: 70,
    borderRadius: 20,
    backgroundColor: theme.palette.common.white,
    padding: "10px",
    textAlign: "center",
    position: "relative",
    boxShadow: "3px 3px 6px #00000029",
    transition: "background 0.5s, color 0.5s",
    "&.active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: "5px 5px 10px #00000029",
      "& h6": {
        color: theme.palette.common.white,
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  cardHovered: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: "5px 5px 10px #00000029",
      "& h6": {
        color: theme.palette.common.white,
      },
    },
  },
  iconButton: {
    borderRadius: 50,
    backgroundColor: "#fff",
    marginTop: -40,
    "&.MuiIconButton-root": {
      padding: 2,
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  svgIcon: {
    fill: theme.palette.primary.main,
    width: 52,
    height: 52,
  },
  cardContent: {
    paddingTop: 5,
  },
  cardTitle: {
    width: "100%",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
      fontSize: "14px",
    },
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  searchInput: {
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  tableContainer: {
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 20,
    },
  },
  /*Styles for flash card screen!*/
  flashCardsContainer: {
    paddingTop: 10,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 20,
    },
  },
  listTitle: {
    fontSize: "1.1rem",
  },
  listContent: {
    fontSize: "0.8rem",
  },
  updateButton: {
    borderRadius: 8,
    textTransform: "capitalize",
    marginBottom: "5px",
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      infoDetails: [
        {
          id: 1,
          title: "Users",
          icon: <UserIcon className={this.props.classes.svgIcon} />,
          count: 50,
          path: "/admin/users",
        },
        {
          id: 2,
          title: "Categories",
          icon: <CategoryIcon className={this.props.classes.svgIcon} />,
          count: 10,
          path: "/admin/categories",
        },
        {
          id: 3,
          title: "Sub Categories",
          icon: <CategoryIcon className={this.props.classes.svgIcon} />,
          count: 50,
          path: "/admin/sub-categories",
        },
        {
          id: 4,
          title: "Topics",
          icon: <TopicsIcon className={this.props.classes.svgIcon} />,
          count: 50,
          path: "/admin/topics",
        },
        {
          id: 5,
          title: "Flash Cards",
          icon: <TopicsIcon className={this.props.classes.svgIcon} />,
          count: "",
          path: "/admin/flash-cards",
        },
      ],
      rows: [],
      columns: [],
      search: "",
      loading: true,
      open: false,
      item: null,
      type: null,
      key: Math.random(),
    };
  }

  fetchRow = async (offset) => {
    this.setState({
      loading: true,
    });
    var { client, match } = this.props;
    var data = { limit: 5, offset: offset };
    if (match.path !== Routes.Admin.users) {
      data = { first: 5, offset: offset * 5 };
    }
    var newRows = await handler.getRows(client, match.path, data);
    var { rows } = this.state;
    rows.push(...newRows);
    this.setState({
      rows: rows,
      loading: false,
    });
  };
  setRowColumns = async () => {
    var { client, match } = this.props;
    this.setState({
      loading: true,
    });
    var data = { limit: 5, offset: 0 };
    if (match.path !== Routes.Admin.users) {
      data = { first: 5, offset: 0 };
    }
    setTimeout(async () => {
      var rows = await handler.getRows(client, match.path, data);
      var columns = handler.getColumnNames(match.path);
      this.setState({
        ...this.state,
        rows: rows,
        columns: columns,
        loading: false,
      });
    }, 50);
  };
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setRowColumns();
    }
  }
  async componentDidMount() {
    var infoCount = await handler.getInfoDetailsCount();
    var { infoDetails } = this.state;
    set(infoDetails, ["0", "count"], infoCount?.user_count);
    set(infoDetails, ["1", "count"], infoCount?.category_count);
    set(infoDetails, ["2", "count"], infoCount?.sub_category_count);
    set(infoDetails, ["3", "count"], infoCount?.topic_count);
    this.setRowColumns();
    this.setState({
      ...this.state,
      infoDetails: infoDetails,
      loading: false,
      userId: null,
    });
  }
  // Search Filter
  filterHandler = (items) => {
    return items;
  };
  // Switch handler
  switchHandler = (item) => {
    this.setState({
      ...this.state,
      open: true,
      item: item,
      type: "Change status",
    });
  };
  // Edit handler
  hanldeEdit = (item) => {
    this.setState({
      ...this.state,
      open: true,
      item: item,
      type: "Edit",
    });
  };
  handleView = (item) => {
    this.setState({
      ...this.state,
      open: true,
      item: item,
      type: "View",
    });
  };
  //set Item
  setItem = (data) => {
    this.setState({
      item: data,
    });
  };
  //Close Modal
  handleClose = (type) => {
    if (this.props.match.path !== Routes.Admin.flashcards) {
      var { rows } = this.state;
      var index = findIndex(rows, { id: this.state.item?.id });
      if (type !== "Close") {
        if (type === "Remove") rows.splice(index, 1);
        if (type === "Save") rows.splice(index, 1, this.state.item);
        if (type === "Status")
          rows.splice(index, 1, {
            ...this.state.item,
            isActive: !this.state.item?.isActive,
          });
      }
      this.setState({
        rows: rows,
      });
    } else {
      this.child.current.saveFlashcards(type, this.state.item);
    }
    this.setState({
      open: false,
      item: null,
      type: null,
      key: Math.random(),
    });
  };
  // Delete handler
  handleDelete = (item) => {
    this.setState({
      ...this.state,
      open: true,
      item: item,
      type: "Delete",
    });
  };
  render() {
    const { classes, match } = this.props;
    const path = match?.path;
    const { infoDetails, loading, columns, rows } = this.state;
    var count = 0;
    infoDetails.forEach((item) => {
      if (item.path === path) count = parseInt(item.count);
    });
    return (
      <div className={classes.componentRender}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.root}
        >
          {/* Header Card */}
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            className={classes.headerContainer}
          >
            {infoDetails.map((data, index) =>
              !loading ? (
                <div className={classes.innerContainer} key={index}>
                  <div
                    className={`${classes.cardContainer} ${
                      classes.cardHovered
                    } ${path === data.path && "active"}`}
                    onClick={() => {
                      this.props.history.push(data.path);
                    }}
                  >
                    {/* Icon */}
                    <IconButton size="medium" className={classes.iconButton}>
                      {data.icon}
                    </IconButton>
                    <div className={classes.cardContent}>
                      {/* Count */}
                      <Typography
                        className={`${classes.cardTitle} ${classes.primaryText}`}
                        variant="h6"
                      >
                        {data.count ? data.count : <br />}
                      </Typography>
                      {/* Title */}
                      <Typography className={classes.cardTitle} variant="h6">
                        {data.title}
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                // Skeleton
                <div className={classes.innerContainer} key={index}>
                  <div
                    className={classes.cardContainer}
                    style={{ cursor: "pointer" }}
                  >
                    {/* Icon */}
                    <IconButton
                      size="medium"
                      className={classes.iconButton}
                      disableRipple
                    >
                      <Skeleton variant="circle" width={52} height={52} />
                    </IconButton>
                    <Skeleton
                      variant="rect"
                      style={{ marginBottom: 10, borderRadius: 10 }}
                    />
                    <Skeleton variant="rect" style={{ borderRadius: 10 }} />
                  </div>
                </div>
              )
            )}
          </Grid>
          {/* Search 
          <Grid item container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <TextField
                id="standard-search"
                label="Filter"
                type="search"
                className={classes.searchInput}
                value={search}
                onChange={(e) => {
                  if (e.target.value.trim()) {
                    this.setState({ ...this.state, search: e.target.value });
                  }
                }}
              />
            </Grid>
          </Grid>*/}
          {/* Table */}

          <Grid item container direction="row">
            {path === "/admin/flash-cards" ? (
              <FlashCardScreen
                ref={this.child}
                handleEdit={(item) => this.hanldeEdit(item)}
                handleDelete={(item) => this.handleDelete(item)}
                handleView={(item) => this.handleView(item)}
                {...this.props}
              />
            ) : (
              <Grid item xs={12} className={classes.tableContainer}>
                <DashboardTable
                  loading={loading}
                  path={path}
                  count={count}
                  columns={columns}
                  rows={this.filterHandler(rows)}
                  fetchRow={this.fetchRow}
                  onChangeSwitch={(item) => this.switchHandler(item)}
                  onEdit={(item) => this.hanldeEdit(item)}
                  onDelete={(item) => this.handleDelete(item)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Bottom Navigation */}
        <Hidden mdUp>
          <BottomNavbar />
        </Hidden>
        <EditContent
          key={this.state.key}
          open={this.state.open}
          item={this.state.item}
          type={this.state.type}
          setItem={this.setItem}
          handleClose={(type) => this.handleClose(type)}
          {...this.props}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(withApollo(Dashboard)));
