import React from "react";

import {
  AppBar, Hidden, Grid,
  InputBase, IconButton, Drawer,
  Typography, withStyles
} from "@material-ui/core";
import ToolTip from '../Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from "react-router-dom";
import SideMenu from "./SideNavbar";
import ProfileAvatar from '../../components/profileAvatar'
import AddIcon from '@material-ui/icons/Add';
import MenuMainIcon from "../../icons/menu";
import { AuthContext } from '../../contexts';
import { get } from 'lodash'

const styles = (theme) => ({
  appBar: {
    width: "100%",
    height: 95,
    background: theme.palette.background.paper,
    color: theme.palette.common.black,

    paddingLeft: 66,
    paddingRight: 66,
    paddingTop: 27,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.0), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 2px 1px -1px rgba(0, 0, 0, 0)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 5,
    },
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      height: 110,

      background: theme.palette.common.white,

    },
    [theme.breakpoints.only("sm")]: {
      height: 110,
      paddingLeft: 0,
      paddingRight: 0,
      background: theme.palette.common.white,

    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: 40,
      paddingRight: 30,
      height: 79,
      paddingTop: 22,
    },
    [theme.breakpoints.up("lg")]: {
      height: 103,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 166,
      paddingRight: 166
    },
  },
  header: {
    display: "flex",
    paddingLeft: 12,
    [theme.breakpoints.up('xs')]: {

      textAlign: "center",
      display: "contents",
    }
  },
  headerChild: {
    paddingLeft: "12px",
    paddingTop: "10px",
  },
  searchChild: {
    paddingRight: "22px",
    paddingTop: "2px",
    [theme.breakpoints.up('sm')]: {
      paddingRight: "18px",
      paddingLeft: "18px",
      paddingTop: "9px"
    },
    [theme.breakpoints.only('xs')]: {
      paddingRight: "18px",
      paddingLeft: "18px",
      paddingTop: "6px"
    },
    [theme.breakpoints.only('sm')]: {
      paddingRight: "18px",
      paddingLeft: "18px",
      paddingTop: "5px"
    },
  },
  imgContainer: {
    display: "flex",
    textAlign: "center"
  },
  avatar: {
    backgroundColor: theme.palette.background.paper
  },
  icon: {
    color: theme.palette.primary.dark,
    padding: 16
  },
  addBtn: {
    position: "absolute",
    right: 40
  },
  deskTopAddBtn: {
    position: "absolute",
    right: 80
  },
  profilePicContainer: {
    textAlign: "-webkit-right",
    cursor: "pointer",
    paddingRight: 16
  },
  profilePic: {
    "&:hover": {
      boxShadow: "1px 1px 5px black"
    }
  },
  search: {
    position: 'relative',
    borderRadius: "25px",
    height: "38px",
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      //boxShadow: '0 5px 15px rgba(61, 70, 144, .4)',
      // backgroundColor: "#00000008"
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
      backgroundColor: theme.palette.background.paper,

    },
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.background.paper,

    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.hint,
  },
  inputRoot: {
    color: theme.palette.text.hint,
    width: "100%",

  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: theme.palette.common.black,
    paddingTop: 10,
    [theme.breakpoints.up('md')]: {
      width: "250px",
    },
  },
  tagIcon: {
    width: "100%",
    marginTop: "11px",
    height: "28px"
  },
  tagCon: {
    display: "flex",
    justifyContent: "end",
    alignItems: "flex-end",
    color: theme.palette.primary.main
  },
  avatorIcon: {
    height: "20px",
    width: "20px",
    paddingTop: 6

  },
  AvaCont: {
    height: "50px",
    width: "35px",
    paddingLeft: "11px",
    [theme.breakpoints.only('sm')]: {
      paddingLeft: "38px",

    }
  },
  TextName: {
    lineHeight: 1,
    fontWeight: "bold",
    color: theme.palette.common.black,
    [theme.breakpoints.only('xs')]: {
      fontWeight: "bold",
      color: theme.palette.text.secondary,
    }
  },
  createButton: {
    background: theme.palette.primary.main,
    width: 40,
    height: 40,
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.white,
      fontSize: "1.8rem"
    },
    "&:hover": {
      background: theme.palette.primary.main
    }
  },
  menuIcon: {
    width: 28,
    height: 28,
    fill: theme.palette.primary.main
  }
});


class Navbar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.handleSearch('')
    }
  }

  toogleDrawer = e => {
    this.setState({ isOpen: false });
  };

  render() {

    let { classes } = this.props
    let pathname = this.props.location.pathname
    return (
      <AppBar position="static" className={classes.appBar} >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {/* <Hidden smDown> */}
          <Hidden mdUp>
            <Grid item xs={2} className={classes.AvaCont}>
              <IconButton
                aria-label="menu"
                onClick={() => this.setState({ isOpen: true })}
              >
                <MenuMainIcon className={classes.menuIcon} />
              </IconButton>
            </Grid>
          </Hidden>
          <Drawer xs={3} open={this.state.isOpen} onClose={() => this.setState({ isOpen: false })}>
            <SideMenu auth={this.props.auth} toogleDrawer={this.toogleDrawer} />
          </Drawer>
          <Grid item md={3} lg={2} xl={2} xs={7} className={classes.header}>

            <div className={classes.headerChild}>
              <Typography variant="h5" className={classes.TextName} >
                {this.props.title}
                {/* Catagories */}
              </Typography>

            </div>
          </Grid>
          {/* </Hidden> */}
          <Hidden mdUp>
            <Grid container justify="center" item xs={2} md={1} lg={1}>
              <ProfileAvatar />
            </Grid>
          </Hidden>
          {/* <Hidden smDown> */}
          <Grid item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            xs={12} lg={6} md={7} sm={12} className={classes.searchChild}>
            <Grid item xs={12} md={9} lg={9}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder={`Search ${pathname.includes('bookmark') ? 'bookmarks' : 'categories'}`}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  value={this.props.search}
                  type="search"
                  onChange={(event) => this.props.handleSearch(event.target.value)}
                />
              </div>
            </Grid>
            <Hidden smDown>

              {
                get(this.context.user, ['user_profiles', '0', 'workspace_user_profiles', '0', 'role', 'name']) === 'admin' &&
                <ToolTip title='Add'>
                  <IconButton color="primary"
                    className={classes.createButton}
                    onClick={() => this.props.history.push("/admin/create")}>
                    <AddIcon />
                  </IconButton>
                </ToolTip>
              }

              <ProfileAvatar />
            </Hidden>
          </Grid>
          {/* </Hidden> */}
        </Grid>
      </AppBar >
    )
  }
}
Navbar.contextType = AuthContext
export default withRouter(withStyles((styles))(Navbar));
