import React from "react";
import { withApollo } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// import {ThemeContext} from '../../contexts';
//import ThemeColor from '../../icons/themeColor';
import { Typography, Hidden, Breadcrumbs, Link } from "@material-ui/core";
import { withRouter, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ProfileAvatar from '../profileAvatar';

const useStyles = makeStyles(theme => ({
    appBar: {
        width: "100%",
        height: 95,
        background: theme.palette.background.paper,
        color: theme.palette.common.black,
        paddingLeft: 165,
        paddingRight: 145,
        paddingTop: 32,
        boxShadow:
            "0px 1px 3px 0px rgba(0, 0, 0, 0.0), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 2px 1px -1px rgba(0, 0, 0, 0)",
        [theme.breakpoints.down("sm")]: {
            paddingTop: 2,
        },
        [theme.breakpoints.only("xs")]: {
            paddingLeft: 0,
            paddingRight: 0,
            position: "absolute",

            paddingTop: 0,
            height: "92px",
            background: theme.palette.primary.main,


        },
        [theme.breakpoints.only("sm")]: {
            height: "14vh",
            position: "fixed",
            paddingLeft: 50,
            paddingRight: 60,
            background: theme.palette.primary.main,

        },
        [theme.breakpoints.only("md")]: {
            paddingLeft: 40,
            paddingRight: 17,
            width: "100%",
            borderTopLeftRadius: "38px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "100%",
            borderTopLeftRadius: "38px",
        }
    },
    header: {
        display: "flex",
        paddingLeft: 12,
        [theme.breakpoints.up('xs')]: {

            textAlign: "center",
            display: "contents",
        }
    },
    headerChild: {
        paddingLeft: "11px",
        paddingTop: "38px",
        [theme.breakpoints.only('lg')]: {
            paddingLeft: "13px",
            paddingTop: "0px",

        },
        [theme.breakpoints.only('md')]: {
            paddingLeft: "13px",
            paddingTop: "0px",

        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: "8px",
            paddingTop: "54px",

        }

    },
    arrowIcon: {
        paddingLeft: "18px",
        paddingTop: "36px",
        fill: "#FFD447FC",
        [theme.breakpoints.only('lg')]: {
            paddingLeft: "45px",

        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: "0px",
            paddingTop: "50px",


        }

    },
    TextName: {
        lineHeight: 1,
        fontWeight: "bold",
        display: "flex",
        color: theme.palette.common.black,
        [theme.breakpoints.only('xs')]: {
            fontWeight: "bold",
            paddingBottom: 6,
            fontSize: 19,
            color: theme.palette.common.white,
        },
        [theme.breakpoints.only('sm')]: {
            fontWeight: "bold",
            paddingBottom: 6,
            color: theme.palette.common.white,
        }
    },
    searchChild: {
        paddingRight: "22px",
        paddingTop: "2px",
        [theme.breakpoints.up('xs')]: {
            // paddingRight: "18px",
            paddingLeft: "18px",
            paddingTop: "5px"
        },
        [theme.breakpoints.up('lg')]: {
            paddingRight: "5px",

        }
    },
    search: {
        position: 'relative',
        borderRadius: "25px",
        height: "38px",
        display: "flex",
        float: "right",
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        // marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        [theme.breakpoints.only('sm')]: {
            backgroundColor: theme.palette.background.paper,

        },
        [theme.breakpoints.only('xs')]: {
            backgroundColor: theme.palette.background.paper,

        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.hint,
    },
    inputRoot: {
        color: theme.palette.text.hint,
        width: "100%",

    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        color: theme.palette.common.black,
        paddingTop: 10,
        [theme.breakpoints.up('md')]: {
            width: "250px",
        },
    },
    subTitle: {
        marginTop:"5px",
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    themeIcon: {
        width: 43,
        height: 43,
        fill: "#fff",
        paddingTop: 6,
        [theme.breakpoints.between("xs", 'sm')]: {
            fill: theme.palette.primary.main,
            width: 36,
            height: 36,
        },
    },
    topCont: {
        width: "100%",
        [theme.breakpoints.only('md')]: {
            width: "90%"
        },
        [theme.breakpoints.only('lg')]: {
            width: "97%"
        },
    },
    ThemeCont: {
        width: 49,
        height: 47,
        backgroundColor: theme.palette.primary.main,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        borderRadius: "50%",
        [theme.breakpoints.between("xs", 'sm')]: {
            backgroundColor: theme.palette.common.white,
            width: 40,
            height: 39,
        },
    },
    theme: {
        [theme.breakpoints.only("xs")]: {
            paddingTop: 27,
            paddingLeft: 0

        },
        [theme.breakpoints.only("sm")]: {
            paddingTop: "47px",
            justifyContent: "flex-end",
        },

    },
    triangle: {
        width: "0",
        height: "0",
        borderLeft: "7px solid transparent",
        borderRight: "7px solid transparent",
        borderBottom: "15px solid white",
        right: "16%",
        top: "-1px",
        position: "absolute",
        [theme.breakpoints.only("sm")]: {
            right: "7%",
        },
        [theme.breakpoints.only("md")]: {
            right: "16%",
        },
        [theme.breakpoints.only("lg")]: {
            right: "16%",
        },
    },
    colorBox: {
        width: 284,
        height: 43,
        position: "absolute",
        backgroundColor: "white",
        top: "20%",
        right: "9%",
        zIndex: 10000,
        borderRadius: 10,
        boxShadow: "0px 3px 6px #00000029",
        [theme.breakpoints.only("sm")]: {
            right: "4%",
            top: "15%",
        },
        [theme.breakpoints.only("md")]: {
            right: "13%",
            top: "24%",

        },
        [theme.breakpoints.only("lg")]: {
            right: "13%",
            top: "24%",

        },
    },
    colorCont: {
        width: 22,
        height: 22,
        borderRadius: 5,

    },
    manyColor: {
        position: "relative",
        width: "100%",
        height: "100%",
    },
    MainCont: {
        height: "92%",
        position: "fixed",
    },
    avatar: {
        marginTop: 27,
        position: "relative",
        [theme.breakpoints.between("md", "lg")]: {
            marginTop: 0,
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: 33,
        }
    }
}));

function capitalize_Words(str) {
    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

const ListTopbar = props => {
    const classes = useStyles();
    // const theme =React.useContext(ThemeContext);
    //let [color, OpenColor] = React.useState(false); currently not using this feature
    /*let colorBoxFuction = () => {
        OpenColor(!color)
    }*/

    let { categoryId, cardId } = useParams()

    /*function fillColor(data) {
        // alert(data)
        props.fillColorBg(data)
    }*/
    return (
        <Grid container className={classes.MainCont}>
            <AppBar position="static"
                style={{ backgroundColor: props.color === "" ? classes.appBar : props.color }}
                className={classes.appBar}
            >
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.topCont}
                >
                    {/* Arrow icon */}
                    <Hidden mdUp>
                        <Grid item onClick={() => window.history.back()}>
                            <ArrowBackIosIcon className={classes.arrowIcon} />
                        </Grid>
                    </Hidden>


                    <Grid item direction="row"
                        justify="space-between"
                        //alignItems="center"
                        container
                        md={12} lg={12} sm={12} xl={2} xs={12} className={classes.header}>

                        <Grid item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            lg={9} md={9} sm={10} xs={8}
                            className={classes.headerChild}>
                            <Grid item xs={12}>
                                {/* Title */}
                                <Typography variant="h5" className={classes.TextName} >
                                    {capitalize_Words(cardId.replace(/-/g, " "))}
                                    {/* Catagories */}
                                </Typography>
                            </Grid>
                            <Hidden smDown>
                                <Grid item>
                                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} className={classes.subTitle}>
                                        <Link key="categories" color="inherit" href="/">Categories</Link>
                                        <Link key="topic" color="inherit" href={`/${categoryId}`}>{capitalize_Words(categoryId.replace(/-/g, " "))}</Link>
                                        <Typography color="textPrimary">{capitalize_Words(cardId.replace(/-/g, " "))}</Typography>
                                    </Breadcrumbs>
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Hidden smDown>
                            <Grid item className={classes.avatar}>
                                <ProfileAvatar />
                            </Grid>
                        </Hidden>
                        {/*<Grid item  //currently not used!
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={classes.theme}
                            // onClick={() =>{
                            //     theme.setTheme({...theme,Darktheme:!theme.Darktheme})
                            // }}
                            onClick={() => colorBoxFuction()}
                            lg={2} md={2} sm={3} xs={3}>
                             Theme color change icon 
                            <Grid item className={classes.ThemeCont}>
                                <ThemeColor className={classes.themeIcon} />
                            </Grid>*/}

                        {/*color === true &&
                            <Grid className={classes.manyColor}>
                                <Grid className={classes.triangle}>

                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    className={classes.colorBox}>
                                    <Grid item onClick={() => fillColor("#0810A6")} style={{ backgroundColor: "#0810A6" }} className={classes.colorCont}>

                                    </Grid>
                                    <Grid item onClick={() => fillColor("#65A653")} style={{ backgroundColor: "#65A653" }} className={classes.colorCont}>

                                    </Grid>
                                    <Grid item onClick={() => fillColor("#F20505")} style={{ backgroundColor: "#F20505" }} className={classes.colorCont}>

                                    </Grid>
                                    <Grid onClick={() => fillColor("#EEFF04")} style={{ backgroundColor: "#EEFF04" }} item className={classes.colorCont}>

                                    </Grid>
                                    <Grid onClick={() => fillColor("#FFA201")} item style={{ backgroundColor: "#FFA201" }} className={classes.colorCont}>

                                    </Grid>
                                    <Grid item onClick={() => fillColor("#FF01EE")} style={{ backgroundColor: "#FF01EE" }} className={classes.colorCont}>

                                    </Grid>
                                    <Grid item onClick={() => fillColor("#C501FF")} style={{ backgroundColor: "#C501FF" }} className={classes.colorCont}>

                                    </Grid>
                                </Grid>
                            </Grid>
                        */}
                    </Grid>
                </Grid>
            </AppBar>
        </Grid>
    );
};

export default withRouter(withApollo(ListTopbar));
