import React from 'react';
import { withRouter, NavLink } from "react-router-dom";
import { withStyles, Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from "@material-ui/core";
import { HomeIcon, BookmarkIcon } from '../../icons';
import { AuthContext, ModalContext } from './../../contexts';
import routes from '../../router/routes'
import { get } from 'lodash'

const styles = (theme) => ({
    bottomContainer: {
        height: 60,
        bottom: 0,
        position: "fixed",
        zIndex: 10000,
        width: "100%",
        borderRadius: 18,
        backgroundColor: theme.palette.common.white
    },
    innerContainer: {
        height: "100%",
        width: "100%",
        textAlign: "center",
        position: "relative"
    },
    linkStyle: {
        textDecoration: "none",
        color: theme.palette.common.white
    },
    isActiveLink: {
        "& button": {
            color: theme.palette.primary.main,
            background: theme.palette.background.paper,
            "&:hover": {
                color: theme.palette.primary.main,
                background: theme.palette.background.paper,
            }
        },
        "& svg": {
            fill: theme.palette.primary.main
        }
    },
    button: {
        color: "#9297A5",
        fontSize: 16,
        fontWeight: "500",
        textTransform: "capitalize",
        borderRadius: 10,
        padding: "4px 12px"
    },
    footerIcon: {
        width: 32,
        height: 32,
        fill: "#9297A5"
    },
    createIcon: {
        position: "absolute",
        top: "0%",
        left: "50%",
        transform: "translate(-50%,-50%)"
    },
    createButton: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        background: theme.palette.primary.main,
        color: "#fff",
        width: 50,
        height: 50,
        "& .MuiSvgIcon-root": {
            fontSize: "1.3em"
        },
        [theme.breakpoints.only("sm")]: {
            width: 55,
            height: 55,
            "& .MuiSvgIcon-root": {
                fontSize: "1.5em"
            }
        }
    },
    createContainer: {
        width: 65,
        height: 65,
        position: "relative",
        background: theme.palette.common.white,
        borderRadius: "50%",
        [theme.breakpoints.only("sm")]: {
            width: 70,
            height: 70
        }
    },
})


class BottomNavbar extends React.Component {

    handleClick = (event, props) => {
        if (!props?.email) {
            this.context.setModal({
                open: true
            })
        } else {
            this.setState({ anchorEl: event.currentTarget });
        }
    }

    handleClick = (Login) => {
        if (!this.context.user?.email) {
            Login.setModal({
                open: true,
                cb: () => {
                    this.props.history.push(routes.Bookmark)
                }
            })
        } else {
            this.props.history.push(routes.Bookmark)
        }
    }
    render() {
        const { classes } = this.props;
        const role = get(this.context.user, ['user_profiles', '0', 'workspace_user_profiles', '0', 'role', 'name'])
        return (
            <div className={classes.bottomContainer}>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center" className={classes.innerContainer}>
                    <Grid item xs={6} style={{ borderRight: "2px solid #EEF3F9" }}>
                        <NavLink to="/" exact className={classes.linkStyle} activeClassName={classes.isActiveLink}>
                            <Button
                                variant="text"
                                className={classes.button}
                                startIcon={<HomeIcon className={classes.footerIcon} />}
                            >
                                Home
                        </Button>
                        </NavLink>
                    </Grid>
                    <ModalContext.Consumer>
                        {Login =>
                            <Grid item xs={6} onClick={() => this.handleClick(Login)}>
                                <NavLink
                                    to="/bookmark"
                                    exact className={classes.linkStyle} activeClassName={classes.isActiveLink}>
                                    <Button
                                        variant="text"
                                        className={classes.button}

                                        startIcon={<BookmarkIcon className={classes.footerIcon} />}
                                    >
                                        Bookmark
                                    </Button>
                                </NavLink>
                            </Grid>
                        }
                    </ModalContext.Consumer>
                    {
                        role === 'admin' &&
                        <Grid item
                            xs={3} className={classes.createIcon}>
                            <div className={classes.createContainer}>
                                <IconButton
                                    className={classes.createButton}
                                    onClick={() => this.props.history.push("/admin/create")}                                >
                                    < AddIcon />
                                </IconButton>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>
        )
    }
}
BottomNavbar.contextType = AuthContext
export default withRouter(withStyles((styles))(BottomNavbar));
