import React from 'react';
import {
    Tooltip, makeStyles
} from '@material-ui/core';


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.white,
    },
    tooltip: {
        fontWeight: 'lighter',
        fontSize: '13px',
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
    },
}));

function ToolTip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

export default ToolTip;