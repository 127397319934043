import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="Group_117" transform="translate(0.994)">
            <path id="Path_94" d="M40,21.7L27.4,9l0,0c-1.4-1.2-3.4-1.2-4.8,0l-0.1,0L9.8,21.6c-0.4,0.5-0.4,1.3,0.1,1.7c0.5,0.4,1.1,0.4,1.6,0
       l1-1v10.4c0,2.7,2.2,4.8,4.8,4.8h4.3c0.7,0,1.2-0.5,1.2-1.2l0,0v-9.8H27v9.8c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.2
       c2.7,0,4.8-2.2,4.8-4.8c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2c0,0,0,0,0,0.1c0,1.3-1.1,2.4-2.4,2.4h-3v-9.8
       c0-0.7-0.5-1.2-1.2-1.2c0,0,0,0,0,0h-6.6c-0.7,0-1.2,0.5-1.2,1.2l0,0v9.8h-3.1c-1.3,0-2.4-1.1-2.4-2.4V20c0,0,0,0,0,0l9.3-9.3
       c0.5-0.4,1.1-0.4,1.6,0l9.2,9.2v6.8c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2c0,0,0,0,0-0.1v-4.4l1,1c0.5,0.5,1.2,0.4,1.7-0.1
       C40.4,22.8,40.4,22.1,40,21.7L40,21.7z"/>
        </g>
    </svg>
);
export default SVG;
