import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="data" transform="translate(0 -85.333)">
            <g id="Group_47" transform="translate(0 85.333)">
                <path id="Path_92" d="M37.6,20.7C36.2,13.4,29,8.6,21.7,10.1c-4,0.8-7.5,3.4-9.4,7c-5.9,0.6-10.2,6-9.6,11.9
           c0.6,5.5,5.2,9.7,10.8,9.7H37c5,0,9-4,9-9C46,25,42.3,21,37.6,20.7z M27.9,26.1v7.2h-7.2v-7.2h-5.4l9-9l9,9L27.9,26.1L27.9,26.1z"
                />
            </g>
        </g>
    </svg>
);
export default SVG;
