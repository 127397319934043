import React from "react";
const SVG = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className || ""}`}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 50 50">
        <g id="tag" transform="translate(0.886 134.811)">
            <g id="Group_119" transform="translate(0 0)">
                <path id="Path_110" d="M11.9-126c1.2,0,2.4,0.3,3.6,0.7c2.1,0.8,3.8,2.3,4.8,4.4l5.6-0.6c1.5-0.2,3,0.4,4.1,1.5l9.6,9.6
           c2,2,2,5.2,0,7.2l-8.4,8.4c-1,1-2.3,1.5-3.6,1.5c-1.3,0-2.6-0.5-3.6-1.5c-0.5-0.5-0.6-1.3-0.1-1.8c0.5-0.5,1.3-0.6,1.8-0.1
           c0,0,0.1,0.1,0.1,0.1c1,1,2.6,1,3.6,0l8.4-8.4c1-1,1-2.6,0-3.6l-9.6-9.6c-0.5-0.5-1.3-0.8-2.1-0.7l-6.5,0.7l-0.1,0l-3.2,0.3
           l-0.2,1.9c1.4,0.5,2.9,0.8,4.5,1c0.7,0,1.2,0.7,1.2,1.4c0,0.7-0.6,1.2-1.3,1.2h-0.1c-1.6-0.1-3.1-0.4-4.6-0.9l-0.5,5.1
           c-0.1,0.8,0.2,1.5,0.7,2.1l4.9,4.8c0.4,0.5,0.4,1.3-0.2,1.8c-0.5,0.4-1.1,0.4-1.6,0l-4.9-4.8l0,0c-1.1-1.1-1.6-2.6-1.5-4.1
           l0.6-5.9c-3.1-1.4-5.1-4.6-5.1-8v-0.1C8.4-124.4,10-126,11.9-126z M11-122.4L11-122.4c0,2.1,1,4.1,2.8,5.2l0.2-2
           c0.1-0.6,0.5-1.1,1.1-1.1l2.4-0.2c-0.7-1-1.7-1.8-2.9-2.2c-0.9-0.3-1.8-0.5-2.7-0.6C11.4-123.4,11-123,11-122.4L11-122.4z"/>
            </g>
        </g>
    </svg>
);
export default SVG;
