import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    "& .MuiTableCell-head": {
      fontWeight: "bold",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

function DashboardTableHead(props) {
  const classes = useStyles();
  const { columns } = props;
  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {/* Index */}
        <TableCell align="center">S No</TableCell>
        {columns.map((column, index) => (
          <TableCell key={index} align={column.align}>
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default DashboardTableHead;
