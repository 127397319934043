import React from "react";
import { Grid, Dialog, Hidden } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { AuthContext, ModalContext } from "./../../contexts";
import withStyles from "@material-ui/core/styles/withStyles";
import Routes from "../../router/routes";
//import { cardDetails } from '../flashcard/CardDetails';
import {
  BlankPage,
  Categories,
  TopicsList,
  FlashCard,
  Create,
  Bookmark,
  Dashboard,
} from "../";
import SubCategories from "./../SubCategories/index";
import {
  Navbar,
  SideMenu,
  SubTopbar,
  //ListTopbar,
  Login,
  CreateTopbar,
} from "../../components";

const useStyles = (theme) => ({
  desktopSidebarContainer: {
    width: "100%",
  },
  desktopSidebar: {
    width: 300,
    [theme.breakpoints.only("xl")]: {
      width: 430,
    },
  },
  componentContainer: {
    width: "85%",
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
    overflowY: "auto",
    /* width  */
    "&::-webkit-scrollbar": {
      width: 0,
      display: "none",
    },
    borderTopLeftRadius: "38px",
    borderBottomLeftRadius: "38px",
    position: "absolute",
    display: "flex",
    zIndex: 1000,
    marginLeft: "15%",
    background: `#EEF3F9 0% 0% no-repeat padding-box`,
    [theme.breakpoints.between("xs", "sm")]: {
      height: "calc(100vh - 56px)",
      width: "100%",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      marginLeft: "0px",
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: "19%",
      width: "81%",
    },
    [theme.breakpoints.down("sm")]: {
      /* width  */
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
  },
});

function capitalize_Words(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "",
      search: "",
      key: Math.random(),
    };
  }

  fillColorBg = (data) => {
    this.setState({ color: data });
  };

  giveMeComponentToRender = (auth) => {
    // Category Page
    if (this.props.match.path === Routes.Home) {
      return <Categories search={this.state.search} />;
    }
    // Content Page
    if (this.props.match.path === Routes.Content) {
      return (
        <SubCategories
          search={this.state.search}
          handleSearch={(data) => this.setState({ search: data })}
        />
      );
    }
    //Topic Lists
    if (this.props.match.path === Routes.TopicList) {
      return <TopicsList search={this.state.topicSearch} />;
    }
    //Flash Cards
    if (Routes.Card.indexOf(this.props.match.path) > -1) {
      return <FlashCard color={this.state.color} />;
    }

    //Bookmarks
    if (this.props.match.path === Routes.Bookmark) {
      return (
        <Bookmark
          search={this.state.search}
          handleSearch={(data) => this.setState({ search: data })}
        />
      );
    }

    // Dashboard

    if (
      this.props.match.path === Routes.Admin.users ||
      this.props.match.path === Routes.Admin.categories ||
      this.props.match.path === Routes.Admin.subcategories ||
      this.props.match.path === Routes.Admin.topics ||
      this.props.match.path === Routes.Admin.flashcards
    ) {
      return <Dashboard />;
    }

    //Create
    else if (this.props.match.path === Routes.Create) {
      return <Create />;
    }
    //Edit
    //else if (this.props.match.path === Routes.Edit) {
    // return <Categories search={this.state.search} preview={true} editable={true} />;
    //}
    else if (this.props.match.path === Routes.BlankPage) {
      return <BlankPage />;
    }
  };

  giveMeComponentToRenderTopBar = (auth) => {
    var title;
    if (
      this.props.match.path === Routes.Home ||
      this.props.match.path === Routes.Bookmark
    ) {
      title =
        this.props.match.path === Routes.Home
          ? "/categories"
          : this.props.match.path;
      return (
        <Navbar
          title={capitalize_Words(title.replace(/\//g, " "))}
          search={this.state.search}
          handleSearch={(data) =>
            this.setState({ search: data, topicSearch: "" })
          }
        />
      );
    }
    if (
      this.props.match.path === Routes.Content ||
      this.props.match.path === Routes.TopicList ||
      Routes.Card.indexOf(this.props.match.path) > -1
    ) {
      return (
        <SubTopbar
          search={this.state.search}
          handleSearch={(data) =>
            this.setState({ search: data, topicSearch: "" })
          }
        />
      );
    }
    // Create and edit router
    else if (
      this.props.match.path === Routes.Create ||
      this.props.match.path === Routes.Admin.users ||
      this.props.match.path === Routes.Admin.categories ||
      this.props.match.path === Routes.Admin.subcategories ||
      this.props.match.path === Routes.Admin.topics ||
      this.props.match.path === Routes.Admin.flashcards
    ) {
      const isCreate = this.props.match.path === Routes.Create;
      title = isCreate
        ? "/create"
        : this.props.match.path.replace("/admin/", "").replace(/-/g, " ");
      return (
        <CreateTopbar
          title={capitalize_Words(title.replace("/", ""))}
          hasCreate={!isCreate}
        />
      );
    }
  };

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          container
          className={classes.desktopSidebarContainer}
        >
          <Hidden smDown>
            <Grid item className={classes.desktopSidebar}>
              <SideMenu
                auth={this.context}
                toogleDrawer={this.toogleDrawer}
                //key={this.state.key}
              />
            </Grid>
          </Hidden>

          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.componentContainer}
          >
            <Grid item lg={12} md={12} xs={12} sm={12}>
              {this.giveMeComponentToRenderTopBar()}
            </Grid>

            <Grid item lg={12} md={12} xs={12}>
              {this.giveMeComponentToRender(this.context)}
            </Grid>
          </Grid>
        </Grid>
        <ModalContext.Consumer>
          {(Modal) => (
            <Dialog
              open={
                Modal.open /*localStorage.getItem('auth_token') === null ||*/ ||
                //((_.get(this.context.user, ['user_profiles', '0', 'workspace_user_profiles', '0', 'role', 'name']) !== 'admin') && this.props.match.path === Routes.Admin) ||
                (!this.context.user?.id &&
                  this.props.location.state?.resetted === true)
              }
              maxWidth="lg"
              disableBackdropClick={true}
              disableEscapeKeyDown={true}
              PaperComponent={Grid}
              onClose={Modal.handleClose}
              onExit={() => {
                this.setState({ key: Math.random() });
              }}
              id={"login-from"}
              aria-labelledby="login-form"
            >
              <Login onClose={Modal.handleClose} cb={Modal.cb} />
            </Dialog>
          )}
        </ModalContext.Consumer>
      </Grid>
    );
  }
}

Home.contextType = AuthContext;

export default withRouter(withStyles(useStyles)(Home));
