import React from "react";

export const ThemeContext = React.createContext({
  Darktheme: false,
  setTheme: () => null,
});

export const AuthContext = React.createContext({
  user: {},
  setAuth: () => null,
});

export const ModalContext = React.createContext({
  Modal: {
    open: false,
    cb: null,
  },
  setModal: () => null,
});

export const SnackBarContext = React.createContext({
  open: false,
  type: "",
  msg: "",
  vertical: "top",
  horizontal: "center",
  onclose: () => null,
  setSnack: () => null,
});

export const SubTitleBarContext = React.createContext({
  category: {},
  subcategory: {},
  topic: {},
  setSubTitle: () => {},
});
