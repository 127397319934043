import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Slide,
  DialogContentText,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { MainCards, SubCards, SubCategoryCard } from "../";
import config from "../../config";
import Routes from "../../router/routes";
import { mutation } from "../../graphql/mutations";
import { uploadPhoto, removePhoto } from "../../services";
import { SnackBarContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  categoryPreview: {
    border: "2px dashed #B9BEC9",
    borderRadius: 8,
    padding: "10px 20px",
    background: "#F9FBFE",
    [theme.breakpoints.down("sm")]: {
      background: theme.palette.background.paper,
    },
  },
  inputStyle: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  previewCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    wordBreak: "break-word",
  },
  previewImage: (props) => ({
    width:
      props.type === "Category"
        ? 200
        : props.type === "Sub Category"
        ? 160
        : props.type === "Topics"
        ? "100%"
        : "100%",
    padding: props.type === "Topics" ? "15px 0" : "10px 0",
    [theme.breakpoints.only("xs")]: {
      width:
        props.type === "Category"
          ? 200
          : props.type === "Sub Category"
          ? 160
          : props.type === "Topics"
          ? "100%"
          : "100%",
    },
  }),
  uploadImage: {
    width: 100,
    height: 100,
    fill: "#DBDFEA",
  },
  uploadButton: {
    borderRadius: 8,
    textTransform: "capitalize",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditContent(props) {
  const [image, setImage] = React.useState(null);
  const [isChanged, setIsChanged] = React.useState(false);
  const [prevImageURL, setPrevImageURL] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const snack = React.useContext(SnackBarContext);
  const getType = () => {
    var { path } = props.match;
    if (path === Routes.Admin.categories) return "Category";
    if (path === Routes.Admin.subcategories) return "Sub Category";
    if (path === Routes.Admin.topics) return "Topics";
    if (path === Routes.Admin.flashcards) return "Card";
  };
  const Update = () => {
    /* function to update content */
    var { item, client } = props;
    let filename =
      item?.id +
      "_" +
      image?.name.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "-");
    setLoading(true);
    if (image !== null && getType() !== "Card") {
      try {
        let bodyFormData = new FormData();
        bodyFormData.set("name", filename);
        bodyFormData.set("folder", getType().toLowerCase().replace(/ /g, "_"));
        bodyFormData.set("file", image);
        uploadPhoto(bodyFormData).then((res) => {
          console.log(res.data.path);
        });
        removePhoto(prevImageURL).then((res) => {
          if (res?.status) {
            console.log("removed previous image!");
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    var values = {
      ...item,
      urlParam: item?.name
        ? `${
            item?.name
              .replace(/[^a-zA-Z0-9 ]/g, "")
              .trim()
              .replace(/ /g, "-") +
            "-" +
            item?.id.split("-")[0]
          }`.toLowerCase()
        : `${
            item?.title
              .replace(/[^a-zA-Z0-9 ]/g, "")
              .trim()
              .replace(/ /g, "-") +
            "-" +
            item?.id.split("-")[0]
          }`.toLowerCase(),
      imageUrl: image
        ? getType().toLowerCase().replace(/ /g, "_") + "/" + filename
        : item?.imageUrl,
      updatedAt: new Date(),
    };
    if (item?.type) {
      delete values.imageUrl;
      values = {
        ...values,
        created_at: new Date(),
      };
    }
    client
      .mutate({
        mutation: mutation[getType().toLowerCase().replace(/ /g, "_")]?.update,
        variables: values,
      })
      .then((res) => {
        snack.setSnack({
          ...snack,
          open: true,
          type: "success",
          msg: `Successfully ${
            item?.type ? "created" : "updated"
          } ${getType().toLowerCase()}!`,
          vertical: "bottom",
        });
      })
      .catch((err) => {
        snack.setSnack({
          ...snack,
          open: true,
          type: "error",
          msg: "Something went wrong, please check again!",
          vertical: "bottom",
        });
      });
    props.handleClose("Save");
    setLoading(false);
  };

  const Delete = () => {
    /* function to delete or deactivate content */
    var { item, client } = props;
    setLoading(true);
    if (props.type === "Delete") {
      client
        .mutate({
          mutation:
            mutation[getType().toLowerCase().replace(/ /g, "_")]?.delete,
          variables: {
            id: item?.id,
          },
        })
        .then((res) => {
          removePhoto(item?.imageUrl).then((res) => {
            if (res?.status) {
              console.log("removed previous image!");
            }
          });
          snack.setSnack({
            ...snack,
            open: true,
            type: "success",
            msg: "Successfully deleted " + getType().toLowerCase() + " !",
            vertical: "bottom",
          });
          props.handleClose("Remove");
        })
        .catch((err) => {
          console.log(err);
          snack.setSnack({
            ...snack,
            open: true,
            type: "error",
            msg: "Something went wrong, please check again!",
            vertical: "bottom",
          });
        });
    } else {
      client
        .mutate({
          mutation:
            mutation[getType().toLowerCase().replace(/ /g, "_")]?.update,
          variables: {
            id: item?.id,
            isActive: !item?.isActive,
            updatedAt: new Date(),
          },
        })
        .then((res) => {
          snack.setSnack({
            ...snack,
            open: true,
            type: "success",
            msg: `${`Successfully ${
              item?.isActive ? "deactivated" : "activated"
            } ${getType().toLowerCase()}!`}`,
            vertical: "bottom",
          });
          props.handleClose("Status");
        })
        .catch((err) => {
          snack.setSnack({
            ...snack,
            open: true,
            type: "error",
            msg: "Something went wrong, please check again!",
            vertical: "bottom",
          });
        });
    }
    setLoading(false);
  };
  var { item } = props;
  const classes = useStyles({ type: getType() });
  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={() => props.handleClose("Close")}
        aria-labelledby="edit-content"
      >
        <DialogTitle id="edit-content">
          {`${item?.type ? "New " + getType() : props.type}`}
        </DialogTitle>
        <DialogContent dividers>
          {(props.type === "Edit" || props.type === "View") && (
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              {getType() !== "Card" && (
                <>
                  <Grid item xs={12} className={classes.inputStyle}>
                    <TextField
                      autoFocus
                      variant="outlined"
                      id="name"
                      value={item?.name || item?.title || ""}
                      onChange={(event) => {
                        if (item.hasOwnProperty("name")) {
                          props.setItem({
                            ...item,
                            name: event.target.value,
                          });
                        }
                        if (item.hasOwnProperty("title")) {
                          props.setItem({
                            ...item,
                            title: event.target.value,
                          });
                        }
                        setIsChanged(true);
                      }}
                      label={getType() === "Category" ? "Name" : "Title"}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.categoryPreview}>
                      <Typography variant="body2" color="primary">
                        Preview
                      </Typography>
                      <div className={classes.previewCard}>
                        <div className={classes.previewImage}>
                          {getType() === "Category" && (
                            <MainCards
                              preview={true}
                              data={{
                                ...item,
                                imageUrl: image
                                  ? URL.createObjectURL(image)
                                  : config.url + "/" + item?.imageUrl,
                              }}
                            />
                          )}
                          {getType() === "Sub Category" && (
                            <SubCategoryCard
                              preview={true}
                              card={{
                                ...item,
                                path: image
                                  ? URL.createObjectURL(image)
                                  : config.url + "/" + item?.imageUrl,
                                createdAt: new Date().toISOString(),
                              }}
                            />
                          )}
                          {getType() === "Topics" && (
                            <SubCards
                              preview={true}
                              card={{
                                ...item,
                                path: image
                                  ? URL.createObjectURL(image)
                                  : config.url + "/" + item?.imageUrl,
                                createdAt: new Date().toISOString(),
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <label htmlFor="upload-photo">
                        <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          onChange={(event) => {
                            const files = event.target.files;
                            if (files) {
                              setPrevImageURL(item?.imageUrl);
                              setImage(files[0]);
                            }
                          }}
                        />
                        <Button
                          color="primary"
                          fullWidth
                          variant="contained"
                          component="span"
                          className={classes.uploadButton}
                          disableElevation
                        >
                          {!isChanged ? "Change Image" : "Upload Image"}
                        </Button>
                      </label>
                      {image && (
                        <Button
                          color="primary"
                          fullWidth
                          variant="text"
                          component="span"
                          className={classes.uploadButton}
                          disableElevation
                          onClick={() => setImage(null)}
                        >
                          Remove Image
                        </Button>
                      )}
                    </div>
                  </Grid>
                </>
              )}
              {getType() === "Card" && (
                <>
                  <Grid item xs={12} className={classes.inputStyle}>
                    <TextField
                      autoFocus
                      variant="outlined"
                      id="title"
                      value={item?.title}
                      onChange={(event) => {
                        props.setItem({
                          ...item,
                          title: event.target.value,
                        });
                        setIsChanged(true);
                      }}
                      label={"Title"}
                      disabled={props.type === "View"}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.inputStyle}>
                    <TextField
                      autoFocus
                      variant={"outlined"}
                      multiline
                      value={item?.content}
                      onChange={(event) => {
                        props.setItem({
                          ...item,
                          content: event.target.value,
                        });
                        setIsChanged(true);
                      }}
                      label={"Content"}
                      disabled={props.type === "View"}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
          {props.type !== "Edit" && props.type !== "View" && (
            <DialogContentText>
              {`Are you sure? ${
                props.type === "Delete"
                  ? `This will permanently remove ${getType()?.toLowerCase()} "${
                      item?.name || item?.title || ""
                    }" from server.`
                  : `This will ${
                      item?.isActive ? "deactivate" : "activate"
                    } ${getType()?.toLowerCase()} "${
                      item?.name || item?.title || ""
                    }".`
              }`}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {isLoading && <CircularProgress size={36} color="primary" />}
          {props.type === "Edit" && (
            <Button
              onClick={Update}
              disabled={!isChanged && !image}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          )}
          {props.type !== "Edit" && props.type !== "View" && (
            <Button onClick={Delete} variant="contained" color="primary">
              yes
            </Button>
          )}
          <Button onClick={() => props.handleClose("Close")} color="primary">
            {`${
              props.type === "Edit"
                ? "cancel"
                : props.type === "View"
                ? "Close"
                : "no"
            }`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default EditContent;
