import React from "react";
const SVG = ({ width, height, fill, className }) => (
	<svg version="1.1"
		width={width}
		height={height}
		className={`svg-icon ${className || ""}`}
		id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 890.6 350.6" enableBackground="new 0 0 890.6 350.6">
		<g>
			<g>
				<path d="M35.8,208.4V28.5h99.6c18.4,0,35,3.8,50.1,11.2c15.1,7.3,27.1,17.8,36.1,31.3s13.5,29.3,13.5,47.5
			c0,18.2-4.5,34-13.5,47.5c-9,13.4-21,23.9-36.1,31.3c-15.2,7.4-31.8,11.1-50.1,11.1H35.8z M164,163.5c8.5-4.2,15.3-10.2,20.3-17.8
			c5-7.7,7.5-16.7,7.5-27.1c0-10.4-2.5-19.5-7.5-27.1c-5-7.7-11.7-13.7-20.3-17.9c-8.5-4.2-18-6.4-28.6-6.4H79.2v102.8h56.2
			C146,169.9,155.5,167.8,164,163.5z"/>
				<path d="M311.8,201c-16.5-8.2-29.8-19.5-39.6-33.8c-9.9-14.4-14.8-30.6-14.8-48.7c0-18.3,5-34.4,14.8-48.7
			c9.9-14.4,23.1-25.6,39.6-33.8c16.6-8.3,34.9-12.4,54.9-12.4c20.1,0,38.4,4.1,55,12.4c16.5,8.2,29.8,19.4,39.6,33.8
			c9.9,14.3,14.8,30.4,14.8,48.7c0,18.2-5,34.3-14.8,48.7c-9.9,14.3-23.1,25.6-39.6,33.8c-16.6,8.2-34.9,12.3-55,12.3
			C346.7,213.3,328.4,209.2,311.8,201z M399.4,165.4c10-4.2,18-10.3,24.2-18.2c6.1-8,9.2-17.6,9.2-28.8c0-11.3-3.1-20.9-9.2-28.8
			c-6.2-8-14.3-14.1-24.2-18.3c-10.1-4.2-20.9-6.3-32.7-6.3c-11.6,0-22.5,2.1-32.5,6.4c-10.1,4.2-18.2,10.3-24.2,18.3
			c-6.1,8-9.2,17.5-9.2,28.7c0,11.1,3.1,20.6,9.2,28.6c6.1,8,14.2,14.1,24.2,18.4c10.1,4.2,20.9,6.3,32.5,6.3
			C378.5,171.8,389.3,169.7,399.4,165.4z"/>
				<path d="M545.3,208.4c-11.4-3.2-21.5-7.3-30.1-12.5c-8.7-5.2-16.2-11.4-22.4-18.6l27.2-30c4,4.4,8.9,8.5,14.5,12.5
			c5.6,4,12.5,7.4,20.6,10.4c8,3,17,4.5,27.1,4.5c9.5,0,18-1.4,25.6-4.3c7.4-2.8,13.3-6,17.4-9.7s6.2-7,6.2-9.7
			c0-3.2-1.8-5.6-5.2-7.3s-8.4-2.9-14.9-3.7c-6.5-0.8-13.8-1.4-22-1.9c-7.9-0.4-15.9-1-24.2-1.9c-8.3-0.9-16.4-2.3-24.2-4.3
			c-7.8-2-14.8-5-21.3-9c-6.4-4-11.5-9.3-15.3-16.1c-3.9-6.6-5.8-15.1-5.8-25.2c0-10.1,3.4-19.6,10.3-28.5
			c6.9-8.9,16.5-15.9,29-21.4c12.6-5.4,27.5-8.2,44.7-8.2c13.2,0,25,1.4,35.3,4.3c10.3,3,19.4,6.8,27,11.5
			c7.8,4.6,14.7,10.4,21.1,17.2l-27.7,27.7c-5.1-4.6-10.1-8.4-14.9-11.5c-4.8-3.1-10.3-5.6-16.7-7.5c-6.4-2-14.2-2.9-23.5-2.9
			c-11.7,0-22.3,2.1-31.3,6.4c-9.2,4.3-13.7,8.6-13.7,12.8c0,3.8,2,6.8,5.9,8.9c4,2.1,9.6,3.8,16.8,4.9c7.2,1.1,15.3,2,24.1,2.8
			c9.7,0.9,19.7,2,29.7,3.5c10.1,1.5,19.4,4,28,7.4s15.7,8.5,21.3,15.2c5.5,6.6,8.2,15.5,8.2,26.7c0,11-3.5,21.1-10.6,30.7
			c-7.2,9.5-17.4,17.2-30.8,23c-13.4,5.8-29.4,8.6-48.2,8.6C569.1,213.3,556.7,211.6,545.3,208.4z"/>
				<path d="M694.3,28.5h160.4V67H694.3V28.5z M835.2,99.2v38.5H694.3V99.2H835.2z M854.7,169.9v38.5H694.3v-38.5
			H854.7z"/>
			</g>
			<g>
				<path d="M492.8,263.9h2.9V288c0.5-0.7,1.1-1.4,1.8-2.1c3.4-3.4,7.6-5.1,12.4-5.1c4.9,0,9,1.7,12.4,5.1
			s5.1,7.6,5.1,12.4c0,4.8-1.7,9-5.1,12.4s-7.6,5.1-12.4,5.1c-4.8,0-9-1.7-12.4-5.1c-0.7-0.7-1.3-1.4-1.8-2.1v6.4h-2.9V263.9z
			 M495.7,295.3v6c0.6,2.7,1.9,5.1,4,7.2c2.8,2.8,6.2,4.2,10.3,4.2c4,0,7.4-1.4,10.3-4.2s4.2-6.2,4.2-10.2s-1.4-7.4-4.2-10.3
			c-2.8-2.8-6.2-4.2-10.3-4.2c-4,0-7.4,1.4-10.3,4.2C497.6,290.2,496.3,292.6,495.7,295.3z"/>
				<path d="M546.9,312.2l-7.1,14.8H537l8.5-17.8l-12.2-25.6h3.3l10.4,22.5l10.4-22.5h3.2L546.9,312.2L546.9,312.2z" />
				<path d="M631.1,306.8l2.2,1.9c-5,4.5-10.8,6.8-17.6,6.8c-7.2,0-13.4-2.5-18.5-7.6c-5.1-5.1-7.6-11.3-7.6-18.5
			c0-7.2,2.5-13.4,7.6-18.5c5.1-5.1,11.3-7.7,18.5-7.7c6.8,0,12.6,2.3,17.6,6.8l-2.2,2c-4.4-3.9-9.5-5.8-15.4-5.8
			c-6.4,0-11.9,2.3-16.4,6.8s-6.8,10-6.8,16.4c0,6.4,2.3,11.9,6.8,16.4c4.5,4.5,10,6.8,16.4,6.8
			C621.6,312.7,626.7,310.7,631.1,306.8z"/>
				<path d="M641.3,283.6h2.9v5.7c0.6-0.9,1.3-1.7,2.1-2.5c2.5-2.5,5.1-3.9,7.8-4v3.1c-1.8,0.1-3.7,1.1-5.6,3
			c-2.7,2.7-4.1,6-4.2,9.7V315h-2.9V283.6z"/>
				<path d="M694.2,315h-2.9v-6.4c-0.6,0.7-1.2,1.5-1.8,2.1c-3.4,3.4-7.6,5.1-12.4,5.1c-4.8,0-9-1.7-12.4-5.1
			s-5.1-7.6-5.1-12.4c0-4.9,1.7-9,5.1-12.4s7.6-5.1,12.4-5.1c4.9,0,9,1.7,12.4,5.1c0.7,0.7,1.3,1.4,1.8,2.1v-4.4h2.9V315z
			 M691.4,301.2v-5.7c-0.5-2.8-1.9-5.3-4-7.4c-2.8-2.8-6.2-4.2-10.3-4.2s-7.4,1.4-10.3,4.2c-2.8,2.8-4.2,6.2-4.2,10.3
			s1.4,7.4,4.2,10.2s6.2,4.2,10.3,4.2s7.4-1.4,10.3-4.2C689.5,306.4,690.8,304,691.4,301.2z"/>
				<path d="M715.1,312.2L708,327h-2.8l8.5-17.8l-12.2-25.6h3.3l10.4,22.5l10.4-22.5h3.2L715.1,312.2L715.1,312.2z" />
				<path d="M750.6,280.8c4.9,0,9,1.7,12.4,5.1s5.1,7.6,5.1,12.4c0,4.8-1.7,9-5.1,12.4s-7.6,5.1-12.4,5.1
			c-4.8,0-9-1.7-12.4-5.1c-3.4-3.4-5.1-7.6-5.1-12.4c0-4.9,1.7-9,5.1-12.4C741.6,282.5,745.8,280.8,750.6,280.8z M740.3,288.1
			c-2.8,2.8-4.2,6.2-4.2,10.3s1.4,7.4,4.2,10.2s6.2,4.2,10.3,4.2s7.4-1.4,10.3-4.2c2.8-2.8,4.2-6.2,4.2-10.2s-1.4-7.4-4.2-10.3
			c-2.8-2.8-6.2-4.2-10.3-4.2S743.2,285.3,740.3,288.1z"/>
				<path d="M778.7,290.6V315h-2.9v-31.4h2.9v2c2.6-2.6,5.7-3.9,9.1-3.9c3.7,0,6.7,1.2,9,3.5s3.5,5.2,3.6,8.8v21h-2.9
			v-20.7c0-2.7-1-4.9-2.9-6.8c-1.9-1.9-4.2-2.8-6.9-2.8s-4.9,0.9-6.8,2.8C779.9,288.4,779.2,289.5,778.7,290.6z"/>
				<path d="M809.2,283.1V264h2.9v19.2H809.2z" />
				<path d="M854.7,315h-2.9v-6.4c-0.6,0.7-1.2,1.5-1.8,2.1c-3.4,3.4-7.6,5.1-12.4,5.1c-4.8,0-9-1.7-12.4-5.1
			c-3.4-3.4-5.1-7.6-5.1-12.4c0-4.9,1.7-9,5.1-12.4c3.4-3.4,7.6-5.1,12.4-5.1c4.9,0,9,1.7,12.4,5.1c0.7,0.7,1.3,1.4,1.8,2.1v-24.2
			h2.9V315z M851.8,301.2v-5.7c-0.5-2.8-1.9-5.3-4-7.4c-2.8-2.8-6.2-4.2-10.3-4.2s-7.4,1.4-10.3,4.2c-2.8,2.8-4.2,6.2-4.2,10.3
			s1.4,7.4,4.2,10.2s6.2,4.2,10.3,4.2s7.4-1.4,10.3-4.2C850,306.4,851.3,304,851.8,301.2z"/>
			</g>
		</g>
	</svg>

);
export default SVG;