import React from "react";
import { withStyles, Grid, Button, Typography, Divider, Hidden } from '@material-ui/core';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { TextField } from '@material-ui/core';
//import { GET_ALL_FLASH_CARDS } from '../../graphql/query';
//import DeleteFlashCard from './deleteflashcard';

const styles = (theme) => ({
    inputStyle: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 8,
        },
        "& .MuiFormHelperText-contained": {
            marginLeft: 0,
            marginRight: 0,
            textAlign: "right",
            fontWeight: 'bold'
        },
        "& .MuiFormHelperText-root": {
            marginTop: 1,
            fontSize: 12,
            color: "#AEADAD"
        }
    },
    actionButton: {
        float: "right",
        padding: "15px 0",
    },
    button: {
        fontWeight: '800',
        textTransform: "capitalize",
        borderRadius: 8,
        padding: "8px 20px"
    },
    cardTitle: {
        float: "right",
        fontSize: "14px",
        fontWeight: "600"
    },
    title: {
        fontSize: "1.2rem",
        fontWeight: "600",
        textAlign: "center"
    },
})

class CreateFlashCard extends React.Component {

    handleChange = (event, type, index) => {
        let { cardDetails } = this.props;
        let { value } = event.target;
        cardDetails[index][type] = value;
        this.props.setCardDetails({ cardDetails, })
    }

    handleAdd = () => {
        let { cardDetails } = this.props;
        cardDetails.push({ title: '', content: '' })
        this.props.setCardDetails({ cardDetails })
    }

    handleRemove = (index) => {
        let { cardDetails } = this.props;
        cardDetails.splice(index, 1);
        this.props.setCardDetails({ cardDetails, });
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <Hidden mdUp>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.title}>Add Content</Typography>
                    </Grid>
                </Hidden>
                {this.props.cardDetails.map((value, index) => {
                    return (
                        <Grid container direction="row" key={"info" + index} spacing={4} className={classes.inputStyle}>
                            <Grid item xs={12}>
                                <Typography color="primary" className={classes.cardTitle}>Card No. {index + 1}</Typography>
                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12}>
                                <TextField id="title" label="Create Title"
                                    value={value.title}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event, 'title', index)}
                                    variant="outlined"
                                    maxLength={20}
                                    inputProps={{ maxLength: 35 }}
                                    helperText={`${value.title?.length ? value.title?.length : 0} / 35`}
                                    placeholder="Enter title here..." />
                                <Hidden smDown>
                                    <div className={classes.actionButton}>
                                        <Button
                                            size="medium"
                                            variant="text"
                                            className={classes.button}
                                            disabled={this.props.cardDetails.length === 1}
                                            onClick={() => this.handleRemove(index)}
                                            style={{ marginRight: "5px" }}
                                        >
                                            Remove
                                    </Button>
                                        {this.props.cardDetails.length - 1 === index &&
                                            <Button
                                                size="medium"
                                                color="primary"
                                                variant="contained"
                                                onClick={() => this.handleAdd()}
                                                className={classes.button}
                                            >
                                                Add Card +
                                        </Button>}
                                    </div>
                                </Hidden>
                            </Grid>
                            <Grid item lg={7} md={7} sm={12} xs={12}>
                                <TextField
                                    id={"info" + index}
                                    fullWidth
                                    label="Add Content"
                                    multiline
                                    rows={4} value={value.content}
                                    onChange={(event) => this.handleChange(event, 'content', index)}
                                    inputProps={{ maxLength: 230 }}
                                    helperText={`${value.content?.length ? value.content?.length : 0} / 230`}
                                    placeholder="Type..."
                                    variant="outlined"
                                />
                                <Hidden mdUp>
                                    <div className={classes.actionButton}>
                                        <Button
                                            size="medium"
                                            variant="text"
                                            className={classes.button}
                                            disabled={this.props.cardDetails.length === 1}
                                            onClick={() => this.handleRemove(index)}
                                            style={{ marginRight: "5px" }}
                                        >
                                            Remove
                                    </Button>
                                        {this.props.cardDetails.length - 1 === index &&
                                            <Button
                                                size="medium"
                                                color="primary"
                                                variant="contained"
                                                onClick={() => this.handleAdd()}
                                                className={classes.button}
                                            >
                                                Add Card +
                                        </Button>}
                                    </div>
                                </Hidden>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                        //     <div key={"info" + index}>


                        //     {this.props.cardDetails.length !== 1 &&
                        //         <IconButton aria-label="delete" onClick={() => this.handleRemove(index)}>
                        //             <DeleteForeverRoundedIcon />
                        //         </IconButton>}
                        //     {this.props.cardDetails.length - 1 === index &&
                        //         <IconButton aria-label="add" onClick={() => this.handleAdd()}>
                        //             <AddRoundedIcon />
                        //         </IconButton>}
                        // </div>
                    )
                })
                }
            </>
        )
    }
}

export default withRouter(withStyles((styles))(withApollo(CreateFlashCard)));